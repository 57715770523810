import React, { useState } from 'react'
import { Button, Modal, List, Typography, Tooltip, Row, Col } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl';
import styles from './shareProjectForm.module.less'

const { Text, Paragraph } = Typography
const ShareProjectForm = ({ visible, onCancel, shareUrl }) => {
  const intl = useIntl();
  const [listData, setListData] = useState([
    {
      label: '拷贝链接地址',
      content: shareUrl,
      copyable: true,
    },
  ])
  return (
    <Modal
      visible={visible}
      title={intl.formatMessage({ id: 'copyUrl' })}
      footer={null}
      width={600}
      onCancel={onCancel}
    >
      <div className={styles.body}>
        <List
          dataSource={listData}
          renderItem={(item) => (
            <List.Item className={styles.listItem}>
              <div>
                <Text className={styles.label} strong>
                  {item.label}
                </Text>
                <Text>{item.content}</Text>
              </div>
              <div>
                {item.copyable ? (
                  <CopyToClipboard text={item.content}>
                    <Tooltip title={intl.formatMessage({ id: 'copied' })} trigger={['click']}>
                      <Button type="primary" size="small" className="black-btn">
                        {intl.formatMessage({ id: 'copy' })}
                      </Button>
                    </Tooltip>
                  </CopyToClipboard>
                ) : null}
              </div>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  )
}

export default ShareProjectForm
