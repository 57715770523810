/*
 * @Author: melon
 * @Date: 2020-08-20 17:41:38
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-27 18:07:04
 */
import { isEmpty, map } from 'lodash/fp'
// 获取
export const ShowcaseSettingInfoSelector = (data) => {
  const newData = {
    ...data,
    aboutTitle: data.aboutTitle, // 关于-标题
    aboutDesc: data.aboutDesc, // 关于-描述
    aboutAlignType: data.aboutAlignType
      ? data.aboutAlignType.toLowerCase()
      : data.aboutAlignType, // 关于-对齐类型
    isShowEnterpriseService: ['Y'].includes(data.isShowEnterpriseService), // 是否显示首页
    enterpriseServiceList: isEmpty(data.enterpriseServiceList)
      ? []
      : map((item) => ({
          ...item,
        }))(data.enterpriseServiceList), // 展示服务集
    contactTitle: data.contactTitle, // 联系-标题
    contactAddress: data.contactAddress, // 联系-地址
    contactEmail: data.contactEmail, // 联系-email
    contactPhone: data.contactPhone, // 联系-联系电话
    contactWechatImageKey: data.contactWechatImageKey, // 联系-微信二维码图片key
    contactWechatImagePhotoInfo: isEmpty(data.contactWechatImagePhotoInfo)
      ? {}
      : data.contactWechatImagePhotoInfo,
    contactWechatImageUrl: !isEmpty(data.contactWechatImagePhotoInfo)
      ? data.contactWechatImagePhotoInfo.photoUrl
      : '', // 联系-微信二维码图片 url

    defaultIndexType: isEmpty(data.defaultIndexType)
      ? null
      : data.defaultIndexType, // 默认首页类型
    isShowIndex: ['Y'].includes(data.isShowIndex), // 是否显示首页
    indexName: data.indexName, // 首页显示名称
    isShowWorks: ['Y'].includes(data.isShowWorks), // 是否显示作品
    worksName: data.worksName, // 作品显示名称
    isShowCustomerPhoto: ['Y'].includes(data.isShowCustomerPhoto), // 是否显示客片
    customerPhotoName: data.customerPhotoName, // 客片显示名称
    isShowAbout: ['Y'].includes(data.isShowAbout), // 是否显示关于
    aboutName: data.aboutName, //关于显示名称
    isShowContact: ['Y'].includes(data.isShowContact), // 是否显示联系
    contactName: data.contactName, // 联系显示名称
  }
  return newData
}
