import LogoBK from './logo_bk.png'
import LogoWT from './logo_wt.png'
import MsgInfo from './msg_info.png'
import ProfotoDefault from './profoto_default.png'
import User from './user.png'
import Wxcode from './qrcode_assistant.jpg'
import DefaultCoverPNG from './default_cover.png'
import NoData from './img_nodata.png'
import LogoTemp from './logoTemp.png'
import storeCoupon from './storeCoupon.png'
import payAlipay from './pay-alipay.png'
import payPaypal from './pay-paypal.png'
import payWechat from './pay-wechat.png'
import imgCoupon from './imgCoupon.png'
const Imgs = {
  LogoWT,
  LogoBK,
  ProfotoDefault,
  User,
  Wxcode,
  MsgInfo,
  DefaultCoverPNG,
  NoData,
  LogoTemp,
  payAlipay,
  payPaypal,
  payWechat,
  imgCoupon,
  storeCoupon
}

export default Imgs
