// 购物袋
import {Form, Modal, InputNumber, Input, Popconfirm} from 'antd'
import {FormattedMessage,useIntl } from 'react-intl'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import {
  CheckOutlined,
} from '@ant-design/icons';
import CheckoutModal from '../CheckoutModal/index'
import Api from 'apis/Api.js'
import {
  getShoppingCarList as _getShoppingCarList,
  toMod as _toMod,
  cancelShoppingCar as _cancelShoppingCar,
} from 'reduxModules/customerPhoto'
import myMessage from "../Message/index";

const ShoppingCartModal = ({ data, visible, onCreate, onCancel, exHeader,project,toCheckouts }) => {
  const intl = useIntl();
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [checkIds, setCheckIds] = useState([])
  const [allPrice, setAllPrice] = useState(0)
  const [list, setList] = useState([])

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true)
        const resData = await onCreate(values)
        setLoading(false)
        if (resData.data && [0].includes(resData.data.code)) {
          form.resetFields()
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  // 单选
  const checkList=(id)=>{
    let oldIds=JSON.parse(JSON.stringify(checkIds))
    checkIds.includes(id)?oldIds.splice(oldIds.indexOf(id),1):oldIds.push(id)
    setCheckIds(oldIds)
  }
  // 全选
  const checkAll=()=>{
    setCheckIds(list.length!==checkIds.length?list.map(v=>v.id):[])
  }

  useEffect(() => {
    setPrice()
    // toCheckout()
  }, [checkIds,list])
  //设置总价
  const setPrice=()=>{
    let price=0
    list.map(v=>{
      if(checkIds.includes(v.id)){
        price+=Number(v.allPrice)
      }
    })
    setAllPrice(price)
  }
  useEffect(() => {
    // form.setFieldsValue({
    //   ...data,
    // })
    getShoppingCarList()
  }, [])
  useEffect(() => {
    getShoppingCarList()
    if(visible){
      setCheckIds([])
    }
  }, [visible])
  //获取产品列表
  const getShoppingCarList = async () => {
    const res = await _getShoppingCarList({exHeader})
    if ([0].includes(res.code)) {
      console.log(res.data)
      res.data.map(v => {
        let addPrice=v.addPageNum>0>(v.addPageNum*v?.pageSalesPrice)/2
        v.allPrice = (v.number*(v.price + addPrice)).toFixed(2)
        v.newoptionName = v.optionName.replace('-', ', ')
      })
      setList(res.data)
    }else{
      myMessage.warning(res.msg);
    }
  }
  //删除购物车
  const deleteCart= async (v)=>{
    let json={
      exportId:v.exportId
    }
    let data = {
      "method": "DELETE",
      "url": Api.product.deleteCart+'/'+v.exportId,
      "parameterJson": JSON.stringify(json),
    }
    //删除编辑模板
    let re=await _toMod({data,exHeader})
    if ([0].includes(re.code)) {
      data={
        ids:[v.id]
      }
      //删除购物车
      let res=await _cancelShoppingCar({data,exHeader})
      if ([0].includes(res.code)) {
        getShoppingCarList()
      }else{
        myMessage.warning(res.msg);
      }
    }else{
      myMessage.warning(re.msg);
    }
  }
  const toCheckout=()=>{
    let order=list.filter(v=>{
      v.salesPrice=v.price
      v.productName=v.name
      v.extraFreight=v.freightPrice
      v.serialNumber=v.exportInformationVo.serialNumber
      // v.pageSalesPrice=0
      return checkIds.includes(v.id)
    })
    if(order.length>0) {
      toCheckouts(order)
    }else{
      //js语言国际化
      myMessage.warning(intl.formatMessage({id:'cartRemindSelect'}))
    }
  }
  // 减商品数量
  const subtract=(e,i,t)=>{
    let arr = JSON.parse(JSON.stringify(list))
    arr[i][t] = e.number>1?e.number-1:1
    let addPrice=e.addPageNum>0>(e.addPageNum*e?.pageSalesPrice)/2
    arr[i].allPrice = (arr[i][t]*(e.price + addPrice)).toFixed(2)
    setList(arr)
    setPrice()
  }
  // 添加商品数量
  const add=(e,i,t)=>{
    let arr = JSON.parse(JSON.stringify(list))
    arr[i][t]=e.number+1
    let addPrice=e.addPageNum>0>(e.addPageNum*e?.pageSalesPrice)/2
    arr[i].allPrice = (arr[i][t]*(e.price + addPrice)).toFixed(2)
    setList(arr)
    setPrice()
  }
  return (
    <Modal
      visible={visible}
      title={<FormattedMessage id="cart" />}
      // okText="保存"
      // cancelText="取消"
      footer={null}
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={loading}
      maskClosable={false}
      width={900}
      centered
    >
      <div className={styles.cartLists}>
        {list.map((v,i)=>{
          return(
            <div className={styles.cartList}>
              <div onClick={()=>checkList(v.id)} className={`${styles.check} ${checkIds.includes(v.id)?styles.checked:''}`}>
                {checkIds.includes(v.id)&&(<CheckOutlined />)}
              </div>
              <div className={styles.infos}>
                <div className={styles.img}>
                  <img src={v.imageUrl} alt=""/>
                </div>
                <div className={styles.info}>
                  <div className={styles.name}>{v.name}</div>
                  <div className={styles.des}>{v.newoptionName?v.newoptionName:''}</div>
                  <div className={styles.albumOptions}>
                    {/* 购物车内的商品为相册时显示编辑按钮 */}
                    <div className={styles.edit}><FormattedMessage id="cartEdit" /></div><span>|</span>
                    <Popconfirm
                      title={<FormattedMessage id="cartRemindDelete" />}
                      onConfirm={() => deleteCart(v)}
                      okText={<FormattedMessage id="ok" />}
                      cancelText={<FormattedMessage id="cancel" />}
                    >
                      <div className={styles.edit}><FormattedMessage id="cartDelete" /></div>
                    </Popconfirm>
                  </div>
                </div>
              </div>
              <div className={styles.pdCount}>
                <span className="iconfont iconiconOpened" onClick={()=>subtract(v, i, 'number')}></span>
                  {v.number}
                <span className="iconfont iconiconClosed" onClick={()=>add(v, i, 'number')}></span>
              </div>
              <div className={styles.listPrice}>¥{v.allPrice}</div>
            </div>
          )
        })}
      </div>
      <div className={`${styles.cartList} ${styles.last}`}>
        <div className={`${styles.check} ${checkIds.length===list.length?styles.checked:''}`} onClick={()=>checkAll()}>
          {checkIds.length===list.length&&(<CheckOutlined />)}
          <span className={styles.all}><FormattedMessage id="cartSelectAll" /></span>
        </div>
        <div className={styles.price}>
          <span className={styles.all}><FormattedMessage id="cartSubtotal" /></span>
          <span>¥{allPrice}</span>
        </div>
      </div>
      <p className={styles.noFreight}><FormattedMessage id="cartNoShippingFee" /></p>
      <div className={styles.newfooter}>
        <div className={styles.submit} onClick={()=>toCheckout()}><FormattedMessage id="cartCheckout" /></div>
      </div>
    </Modal>
  )
}

export default ShoppingCartModal
