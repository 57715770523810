import Mock from 'mockjs'
const { Random } = Mock

export const getPhotosByCategory = (query) => {
  const pageNo = Number(query.pageNo)
  const pageSize = Number(query.pageSize)
  const totalCount = [8, 35, 68, 21, 33, 78, 15, 6][query.categoryId]
  const residue = totalCount - (pageNo - 1) * pageSize
  const pagePhotoCount = residue / pageSize > 1 ? pageSize : residue
  return {
    pageNo: pageNo,
    pageSize: pageSize,
    totalCount: totalCount,
    totalPage: Math.ceil(totalCount / pageSize),
    list: Array.from({ length: pagePhotoCount }, (v, i) => {
      const imgWidth = Random.natural(300, 720)
      const imgHeight = Random.natural(300, 720)
      return {
        id: query.categoryId + '_' + Random.guid(),
        customerPhotoCategoryId: query.categoryId,
        isCover: ['Y', 'N'][Random.natural(0, 1)],
        isHide: ['Y', 'N'][Random.natural(0, 1)],
        isBookmark: ['Y', 'N'][Random.natural(0, 1)],
        sort: i,
        photoInfo: {
          photoKey: Random.guid(),
          photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),
          photoSize: Random.natural(50, 150),
          photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)],
          photoWidth: imgWidth,
          photoHeight: imgHeight,
        },
      }
    }),
  }
}
// 图片分类
export const getPhotoCategoryData = () =>
  Array.from({ length: Random.natural(2, 5) }, (v, i) => ({
    id: i + 1,
    name: Random.cword(2) + '片类',
    canDownload: ['Y', 'N'][Random.natural(0, 1)],
    canVisitor: ['Y', 'N'][Random.natural(0, 1)],
    photoCount: Random.natural(10, 100),
    sort: i,
  }))

// 客片可下载分类
export const getAllCanDownloadPhotoCategoryData = () =>
  Array.from({ length: Random.natural(2, 5) }, (v, i) => ({
    id: i + 1,
    name: Random.cword(2) + '片类',
    canDownload: ['Y', 'N'][Random.natural(0, 1)],
    canVisitor: ['Y', 'N'][Random.natural(0, 1)],
    photoCount: Random.natural(10, 100),
    sort: i,
  }))

//  客片打包下载地址

export const PhotoCategoryDownloadUrlData = {
  packageDownloadName: Random.word(12, 30) + '.zip',
  packageDownloadUrl: Random.url(),
  packageSize: Random.natural(1, 2048), // 文件大小(kb)
}

// 收藏夹下载分类
export const getBookmarkDownloadPhotoCategoryData = () =>
  Array.from({ length: Random.natural(2, 5) }, (v, i) => ({
    id: i + 1,
    name: Random.cword(2) + '片类',
    canNotDownloadCount: Random.natural(0, 20), // 无权下载图片数
    photoCount: Random.natural(30, 100), // 收藏夹下图片数
    sort: i,
  }))
