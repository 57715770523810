/*
 * @Author: melon
 * @Date: 2020-06-29 11:22:13
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-01 18:07:03
 */
import { Spin } from 'antd'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'

import React, { forwardRef, useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getAll as _getShowCaseSetting } from 'reduxModules/showcaseSetting'
import styles from './index.module.less'
import AboutInfo from '../Components/AboutInfo'
import { useIntl } from 'react-intl'
import ServiceList from '../Components/ServiceList'

const WebAbout = (props, ref) => {
  const dispatch = useDispatch()

  const formRef = useRef(null)

  const intl = useIntl()
  let location = useLocation()
  const showcaseSettingState = useSelector(
    (state) => state.showcaseSetting.data
  )
  const formData = { ...showcaseSettingState }
  const enterpriseServiceList = showcaseSettingState.enterpriseServiceList
  const align = showcaseSettingState.aboutAlignType

  return (
    <div className={styles.aboutCenter}>
        <div className={classNames(styles.about_wrapper)}>
          {/* 关于信息 */}
          <AboutInfo formRef={formRef} formData={formData} align={align} intl={intl} />
          {/* 服务 */}
          <ServiceList list={enterpriseServiceList} />
        </div>
    </div>
  )
}

export default forwardRef(WebAbout)
