/*
 * @Author: melon
 * @Date: 2020-08-20 17:38:53
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-01 20:38:33
 */
import request from 'utils/Request'

import Api from 'apis/Api.js'

// 查询展示信息
export const _getAll = async (params = {}) => {
  return await request({
    method: 'get',
    url: Api.showcaseSetting.getAll,
    params: params,
  })
}
