/*
 * @Author: melon
 * @Date: 2020-08-20 17:41:27
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-27 17:46:36
 */
import { isEmpty, map } from 'lodash/fp'
import { getAddressString } from 'utils/Help'
import Imgs from 'imgs'
// 获取
export const CommonSettingInfoSelector = (data) => {
  data.customerLogoPhotoInfo.photoUrl=data&&data.customerLogoPhotoInfo&&data.customerLogoPhotoInfo.photoUrl?data.customerLogoPhotoInfo.photoUrl:Imgs.LogoTemp;
  const newData = {
    ...data,
    name: data.name, // 企业名称
    remark: data.remark, // 企业简介
    contactNumber: data.contactNumber, // 联系电话
    email: data.email, // 联系email
    areaArray: !isEmpty(data.provinceGeoId)
      ? [data.provinceGeoId, data.cityGeoId, data.areaGeoId]
      : [], // 省市区
    address: data.address, // 详细街道地址
    addressString: [data.provinceGeoId, data.cityGeoId, data.areaGeoId],
    areaString:
      !isEmpty(data.provinceGeoId)?getAddressString([data.provinceGeoId, data.cityGeoId, data.areaGeoId]) +
      data.address:"",
    wechatImageKey: data.wechatImageKey, // 联系微信图片key
    wechatImageUrl: !isEmpty(data.wechatImagePhotoInfo)
      ? data.wechatImagePhotoInfo.photoUrl
      : '', // 联系微信图片url
    wechatImagePhotoInfo: data.wechatImagePhotoInfo,
    weiboUrl: data.weiboUrl, // 微博地址
    wechatNo: data.wechatNo, // 微信号/微信公众号
    instagram: data.instagram, // instagram
    brandLogo: data.brandLogo, // 品牌logo
    brandLogoPhotoInfo: data.brandLogoPhotoInfo,
    brandLogoUrl: !isEmpty(data.brandLogoPhotoInfo)
      ? data.brandLogoPhotoInfo.photoUrl
      : '', // 品牌logo url
    websiteIcon: data.websiteIcon, // 网站图标
    websiteIconPhotoInfo: data.websiteIconPhotoInfo,
    websiteIconUrl: !isEmpty(data.websiteIconPhotoInfo)
      ? data.websiteIconPhotoInfo.photoUrl
      : '', // 网站图标 url
    websiteLayoutType: data.websiteLayoutType, // logo布局类型 LEFT_RIGHT:左右, TOP_DOWN:上下
    websiteTitle: data.websiteTitle, // 网站标题
    websiteFooter: data.websiteFooter, // 页面底部文字
    websiteDesc: data.websiteDesc, // 网站描述
    websiteKeywords: data.websiteKeywords, // 关键词
  }
  return newData
}
