import { Col, Row } from 'antd'
import classNames from 'classnames'
import commonComponents from 'components/index'
import { map } from 'lodash/fp'
import { FormattedMessage } from 'react-intl';
import React from 'react'
import styles from './ServiceList.module.less'

const { InfoEmptyData } = commonComponents
const ServiceCard = ({ data = {} }) => {
  const {
    id,
    name,
    price,
    categoryName,
    remark,
    coverKey,
    coverPhotoInfo,
  } = data
  return (
    <div className={styles.service_card_item}>
      {/* 图片 */}
      <div className={styles.service_item_image}>
        <img src={coverPhotoInfo.photoUrl} alt={coverKey} />
      </div>
      {/* 名称 */}
      <div className={classNames(styles.service_item_title)}>
        {name || categoryName} {price ? `· ￥${price}` : null}
      </div>
      {/* 描述 */}
      <div className={classNames(styles.service_item_des)}>{remark}</div>
    </div>
  )
}

const ServiceList = ({ list, enterpriseServiceVisible }) => {
  return (
    list.length > 0 && (
      <div className={classNames(styles.service_wrapper)}>
        <div className={styles.service_title}>
          <FormattedMessage id="service" />
        </div>
        <Row gutter={24}>
          {map((item) => (
            <Col span={8} style={{ minWidth: 300, maxWidth: '100%' }} key={item.id}>
              <ServiceCard data={item} />
            </Col>
          ))(list)}
        </Row>
      </div>
    )
  )
}

export default ServiceList
