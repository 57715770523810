/*
 * @Author: melon
 * @Date: 2020-08-25 17:02:19
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-19 18:38:48
 */
import React, { useState, useEffect, useRef } from 'react'

import { Layout, Spin, Button, Form, Input, Spain } from 'antd'
import classNames from 'classnames'
import commonComponents from 'components/index'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { WebLayout } from 'constants/Options'

// servers
import { getShowcasePhotoForPage as _getPhotos } from 'reduxModules/showcasePhoto'
import styles from './index.module.less'

// 引入瀑布流
const { InfMasonryImgs, CommonFooter } = commonComponents
const Index = ({ selectedId: categoryId, scrollParentRef }) => {
  let location = useLocation()
  const commonSetting = useSelector((state) => state.commonSetting.data)
  const requesting = useSelector((state) => state.showcaseSetting.fetching)
  const { websiteFooter } = commonSetting
  // 页面token

  const getList = async (data) => {
    return await _getPhotos({ ...data, showcaseType: 'INDEX' })
  }
  return (
    <div>
      <InfMasonryImgs
        getList={getList}
        key={categoryId}
        websiteFooter={websiteFooter}
        scrollParentRef={scrollParentRef}
        isUseWindowScroll={
          WebLayout[0].value === commonSetting.websiteLayoutType
        }
      />
      <CommonFooter websiteFooter={websiteFooter} />
    </div>
  )
}

export default Index
