import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Layout, Modal, Input, Button, Form } from 'antd'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { isEmpty } from 'lodash/fp'
import download from 'downloadjs'
import {
  getProjectById as _getProjectById,
  getBookmarkAllForDownload as _getAllBookmarkDownload,
  getBookmarkDownloadUrl as _getBookmarkDownloadUrl,
  getAllCanDownload as _getAllCustomerDownload,
  getDownloadUrl as _getCustomerDownloadUrl,
  getCustomerDownload as _getCustomerDownload,
  getBookmarkDownload as _getBookmarkDownload,
} from 'reduxModules/customerPhoto'
import CategoryList from '../Components/List'
import DownloadForm from '../Components/DownloadForm'

import styles from './index.module.less'
import { getToken } from 'utils/Request'
import { PAGE_PATH } from 'apis/Router'
import { getFullRoute } from 'utils/Tools'

import BottomMessage from 'components/BottomMessage/index'

import {
  getSessionStorageValue
} from 'utils/Help'

const PackDownloadPage = (props) => {
  let history = useHistory()
  let { id, type } = useParams()
  const pageType = window.atob(type)
  const commonSettingData = useSelector((state) => state.commonSetting.data)

  const token = getToken()
  const exHeader = isEmpty(token)
    ? {
        projectId: id,
      }
    : {
        projectId: id,
        token,
      }
  const [photoCategories, setPhotoCategories] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [project, setProject] = useState({})
  const [emailVisible, toggleEmailVisible] = useState(false)
  const [email, setEmail] = useState()
  const [uids, setUIds] = useState()
  const [name, setName] = useState('')
  const [successVisible, toggleSuccessVisible] = useState()
  const [loading, setLoading] = useState(false);
  const [form] =Form.useForm()
  const intl = useIntl();
  // 下载数据
  const [downloadData, setDownloadData] = useState({})
  // 下载步骤
  const [step, setStep] = useState(1)
  useEffect(() => {
    getProjectData()
  }, [])

  // 获取收藏夹可下载分类
  const getAllBookmarkDownload = async (downloadCode) => {
    const res = await _getAllBookmarkDownload({
      params: {
        downloadCode:getSessionStorageValue('pin'),
      },
      exHeader,
    })

    if (res.code == 0) {
      setPhotoCategories(res.data)
    }
  }

  // 获取客片可下载分类

  const getAllCustomerDownload = async (downloadCode) => {
    const res = await _getAllCustomerDownload({
      params: {
        downloadCode:getSessionStorageValue('pin'),
      },
      exHeader,
    })

    if (res.code == 0) {
      setPhotoCategories(res.data)
    }
  }

  // 获取项目信息 + 获取收藏夹可下载分类
  const getProjectData = async () => {
    const res = await _getProjectById({
      params: {
        id: id,
      },
      exHeader,
    })
    if (res.code == 0) {
      const { downloadCode } = res.data
      setName(res.data.customerName)
      await setProject(res.data)
      if (['customer'].includes(pageType)) {
        await getAllCustomerDownload(downloadCode)
      } else {
        await getAllBookmarkDownload(downloadCode)
      }
    }
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    form.validateFields()
  }

  // 开始下载
  const doStartDownload = async (ids) => {
    // 派发获取下载地址请求
    form.validateFields()
    .then(async() =>  {
      setLoading(true)
       try {
        const res = ['customer'].includes(pageType)
          ? await _getCustomerDownload({
              data: {
                categoryIds: ids,
                // downloadCode: project.downloadCode,
                downloadCode: getSessionStorageValue('pin'),
                email,
                source:'WEB',
              },
              exHeader,
            })
          : await _getBookmarkDownload({
              data: {
                bookmarkIds: ids,
                // downloadCode: project.downloadCode,
                downloadCode: getSessionStorageValue('pin'),
                email,
                source:'WEB',
              },
              exHeader,
            })
      } catch (error) {
      } finally {
        setLoading(false)
        toggleEmailVisible(false)
        toggleSuccessVisible(true)
        return null;
      }
    })
  }

  //输出邮箱
  const inputEmail = async (checkedIds) => {
    toggleEmailVisible(true);
    setUIds(checkedIds)
    // doStartDownload;

  }
  const backToHome = async () => {
    history.push(
      getFullRoute(PAGE_PATH.projectDetail, {
        id: id,
      })
    )
  }
  // 打包下载
  const doPackDownload = async () => {
    if (![3].includes(step)) {
      // 派发请求
      try {
        setStep(3)
        // 下载
        download(downloadData.packageDownloadUrl)
        BottomMessage.info(intl.formatMessage({ id: 'downLoadSuccess' }), project.coverPhotoInfoUrl)
      } catch (error) {
      } finally {
      }
    } else {
      history.push(
        getFullRoute(PAGE_PATH.projectDetail, {
          id: id,
        })
      )
    }
  }
  const handleCancel = () => {
    form.resetFields()
    toggleEmailVisible(false)
  };

  return (
    <Layout
      className="Inf_page"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className={styles.packDownloadPage}>
        <div className={styles.header}>
          <div className={styles.topInfo}>
            <div>
              <div className={styles.projectName}>{project.name}</div>
              <div className={styles.projectDate}>{project.shootingDay=='Invalid date'?'':project.shootingDay}</div>
              <div className={styles.logo}>
                <Link to={ getFullRoute(PAGE_PATH.projectDetail, {
                  id: id,
                })}>
                  <img
                    src={
                      isEmpty(commonSettingData)
                        ? ''
                        : commonSettingData.customerLogoPhotoInfo.photoUrl
                    }
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {[1].includes(step) && (
          <div className={styles.list}>
            {/* list */}
            <CategoryList
              list={photoCategories}
              onDownload={inputEmail}
              renderItem={(item) => (
                <div key={item.id}>
                  <div>
                    <span className={styles.name}>{item.name}</span>
                    <span className="Inf_page_text">
                      {item.photoCount}
                      {intl.formatMessage({ id: 'picNumber' })}
                    </span>
                  </div>
                  {['favorite'].includes(pageType) &&
                    item.canNotDownloadCount > 0 && (
                      <div className="Inf_page_text" style={{ fontSize: 12 }}>
                        {intl.formatMessage({ id: 'promptInCollection' })}
                        {item.canNotDownloadCount}
                        {intl.formatMessage({ id: 'roleCantDownLoad' })}
                      </div>
                    )}
                </div>
              )}
            />
          </div>
        )}

        {/* {[2, 3].includes(step) && (
          <div className={styles.form}>
            <DownloadForm
              data={downloadData}
              onDownload={doPackDownload}
              btnText={[3].includes(step) ? intl.formatMessage({ id: 'backHome' }) : intl.formatMessage({ id: 'downLoad' })}
              title={
                [3].includes(step)
                  ? intl.formatMessage({ id: 'beginDownloadPlsCheck' })
                  : intl.formatMessage({ id: 'prepareToDownLoad' })
              }
            />
          </div>
        )} */}

        {/* 整理 */}
        <Modal
          maskClosable={false}
          footer={[]}
          visible={modalVisible}
          wrapClassName={styles.process_modal}
        >
          <p>{intl.formatMessage({ id: 'pleaseWaitBundle' })} </p>
        </Modal>
        {/* {下载成功} */}
         {/* 整理 */}
         <Modal
          maskClosable={false}
          closable={false}
          footer={[]}
          width={600}
          centered
          visible={successVisible}
          wrapClassName={styles.success_modal}
        >
          <i className="iconfont iconico_success" style={{ fontSize: 30, marginBottom: 30, color: '#1AA77D' }}></i>
          <p className={styles.success_text}>{intl.formatMessage({ id: 'downAndLookEmail' })}</p>
          <p className={styles.success_text}>({email})</p>
          <Button onClick={backToHome}>{intl.formatMessage({ id: 'backProjectPage' })}</Button>
          <p className={styles.down_alarm}>#{intl.formatMessage({ id: 'validPeriod' })}#</p>
        </Modal>
        {/* 下载email */}
        <Modal
          maskClosable={false}
          footer={[]}
          width={600}
          centered
          onCancel={handleCancel}
          visible={emailVisible}
          wrapClassName={styles.email_modal}
          destroyOnClose={true}
        >
          <p className={styles.down_title}>{intl.formatMessage({ id: 'downloadReq' })}</p>
          <p className={styles.down_desc}>{intl.formatMessage({ id: 'downloadForEmail' })}</p>
          <p className={styles.input_label}>{intl.formatMessage({ id: 'receiveEmail' })}</p>
          <Form
            name="basic"
            form={form}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: intl.formatMessage({ id: 'inputEmailTip' }),
                },
                {
                  required: true,
                  message: intl.formatMessage({ id: 'inputEmail' }),
                },
              ]}
            >
              <Input placeholder={intl.formatMessage({ id: 'inputEmail' })} onChange={handleEmailChange}/>
            </Form.Item>
          </Form>
          <div className={styles.submit_btns}>
            <Button
              style={{border: 'none', color: '#9B9B9B'}}
              onClick={handleCancel}
            >{intl.formatMessage({ id: 'cancel' })}</Button>
            <Button
              className="Inf_black_btn"
              loading={loading}
              onClick={() => doStartDownload(uids)}
            >
              {intl.formatMessage({ id: 'ok' })}
            </Button>
          </div>

        </Modal>
      </div>
    </Layout>
  )
}

export default PackDownloadPage
