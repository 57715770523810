import { Button, Col, Form, Input, Popover, Row, Spin, Divider } from 'antd'
import BottomMessage from 'components/BottomMessage/index'
import commonComponents from 'components/index'
import download from 'downloadjs'
import { isNil } from 'lodash'
import Masonry from 'masonry-layout'
import React from 'react'
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller'
import styles from './masonryImgs.module.less'
import {
  getSessionStorageValue,
} from 'utils/Help'

const { PreviewCarouselModal, InfoEmptyData, InfListFooterText } =
  commonComponents
const { TextArea } = Input
export default class MasonryImgs extends React.Component {
  formRef = React.createRef()
  state = {
    imgsData: [],
    hasMore: true,
    idOfShowOpts: '',
    contentVisible: false,
    selectedImg: {},
    previewCarouselModalVisible: false, // 查看图片弹窗
    previewList: [], // 查看弹窗图片list
    previewCurrent: 0, // 查看弹窗当前siderNumber
    pagination: {
      current: 1,
    }, // 分页数据
    loading: false,
  }

  componentDidMount() {
    const { categoryId, callbackObj, setCallbackObj } = this.props
    setCallbackObj({
      ...callbackObj,
      callbackFuncs: {
        ...callbackObj.callbackFuncs,
        ...this,
      },
    })
    if (categoryId) {
      this.getBookmarkPhotos({ bookmarkId: categoryId })
    }
  }

  getBookmarkPhotos = async (body) => {
    const { _pagePhotos, exHeader, setFavDetailSelected, backToLogin } =
      this.props
    const { imgsData } = this.state

    this.setState({ loading: true })
    const res = await _pagePhotos({
      params: {
        ...body,
      },
      exHeader,
    })

    this.setState({ loading: false, hasMore: false })
    if ([0].includes(res.code)) {
      const { pagination, list } = res.data
      const hasMore =
        pagination.current * pagination.pageSize < pagination.total
      const newList = pagination.current === '1' ? list : imgsData.concat(list)
      setFavDetailSelected(Number(pagination.total))
      this.setState({ imgsData: newList, hasMore, pagination })
    } else if ([9200].includes(res.code)) {
      backToLogin()
    }
  }

  loadData = (page = 1) => {
    const { hasMore } = this.state
    const { categoryId } = this.props
    if (hasMore) {
      this.getBookmarkPhotos({
        bookmarkId: categoryId,
        pageNo: page,
        //pagination: { pageNo: page },
      })
    }
  }
  onLoadImg = () => {
    // nowNum++
    // if (nowNum === count) {
    //   // 如果所有图片加载完，调用实现瀑布流代码
    // }

    var elem = document.querySelector('.grid')
    var msnry = new Masonry(elem, {
      // options
      itemSelector: '.grid-item',
    })
    var msnry = new Masonry('.grid', {})
  }

  showOpt = (img) => {
    const { project } = this.props
    const { imgsData } = this.state
    if (project.isShowBookmark ||project.isMessage || project.isSetPhotoHidden || project.canDownload) {
      this.setState({
        idOfShowOpts: img.id,
        previewCurrent: imgsData.findIndex((item) =>
          [img.id].includes(item.id)
        ),
      })
    }
  }
  hideOpt = () => {
    this.setState({ idOfShowOpts: '' })
  }
  // 下载单个数据
  downloadImg = async (img) => {
    const { exHeader, _getBookmarkSingleDownloadUrl, project } = this.props
    const { id } = img
    const { downloadCode } = project
    const res = await _getBookmarkSingleDownloadUrl({
      data: {
        bookmarkPhotoIds: [id],
        downloadCode:getSessionStorageValue('pin'),
        source:'WEB',
      },
      exHeader,
    })
    if ([0].includes(res.data.code)) {
      // download(res.data.data.packageDownloadUrl)
      let data = res.data.data;
      if(data.length > 0){
        // download(data[0].photoUrl)
        let request=new XMLHttpRequest();
        request.open("GET", data[0].photoUrl, true);
        request.responseType="blob";
        request.onload= function(e){
          download(e.target.response, data[0].photoName);
        };
        request.send();
      }
    }
    return res
  }

  clickPopover = ({ visible, item, key }) => {
    this.setState({
      contentVisible: visible,
      selectedImg: { img: item, index: key },
    })
  }
  // 删除收藏夹评论
  deleteMessage = async ({ item = {}, key }) => {
    const { selectedImg, imgsData } = this.state
    const { exHeader, _saveMessage } = this.props
    const { img, index } = selectedImg
    const body = {
      id: item.id || img.id,
      message: '',
    }
    if (!key&&key!=0) {
      this.formRef.current.resetFields()
    }
    const res = await _saveMessage({ data: body, exHeader })

    if (res.data.code == 0) {
      const inKey = isNil(index) ? key : index
      imgsData[inKey].hasMessage = false
      imgsData[inKey].message = ''
      this.setState({ imgsData, contentVisible: false, selectedImg: {} })
    }
    return res
  }

  cancel = () => {
    this.setState({ contentVisible: false, selectedImg: {} })
    this.formRef.current.resetFields()
  }
  // 保存收藏夹评论
  saveMessage = async ({ message, item = {}, key }) => {
    const { selectedImg, imgsData } = this.state
    const { exHeader, _saveMessage } = this.props
    const { img, index } = selectedImg
    const body = {
      id: item.id || img.id,
      message: message,
    }
    const res = await _saveMessage({ data: body, exHeader })
    if (res.data.code == 0) {
      const inKey = isNil(index) ? key : index
      imgsData[inKey].hasMessage = true
      imgsData[inKey].message = message
      this.setState({
        imgsData,
        contentVisible: false,
        selectedImg: {},
      })
    }
    return res
  }
  // 弹出窗设置被选图片
  changeSelectedImg = ({ item, key }) => {
    this.setState({ selectedImg: { img: item, index: key } })
  }

  // 取消收藏
  cancelBookmark = async ({ img, index }) => {
    let { imgsData, pagination } = this.state
    const { exHeader, _cancelBookmark, intl } = this.props
    const res = await _cancelBookmark({
      data: { id: img.id },
      exHeader,
    })
    if (res.data.code == 0) {
      this.hidePreviewCarouselModal(imgsData)
      imgsData.splice(index, 1)
      BottomMessage.info(intl.formatMessage({ id: 'deleteCollection' }), img.thumbInfo['thumb'].photoUrl)
      pagination.total -= 1
      this.setState({ imgsData, pagination })
    }
    return res
  }

  // 设置隐藏
  setHide = async ({ img, index }) => {
    let { imgsData } = this.state
    const { exHeader, _setBookmarkPhotoHide, intl } = this.props
    const res = await _setBookmarkPhotoHide({
      data: {
        id: img.id,
        isHide: !img.isHideShow,
      },
      exHeader,
    })
    if (res.data.code == 0) {
      imgsData[index].isHideShow = !img.isHideShow
      this.setState({ imgsData })
      BottomMessage.info(
        img.isHideShow ? intl.formatMessage({ id: 'hasHidden' }) : intl.formatMessage({ id: 'noHidden' }),
        img.thumbInfo['thumb'].photoUrl
      )
    }
    return res
  }
  getContent = (item) => {
    const { checkIdentity, intl } = this.props
    return (
      <Form
        key={this.state.loading}
        onFinish={(values) =>
          checkIdentity({
            funcName: 'saveMessage',
            options: values,
          })
        }
        initialValues={{ message: item.message }}
        ref={this.formRef}
      >
        <Form.Item
          noStyle
          name="message"
          rules={[{ max: 100, message: intl.formatMessage({ id: 'max100'})}]}
        >
          <TextArea
            placeholder={intl.formatMessage({ id: 'remarkMax100'})}
            rows={3}
            bordered={false}
            style={{ resize: 'none', width: 260 }}
            onClick={(e) => {
              e.stopPropagation()
            }}
            maxLength={100}
          />
        </Form.Item>
        <div className={styles.messageBtn}>
          <a
            onClick={(e) => {
              e.stopPropagation()
              checkIdentity({
                funcName: 'deleteMessage',
              })
            }}
            type="link"
            className="dange-default-btn"
            style={{ visibility: item.message.length > 0 ? '' : 'hidden' }}
          >
            {intl.formatMessage({ id: 'delete' })}
          </a>

          <div>
            <Button
              type="link"
              onClick={(e) => {
                e.stopPropagation()
                this.cancel()
              }}
              className="link-btn-default"
            >
              {intl.formatMessage({ id: 'cancel' })}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="small"
              className="Inf_black_btn"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {intl.formatMessage({ id: 'save' })}
            </Button>
          </div>
        </div>
      </Form>
    )
  }
  // 显示查看弹窗
  showPreviewCarouselModal = async (id) => {
    const { imgsData } = this.state
    await this.setState({
      previewCurrent: imgsData.findIndex((item) => [id].includes(item.id)),
    })
    await this.setState({
      previewCarouselModalVisible: true,
    })
  }
  // 隐藏查看弹窗
  hidePreviewCarouselModal = (newList) => {
    this.setState({
      previewCarouselModalVisible: false,
      previewCurrent: 0,
      imgsData: newList,
    })
  }
  setPreviewCurrent = async (index) => {
    await this.setState({
      previewCurrent: index,
    })
  }
  render() {
    const {
      imgsData,
      hasMore,
      idOfShowOpts,
      contentVisible,
      selectedImg,
      previewCarouselModalVisible,
      previewCurrent,
      pagination,
      loading,
    } = this.state
    // console.log(imgsData)
    const { checkIdentity, project, intl } = this.props
    return !hasMore && !imgsData.length ? (
      <div style={{ marginTop: 120 }}>
        <InfoEmptyData description={<FormattedMessage id="collectionNoData" />} />
      </div>
    ) : (
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        hasMore={hasMore}
        loadMore={this.loadData}
      >
        <Spin spinning={loading} tips={<FormattedMessage id="getData" />} style={{width: "100%" }} />
          <Row className="grid fixAntdAndMosonryGrid" gutter={[10, 10]}>
            {imgsData.map((item, key) => {
              return (
                <Col
                  className="grid-item"
                  key={key}
                  onMouseEnter={this.showOpt.bind(this, item)}
                  onMouseLeave={this.hideOpt}
                  onClick={() => this.showPreviewCarouselModal(item.id)}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={6}
                  // xxl={4}
                >
                  <div className={styles.photoList}>
                  <img
                    className="masonry-img"
                    src={item.thumbInfo['general'].photoUrl}
                    key={item.thumbInfo['general'].photoKey}
                    onLoad={(e) => {
                      e.stopPropagation()
                      this.onLoadImg()
                    }}
                  />
                  <div className={styles.imgTop}>
                    <div className={styles.imgTopLeft}>
                      <span
                        className={`iconfont iconicon_favoried ${styles.imgIcon}`}
                      ></span>
                      {/* <svg
                        className={`icon ${styles.imgIcon}`}
                        aria-hidden="true"
                      >
                        <use xlinkHref="#iconicon_favoried"></use>
                      </svg> */}
                      {item.isHideShow && project.isSetPhotoHidden ? (
                        // <svg
                        //   className={`icon ${styles.imgIcon}`}
                        //   aria-hidden="true"
                        // >
                        //   <use xlinkHref="#iconicon_hidden"></use>
                        // </svg>
                        <span
                          className={`iconfont iconicon_hidden ${styles.imgIcon}`}
                        ></span>
                      ) : null}

                      {item.hasMessage && project.isMessage && sessionStorage.getItem('accessType')=='clientMode' ? (
                        <span
                          className={`iconfont iconicon_commented ${styles.imgIcon}`}
                        ></span>
                      ) : null}

                    </div>
                    <div className={styles.imgTopRight}>
                    {item.likeMarkCount>1?(
                      <span className={`iconfont iconico_favmarked_s ${styles.imgIcon}`}><span className={styles.count}>{item.likeMarkCount}</span></span>
                      ):''}
                    </div>
                  </div>

                  {/* {project.isMessage && (
                    <div
                      className={styles.imgCommentedIcon}
                      style={{
                        visibility: item.hasMessage ? '' : 'hidden',
                      }}
                    >
                      <span
                          className={`iconfont iconicon_commented ${styles.imgIcon}`}
                        ></span>
                    </div>
                  )} */}

                  <div
                    className={styles.imgBottom}
                    style={{
                      visibility: idOfShowOpts === item.id ? '' : 'hidden',
                    }}
                  >
                      <div className={styles.imgBtns}>
                        <a
                          onClick={(e) => {
                            e.stopPropagation()
                            checkIdentity({
                              funcName: 'cancelBookmark',
                              options: {
                                img: item,
                                index: key,
                              },
                            })
                          }}
                        >
                          <svg
                            className={`icon ${styles.imgIcon}`}
                            aria-hidden="true"
                          >
                            <use xlinkHref="#iconbtn_fav_on"></use>
                          </svg>
                        </a>
                        {project.isSetPhotoHidden && sessionStorage.getItem('accessType')=='clientMode' && (
                          <span>
                            <Divider type="vertical" />
                            <a
                              onClick={(e) => {
                                e.stopPropagation()
                                checkIdentity({
                                  funcName: 'setHide',
                                  options: {
                                    img: item,
                                    index: key,
                                  },
                                })
                              }}
                            >
                              <svg
                                className={`icon ${styles.imgIcon}`}
                                aria-hidden="true"
                              >
                                {item.isHideShow ? (
                                  <use xlinkHref="#iconbtn_hidden_on"></use>
                                ) : (
                                  <use xlinkHref="#iconbtn_hidden1"></use>
                                )}
                              </svg>
                            </a>
                          </span>
                        )}
                        {project.isMessage && sessionStorage.getItem('accessType')=='clientMode' && (
                          <span>
                            <Divider type="vertical" />
                            <Popover
                              placement="bottomLeft"
                              content={this.getContent.bind(this, item)}
                              trigger="click"
                              overlayClassName="messagePopover noArrow"
                              visible={contentVisible && selectedImg.img == item}
                              onVisibleChange={(visible) =>
                                this.clickPopover({ visible, item, key })
                              }
                              destroyTooltipOnHide={true}
                            >
                              <a
                                onClick={(e) => {
                                  e.stopPropagation()
                                }}
                              >
                                <span
                              className={item.hasMessage ? `iconfont iconbtn_comment_on ${styles.imgIcon} ${styles.imgIcon2}`:`iconfont iconbtn_comment ${styles.imgIcon}`}
                            ></span>
                              </a>
                            </Popover>
                          </span>
                        )}
                        {project.canDownload && item.canDownload === 'Y'&&(sessionStorage.getItem('accessType')=='clientMode'||(sessionStorage.getItem('accessType')=='visitorMode' && project.isVisitorDownload=='Y')) && (
                              <span>
                                <Divider type="vertical" />
                                <a
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    checkIdentity({
                                      type: 'download',
                                      funcName: 'downloadImg',
                                      options: item,
                                    })
                                  }}
                                >
                                  <span
                                    className={`iconfont iconbtn_download1 ${styles.imgIcon}`}
                                  ></span>
                                </a>
                              </span>
                        )}
                      </div>
                  </div>
                  </div>
                </Col>
              )
            })}
            {/* 列表页尾提醒文字 */}
            <InfListFooterText hasMore={hasMore} pagination={pagination} />
          </Row>
        {/* </Spin> */}
        {/* 查看弹窗 */}
        <PreviewCarouselModal
          key={imgsData}
          visible={previewCarouselModalVisible}
          onCancel={this.hidePreviewCarouselModal}
          list={imgsData}
          previewCurrent={previewCurrent}
          canDownload
          downloadImg={(data) => {
            checkIdentity({
              type: 'download',
              funcName: 'downloadImg',
              options: data,
            })
          }}
          setPicHide={(data) =>
            checkIdentity({
              funcName: 'setHide',
              options: data,
            })
          }
          saveMessage={(data) =>
            checkIdentity({
              funcName: 'saveMessage',
              options: data,
            })
          }
          cancelBookmark={(data) =>
            checkIdentity({
              funcName: 'cancelBookmark',
              options: data,
            })
          }
          deleteMessage={(data) =>
            checkIdentity({
              funcName: 'deleteMessage',
              options: data,
            })
          }
          pagination={pagination}
          hasMore={hasMore}
          showOpt={true}
          setPreviewCurrent={this.setPreviewCurrent}
          loadData={this.loadNext}
          showKey="customPhotoName"
          project={project}
          getContent={this.getContent}
          isFav={true}
        />
      </InfiniteScroll>
    )
  }
}
