import React, { useState, useEffect, useRef } from 'react'

import {Layout, Spin, Button, Form, Input, Modal} from 'antd'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import classNames from 'classnames'
import commonComponents from 'components/index'
import { PAGE_PATH } from 'apis/Router'
import { getFullRoute } from 'utils/Tools'
import {
  LoginAccountValidateRule,
} from 'utils/ValidateRulesAndMsg'
import { useIntl } from 'react-intl';
import axios from 'axios'

import { setToken, removeToken } from 'utils/Request'
import { setSession, removeSession,setLocal } from 'utils/Tools'

import {
  getProjectInfoForLogin as _getProjectInfoForLogin ,
  getAuthorization as _getAuthorization,
  wechatMp as _wechatMp,
  emailLogin as _emailLogin,
  getWechat as _getWechat,
  map as _map,
} from 'reduxModules/customerPhoto'
import {
  login as _login,
  record as _record,
} from 'reduxModules/member'
import styles from './index.module.less'
import {useSelector} from "react-redux";
import AboutModal from "../../CategoryPhotosPage/Components/AboutModal";
import WxEmailModal from '../Components/WxEmailModal'
const { MyMessage, TrimInput, TrimPswInput,CommonFooter} = commonComponents
const LoginForm = ({ formRef,loginMode,projectInfo}) => {
  const [form] = Form.useForm()
  const intl = useIntl();

  return (
    <Form
      form={form}
      ref={formRef}
      layout="vertical"
      name="loginForm"
      className={styles.Inf_login_form}
      // validateTrigger="onSubmit"
    >
      {loginMode&&projectInfo.customerId!=0?(
      <Form.Item
        label=""
        name="account"
        rules={[
          { required: true, message: intl.formatMessage({ id: 'validatePhoneOrEmail' }) },
          LoginAccountValidateRule(intl),
        ]}
      >
        <TrimInput className={styles.login_input} placeholder={intl.formatMessage({ id: 'phoneOrEmail' }) } />
      </Form.Item>
      ):null}

      <Form.Item
        label=""
        name="password"
        rules={[
          { required: true, message:  intl.formatMessage({ id: 'validatePassword' }) },
          // { ...PswValidateRule },
        ]}
      >
        <TrimPswInput className={styles.login_input} placeholder={intl.formatMessage({ id: 'password' }) } />
      </Form.Item>
    </Form>
  )
}

const Login = (props) => {
  let history = useHistory()
  let location = useLocation()
  const commonSettingData = useSelector((state) => state.commonSetting.data)
  // 页面token
  let {id} = useParams()
  const exHeader = {
    projectId: id,
  }
  const childRef = useRef()
  const wxRef = useRef()
  const intl = useIntl()
  const [form] = Form.useForm()
  // 项目信息
  const [projectInfo, setProjectInfo] = useState({})
  // 登录状态
  const [requesting, setRequesting] = useState(false)
  const [loginMode, setLoginMode] = useState(true)
  const [visible, setVisible] = useState(false)
  const [wxEmailModalVisible, setWxEmailModalVisible] = useState(false)
  const [one, setOne] = useState(true)
  const [longLat, setLongLat] = useState('')
  const [address, setAddress] = useState({
    country:'',
    province:'',
    city:'',
    district:'',
    township:'',
  })
  const [browsers, setBrowser] = useState('')
  const [clientTypeSimple, setClientTypeSimple] = useState('')
  const [customerId, setCustomerId] = useState('')
  const gotoView = async (customerId,adr) => {
    let recordBrowseDTO={
      customerId:customerId,
      clientType:browsers?browsers:adr.browsers,
      clientTypeSimple:clientTypeSimple?clientTypeSimple:adr.clientTypeSimple,
      country:address.country?address.country:adr.country,
      province:address.province?address.province:adr.province,
      city:address.city?address.city:adr.city,
      position:address.country?address.country+address.province+address.city+address.district+address.township:adr.country+adr.province+adr.city+adr.district+adr.township,
      latitude:longLat.split(',')[1]?longLat.split(',')[1]:adr.location?adr.location.split(',')[1]:'',
      longitude:longLat.split(',')[0]?longLat.split(',')[0]:adr.location?adr.location.split(',')[0]:'',
      accessType:sessionStorage.getItem('accessType')
    }
    console.log(recordBrowseDTO)
    // return
    const res = await _record({
      data: recordBrowseDTO,
      exHeader,
    })
    history.push(
        getFullRoute(PAGE_PATH.projectDetail, {
          id: id,
        })
    )
  }
  const getProjectsData = async () => {
    const res = await _getProjectInfoForLogin({
      params: {
        id: id,
      },
      exHeader,
    })

    if (res.code == 0) {
      setProjectInfo(res.data)
    }
  }
  const GetRequest = () => {
    let url = window.location.href.split('?')[1]; //获取url中"?"符后的字串
    let theRequest = new Object();
    let strs = url.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
    return theRequest;
  }
  //登录查看
  const doCheck = async () => {
    childRef.current
        .validateFields()
        .then(async (values) => {
          try {
            setRequesting(true)
            //loginMode存在为访客模式，否则为客户模式
            values.accessType = !loginMode || projectInfo.customerId == 0 ? 'visitorMode' : 'clientMode'
            sessionStorage.setItem('accessType', values.accessType)
            const res = await _login({
              data: {
                ...values,
              },
              exHeader,
            })

            if ([0].includes(res.data.code)) {
              const {token} = res.data.data
              await setToken(token)
              await setSession('loginUser', JSON.stringify(res.data.data))
              // await setLocal('loginUser', JSON.stringify(res.data.data))
              console.log(res.data.data.id)
              // if(res.data.data.id){
                setCustomerId(res.data.data.id)
              // }
              //客户模式直接跳转页面否则获取用户微信信息
              if (values.accessType == 'clientMode') {
                gotoView(res.data.data.id,'')
              } else {
                setWxEmailModalVisible(true)
              }
            } else {
              MyMessage.error(res.data.msg || intl.formatMessage({id: 'serverError'}))
            }
          } catch (error) {
          } finally {
            setRequesting(false)
          }
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
  }
  const doChecks = async () => {
    const res = await _login({
      data: {
        accessType: 'visitorMode'
      },
      exHeader,
    })
    sessionStorage.setItem('accessType', 'visitorMode')
    if ([0].includes(res.data.code)) {
      const {token} = res.data.data
      await setToken(token)
      setWxEmailModalVisible(true)
      // gotoView()
    }
  }
  useEffect(() => {
    getAddress()
  }, [])
  useEffect(() => {
    removeToken()
    removeSession('loginUser')
    removeSession('wxInfo')
    getProjectsData()
  }, [id])
  // 获取地址信息
  const getAddress = async() => {
    let browser=getBrowserInfo()
    setBrowser(browser)
    let clientTypeSimple=''
    if(browser.split('-')[1].indexOf('Windows')<0){
      clientTypeSimple=getExploreName()+', '+browser.split('-')[1].split(';')[0]
    }else{
      clientTypeSimple=getExploreName()+', '+browser.split('-')[1].split(' ')[0]
    }
    setClientTypeSimple(clientTypeSimple)
    //获取地理位置与经纬度
    try {
      fetch("https://restapi.amap.com/v3/ip?key=28d085faf15e8975ef1338d723e18a9b").then((res) => {
        if (res.ok) {
          res.text().then(async(data) => {
            const detail = JSON.parse(data)
            if (detail.rectangle.length == 0) {
              if (window.location.href.includes('code=') && one) {
                let datas={
                  browsers:getBrowserInfo(),
                  clientTypeSimple:clientTypeSimple,
                  ...address
                }
                isWxCode(datas)
              }
              return
            }
            let rectangle = ((parseFloat(detail.rectangle.split(';')[0].split(',')[0]) +
                parseFloat(detail.rectangle.split(';')[1].split(',')[0])) / 2).toFixed(8) + ',' + ((
                parseFloat(detail.rectangle.split(';')[0].split(',')[1]) +
                parseFloat(detail.rectangle.split(';')[1].split(',')[1])) / 2).toFixed(8)
              await setLongLat(rectangle)
            fetch("https://restapi.amap.com/v3/geocode/regeo?output=json&location=" + rectangle + "&key=28d085faf15e8975ef1338d723e18a9b&radius=1000&extensions=all").then((res) => {
              if (res.ok) {
                res.text().then(async(data) => {
                    await setAddress(JSON.parse(data).regeocode.addressComponent)
                    let datas={
                        browsers:getBrowserInfo(),
                        clientTypeSimple:clientTypeSimple,
                        location:JSON.parse(data).regeocode.addressComponent.streetNumber.location,
                        ...JSON.parse(data).regeocode.addressComponent
                    }
                  if (window.location.href.includes('code=') && one) {
                    isWxCode(datas)
                  }
                })
              }
            })
          })
        }
      })
    } catch (error) {
      if (window.location.href.includes('code=') && one) {
        isWxCode(address)
      }
    } finally
    {
    }
  }
  //获取浏览器及版本号
  const getBrowserInfo=()=>{
    var agent = navigator.userAgent.toLowerCase();
    var regStr_ie = /msie [\d.]+;/gi;
    var regStr_ff = /firefox\/[\d.]+/gi
    var regStr_chrome = /chrome\/[\d.]+/gi;
    var regStr_saf = /safari\/[\d.]+/gi;

    let str = navigator.userAgent;
    let rsg = /\((.*?)\)/;
    let arr = rsg.exec(str);
    let result = "";
    if(arr.length >= 2){
      result = arr[1];
    }else{
      result = str.toLowerCase().indexOf("mac os x") > 0 ? "mac os":"windows"
    }
    //IE
    if (agent.indexOf("msie") > 0) {
      return agent.match(regStr_ie)+'-'+result;
    }
    //firefox
    if (agent.indexOf("firefox") > 0) {
      return agent.match(regStr_ff)+'-'+result;
    }
    //Safari
    if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
      return agent.match(regStr_saf)+'-'+result;
    }
    //Chrome
    if (agent.indexOf("chrome") > 0) {
      return agent.match(regStr_chrome)+'-'+result;
    }
  //（2）然后获取版本号
    var browser = getBrowserInfo();//返回的是个长度为1的数组，根据自己的需要进行转换
    var verinfo = (browser + "").replace(/[^0-9.]/ig, "");

  }
  const getExploreName=()=>{
    var userAgent = navigator.userAgent;
    if(userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1){
      return 'Opera';
    }
    else if(userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1){
      return 'IE';
    }
    else if(userAgent.indexOf("Edge") > -1){
      return 'Edge';
    }
    else if(userAgent.indexOf("Firefox") > -1){
      return 'Firefox';
    }
    else if(userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1){
      return 'Safari';
    }
    else if(userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1){
      return 'Chrome';
    }
    else if(!!window.ActiveXObject || "ActiveXObject" in window){
      return 'IE>=11';
    }
    else{
      return 'Unkonwn';
    }
  }
  const isWxCode =async (adr)=>{
    //当微信绑定信息后存在code则直接跳转页面并清除code
    // if(window.location.href.includes('code=')){
      let obj=GetRequest()
      await setToken(obj.token)
      let wechatCodeDTO={
        data: {
          code: obj.code,
        },
        exHeader,
      }
      sessionStorage.setItem('accessType',obj.accessType)
      const res = await _wechatMp(wechatCodeDTO)
      if (res.data.code == 0&&res.status==200) {
        setOne(false)
        await setSession('wxInfo', JSON.stringify(res.data.data))
        // await setLocal('wxInfo', JSON.stringify(res.data.data))
        // await this.setState({wxInfo:res.data.data})
        gotoView(res.data.data.id,adr)
      }else{
        // gotoView()
      }
    // }
  }
  //访客模式切换
  const switchLoginMode= async ()=>{
    setLoginMode(false)
  }

  const handleCancel = () => {
    setVisible(false)
  };
  //随机选择几个数
  const randomCode = (length) => {
    const code = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    return [...Array(length)].map(() => code[Math.floor(Math.random() * code.length)]).join('');
  };

  //邮件登录
  const emailLogin=async()=>{
    wxRef.current
        .validateFields()
        .then(async (values) => {
          console.log(values.customerEmail)
          // 派发修改数据请求
          try {
            const res = await _emailLogin(
            {
              data: {email:values.customerEmail},
              exHeader,
            })
            if(res.status=='200'&&res.data.code==0){
              // await setSession('loginUser', JSON.stringify({customerEmail:values.customerEmail}))
              await setSession('loginUser', JSON.stringify({...res.data.data,customerEmail:values.customerEmail}))
              console.log(res.data)
              gotoView(res.data.data.id,'')
            }
          } catch (error) {
          } finally {

          }
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
  }
  return (
    <div className={styles.Inf_login_wrapper}>
      <div
        className={styles.blur}
        style={{
          backgroundImage: `url(${projectInfo.largeCoverPhotoInfoUrl})`,
        }}
      >
        <div className={styles.mask}></div>
      </div>
      <div className={styles.Inf_login_form}>
        {/* 头部 */}
        <div className={styles.Inf_login_header}>
          <div className={styles.Inf_login_title}>{projectInfo.name}</div>
          <div className={styles.Inf_login_text}>
            {projectInfo.shootingDayText}
          </div>
          <div className={styles.Inf_login_img}>
            <Link to={PAGE_PATH.home}>
              <img src={projectInfo.customerLogoPhotoInfoUrl} alt="" />
            </Link>
          </div>
        </div>
        {/* 尾部 */}
        <div className={styles.Inf_login_footer}>
          {projectInfo.isLock && (
            <div className={styles.Inf_login_form_wrapper}>
              <div className={styles.Inf_login_text}>
                {intl.formatMessage({ id: 'needValidate' })}
                {(loginMode&&projectInfo.isVisitor=='N')&&projectInfo.customerId!=0?(<span className={styles.visitor} onClick={switchLoginMode}>{intl.formatMessage({ id: 'gotoVisitorMode' })}</span>):null}
              </div>
              <LoginForm formRef={childRef} loginMode={loginMode} projectInfo={projectInfo}/>
            </div>
          )}
          <div className={styles.Inf_login_opt_btn}>
            {projectInfo.isLock ? (
              <Button
                onClick={doCheck}
                loading={requesting}
                className="Inf_white_btn"
              >
                {intl.formatMessage({ id: 'viewAlbum' })}
              </Button>
            ) : (
              <Button onClick={doChecks} className="Inf_white_btn">
                {intl.formatMessage({ id: 'viewAlbum' })}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.Inf_login_footers}>
          <CommonFooter websiteFooter={commonSettingData.websiteFooter}/>
        </div>
      </div>
      {wxEmailModalVisible &&
        <WxEmailModal
            formRef={wxRef}
            form={form}
            visible={wxEmailModalVisible}
            handleCancel={() => {
              setWxEmailModalVisible(false)
            }}
            emailLogin={emailLogin}
            projectInfo={projectInfo}
        />
      }
      {/* <Modal
          maskClosable={false}
          footer={[]}
          onCancel={handleCancel}
          width={600}
          visible={visible}
          wrapClassName={styles.email_modal}
          destroyOnClose={true}
      >
        <p className={styles.down_title}>收藏</p>
        <p className={styles.down_desc}>小标题</p>
        <div className={styles.wxCode}>
          <img src={wxCode}/>
        </div>
        <p className={styles.wxDec}>使用微信扫码登录，或<a style={{color:'#066DD5'}}>通过Email/手机号登录</a></p>
      </Modal> */}
    </div>
  )
}

export default Login
