import React, { useState, useEffect,useRef } from 'react'

import { Layout, Row, Col, Spin, Pagination } from 'antd'
import { Link } from 'react-router-dom'
import Imgs from 'imgs'
import { FormattedMessage } from 'react-intl'
import { PAGE_PATH } from 'apis/Router'

import { getProjectsByCategory as _getProjectsByCategory } from 'reduxModules/customerPhoto'
import { isEmpty } from 'lodash'
import { getFullRoute } from 'utils/Tools'
import styles from './index.module.less'
import commonComponents from 'components/index'
const { InfoEmptyData } = commonComponents
const { DefaultCoverPNG } = Imgs
const PhotoProjectsPage = ({ selectedId: projectCategoryId = '' }) => {
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)
  const pagination = useRef({
    pageNo: 1,
    pageSize: 20,
    total: 0,
  })
  useEffect(() => {
    getProjectsData()
  }, [])
  const getProjectsData = async () => {
    let data={
      projectCategoryId:
        !projectCategoryId || projectCategoryId === 'all' || projectCategoryId === 'null' || projectCategoryId === 'item_0' || projectCategoryId === '/collection-menu-item_0'
          ? ''
          : projectCategoryId,
      pageNo: pagination.current.pageNo,
      pageSize: pagination.current.pageSize,
    }
    const res = await _getProjectsByCategory(data)
    if (res.code == 0) {
      console.log(res)
      setProjects(res.data)
      pagination.current=res.pagination
      setLoading(false);
    }
  }
  const onChange = (page, pageSize) => {
    pagination.current={
      pageNo: page,
      pageSize: pagination.current.pageSize,
      total: pagination.current.total,
    }
    getProjectsData()
  }
  return (
    <Layout>
      <Spin tip={<FormattedMessage id="getData" />} spinning={loading}></Spin>
      {!loading && projects.length > 0 && (
        <Row gutter={[35, 40]}
        // justify="center"
        >
          {projects.length>0&&projects.map((project) => {
            return (
              <Col key={project.key} xs={{ span:24 }} sm={{ span:12 }} md={{ span:8 }} lg={{span:6}}>
                <div className={styles.project}>
                  <div className={styles.projectImgs}>
                    <img
                      src={isEmpty(project.img) ? DefaultCoverPNG : project.img}
                      className={styles.img}
                    />
                    {project.isLock ? (
                      <Link
                        to={getFullRoute(PAGE_PATH.customerVisit, {
                          id: project.id,
                        })}
                      >
                        <div className={styles.lock}>
                          {/* <svg className="icon" aria-hidden="true">
                            <use xlinkHref="#iconicon_protected"></use>
                          </svg> */}
                          <span
                          className={`iconfont iconico_protect ${styles.imgIcon}`}
                        ></span>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        to={getFullRoute(PAGE_PATH.customerVisit, {
                          id: project.id,
                        })}
                      >
                        <div className={styles.cover}></div>
                      </Link>
                    )}
                  </div>
                  <div className={styles.name}>{project.name}</div>
                  <div className={styles.remark}>{project.shootingDay=='Invalid date'?'':project.shootingDay}</div>
                </div>
              </Col>
            )
          })}
        </Row>
      ) }
      {!loading && projects.length > 0 &&  (
        <Pagination
          style={{textAlign:'center', paddingBottom:20, paddingTop:20}}
          current={pagination.current.pageNo}
          total={pagination.current.total}
          defaultPageSize={pagination.current.pageSize}
          onChange={onChange}
          showSizeChanger={false}
          hideOnSinglePage={true}
        />)}
      {!loading && projects.length < 1 && (
        <Row
          gutter={[35, 16]}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10%',
          }}
        >
          <InfoEmptyData description={<FormattedMessage id="noProject" />} />
        </Row>
      )}
    </Layout>
  )
}

export default PhotoProjectsPage
