/*
 * @Author: melon
 * @Date: 2020-08-20 17:41:35
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-25 17:29:11
 */
/*
 * @Author: melon
 * @Date: 2020-08-20 17:39:58
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-20 17:40:24
 */
import { isEmpty, map, isNil } from 'lodash/fp'

const mapWithIndex = map.convert({ cap: false })

// 获取分类
export const ShowcaseCategoryListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    list: data,
    formattedData: {
      list: isEmpty(data)
        ? []
        : map((item) => ({ ...item, key: item.id + '' }))(data),
    },
  }
  return newData
}

// 获取列表
export const ShowcasePhotoListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    data: data,
    list:
      isEmpty(data) || isEmpty(data.list)
        ? []
        : map((item) => ({
            ...item,
          }))(data.list),
    pagination: isEmpty(data)
      ? {
          pageNo: 1,
          current: 1,
          pageSize: 50,
          total: 0,
        }
      : {
          pageNo: Number(data.pageNo),
          current: Number(data.pageNo),
          pageSize: Number(data.pageSize),
          total: Number(data.totalCount),
        },
  }
  return newData
}
