//订单详情
import React, {useEffect, useState} from "react";
import styles from './details.module.less'
import {ArrowLeftOutlined} from '@ant-design/icons';
import {FormattedMessage} from 'react-intl'
import myMessage from "../../../Components/Message";
import { getSession } from 'utils/Tools'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Popconfirm, QRCode, Modal} from 'antd'
import { getOrderType, forsplitTime } from 'utils/Tools'
import Api from 'apis/Api.js'
import {
  toStore as _toStore,
  toSupplier as _toSupplier,
  toMasterCurrency as _toMasterCurrency,
} from 'reduxModules/customerPhoto'

const OrderDetails = (props) => {
  const loginUser = JSON.parse(getSession('loginUser')) || {}
  let { id } = useParams()
  const exHeader = {
    projectId: id,
  }
  const [detail, setDetail] = useState({})
  const [logistEnu, setLogistEnu] = useState([])
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [cancelVisible, setCancelVisible] = useState(false)

  useEffect(() => {
    getlogistEnu()
    getDetail()
  }, [props.orderDetail])
  useEffect(() => {
    if(!props.codeVisible) {
      getDetail()
    }
  }, [props.codeVisible])
  useEffect(() => {
    getDetail()
  }, [logistEnu])
  const getlogistEnu=async()=>{
    let json={}
    let data = {
      "method": "POST",
      "url": Api.product.logistEnu,
      "parameterJson": JSON.stringify(json),
    }
    const res = await _toSupplier({data,exHeader})
    if (res.code == 0) {
      let arr=[]
      for(let key in res.data){
        arr.push({
          id:key,
          name:res.data[key]
        })
      }
      setLogistEnu(arr)
    }else{
      myMessage.warning(res.msg);
    }
  }
  const getDetail=async ()=>{
    let json={
      orderId:props.orderDetail.id,
    }
    let data = {
      "method": "GET",
      "url": Api.product.orderDetails,
      "parameterJson": JSON.stringify(json),
    }
    const res = await _toStore({data,exHeader})
    if (res.code == 0) {
      res.data.orderTime=forsplitTime(res.data.orderDate)
      res.data.payTime=res.data.payVos[0].payTime?forsplitTime(res.data.payVos[0].payTime):''
      res.data.logisticsInfoVo=res.data.logisticsInfoVo?res.data.logisticsInfoVo:[]
      res.data.logisticsInfoVo.map(v=>{
        v.createdTime=forsplitTime(v.createdTime)
        logistEnu.map(va=>{
          if(v.logisticalCompany==va.id){
            v.logisticalCompanyText=va.name
          }
        })
      })
      res.data.logisticsName=logistEnu.logistics
      setDetail(res.data)
    }else{
      myMessage.warning(res.msg);
    }
  }
  const cancelOrder=async()=>{
    let json={
      orderId:props.orderDetail.id,
    }
    let data = {
      "method": "GET",
      "url": Api.product.cancelorder,
      "parameterJson": JSON.stringify(json),
    }
    const res = await _toStore({data,exHeader})
    if (res.code == 0) {
      setCancelVisible(false)
      getDetail()
    }else{
      myMessage.warning(res.msg);
    }
  }
  const deleteOrder=async()=>{
    let json={
      orderId:props.orderDetail.id,
    }
    let data = {
      "method": "GET",
      "url": Api.product.deleteorder,
      "parameterJson": JSON.stringify(json),
    }
    const res = await _toStore({data,exHeader})
    if (res.code == 0) {
      props.toOrderList()
    }else{
      myMessage.warning(res.msg);
    }
  }

  const payOrder = async (v) => {
    let openid = {
      //   openid: wx.getStorageSync('openId')
      //   openid: loginUser.id
    }
    let json = {
      // appid:'wx0e2aefa2f0045f55',
      appid: 'wx0e8580e0e3dcd330',
      // attach: 'myorders',
      orderId: detail.id,
      payer: openid,
      tradeType: 'NATIVE',
    }
    let data = {
      method: 'POST',
      parameterJson: JSON.stringify(json),
      url: Api.product.payorder
    }
    let res=await _toStore({data,exHeader})
    if ([0].includes(res.code)) {
      setDeleteVisible(false)
      props.setPayCode(res.data.payInfo,detail)
    }else{
      myMessage.warning(res.msg);
    }
  }
  return (
    <div className={styles.orderDetails}>
      <div className={styles.titleBar}>
        <div className={styles.forBack} onClick={()=>props.toOrderList()}>
          <div><ArrowLeftOutlined /><FormattedMessage id="orderNo" />{detail.orderNum}</div>
        </div>
        <div className={styles.actions}>
          {detail.status=="waitPay"&&(<div className={styles.actionNormal} onClick={()=>setCancelVisible(true)}><FormattedMessage id="orderCancel" /></div>)}
          {detail.status=='cancel'&&(<div className={styles.actionNormal} onClick={()=>setDeleteVisible(true)}><FormattedMessage id="orderDelete" /></div>)}
          {detail.status=="waitPay"&&(<div className={styles.actionMain} onClick={()=>payOrder()}><FormattedMessage id="orderPay" /></div>)}
        </div>
      </div>

      <div className={styles.orderSummary}>
        {detail.addressVo&&(
          <div className={styles.summaryBlock}>
            <FormattedMessage id="orderDeliveryDetails" />
            <span>{detail.addressVo.customerName}</span>
            <span>{detail.addressVo.phoneNum}</span>
            <span>{detail.addressVo.district}{detail.addressVo.address}</span>
          </div>
        )}
        <div className={styles.summaryBlock}>
        <FormattedMessage id="orderInfos" />
          <span><FormattedMessage id="orderStatus" />{getOrderType(detail.status)}</span>
          <span><FormattedMessage id="orderCreateTime" />{detail.orderTime}</span>
          {detail.payTime&&(<span><FormattedMessage id="orderPaidTime" />{detail.payTime}</span>)}
          {detail.payVos&&detail.payVos[0].status=== "paid"&&(<span><FormattedMessage id="orderPaidWay" /><FormattedMessage id="orderWechatPay" /></span>)}
          {detail.logisticsInfoVo&&detail.logisticsInfoVo.map(v=>{
            return(
              <div>
                <span><FormattedMessage id="orderShippedTime" />{v.createdTime}</span>
                <span><FormattedMessage id="orderShipmentInfo" />{v.logisticalCompanyText}（{v.logisticalNum}）</span>
              </div>
            )
          })}
        </div>
      </div>
      <div>
        {detail.remarks&&(
        <div className={styles.summaryBlock}>
          <FormattedMessage id="orderRemark" />
          <span>{detail.remarks}</span>
        </div>
        )}
      </div>

      <div>
        <div className={styles.blockTitle}><FormattedMessage id="orderPdInfos" /></div>
        <div>
          <div className={styles.orderInfo}>
            <div className={styles.id} style={{width: '50%'}}><FormattedMessage id="orderProducts" /></div>
            <div className={styles.id} style={{width: '20%'}}><FormattedMessage id="orderUnitPrice" /></div>
            <div className={styles.id} style={{width: '20%'}}><FormattedMessage id="orderPdQty" /></div>
            <div className={styles.id} style={{width: '10%', textAlign: "right"}}><FormattedMessage id="orderPdTotle" /></div>
          </div>
          {detail.productVos&&detail.productVos.map((v,i)=>{
            return(
              <div className={styles.flexContent} key={i}>
                <div className={styles.orderList} style={{width: '50%'}}>
                  <div className={styles.pdImg}>
                    <img src={v.imageUrl} alt=""/>
                  </div>
                  <div className={styles.pdInfo}>
                    <div className={styles.pdName}>{v.productName}</div>
                    <div className={styles.pdSubinfo}>{v.optionName}</div>
                  </div>
                </div>
                <div className={styles.payinfo} style={{width: '20%'}}>¥{v.salesPrice}</div>
                <div className={styles.payinfo} style={{width: '20%'}}>{v.number}</div>
                <div className={styles.actionInfo} style={{width: '10%'}}>¥{v.totalPrice}</div>
              </div>
            )
          })}
        </div>
      </div>

      <div className={styles.infoBlock}>
        <div className={styles.priceSumm}>
          <div className={styles.subPrice}>
            <span className={styles.priceTitle}><FormattedMessage id="cartSubtotal" /></span>
            <span className={styles.price}>¥{detail.productPrice}</span>
          </div>
          <div className={styles.subPrice}>
            <span className={styles.priceTitle}><FormattedMessage id="orderFreight" /></span>
            <span className={styles.price}>¥{detail.freight}</span>
          </div>
          <div className={styles.subPrice}>
            <span className={styles.priceTitle}><FormattedMessage id="orderDiscount" /></span>
            <span className={styles.price}>-¥{detail.reliefPrice?detail.reliefPrice:0}</span>
          </div>
          <div className={styles.totalPrice}>
            <span className={styles.priceTitle}><FormattedMessage id="orderTotal" /></span>
            <span className={styles.price}>¥{detail.totalMoney}</span>
          </div>
        </div>
      </div>
      <Modal
        title={<FormattedMessage id="orderCancel" />}
        visible={cancelVisible}
        onOk={cancelOrder}
        onCancel={() => setCancelVisible(false)}
        okText={<FormattedMessage id="ok" />}
        cancelText={<FormattedMessage id="cancel" />}
        width={600}
        closable={false}
        centered
        maskClosable={false}
        cancelButtonProps={{className: 'footerCanceltext'}}
      >
        <div className="Inf_page_title"><FormattedMessage id="orderDialogCancel" /></div>
      </Modal>
      <Modal
        title={<FormattedMessage id="orderDelete" />}
        visible={deleteVisible}
        onOk={deleteOrder}
        onCancel={() => setDeleteVisible(false)}
        okText={<FormattedMessage id="ok" />}
        cancelText={<FormattedMessage id="cancel" />}
        width={600}
        closable={false}
        centered
        maskClosable={false}
        cancelButtonProps={{className: 'footerCanceltext'}}
      >
        <div className="Inf_page_title"><FormattedMessage id="orderDialogDelete" /></div>
      </Modal>
    </div>

  )
}
export default OrderDetails
