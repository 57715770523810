import { Empty } from 'antd'
import React from 'react'
import styles from './index.module.less'
import { FormattedMessage } from 'react-intl'
import Imgs from 'imgs'
const { NoData } = Imgs

const InfoEmptyData = ({ description = <FormattedMessage id="noData" /> }) => (
  <div className={styles.Inf_no_data}>
    <Empty image={NoData} description={description}></Empty>
  </div>
)
export default InfoEmptyData
