// Servers
import { _login,_record } from 'servers/member'
// Selectors
import { LoginSelector } from 'selectors/memberSelectors'
// Actions

// Action Creators

// init data
const initPagination = {
  pageNo: 1,
  pageSize: 20,
}

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
export const login = (data) => _login(LoginSelector(data))
export const record = (data) => _record(data)
