/*
 * @Author: melon
 * @Date: 2020-08-20 17:38:42
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-01 20:38:46
 */
import request from 'utils/Request'

import Api from 'apis/Api.js'

// 查询当前企业公共设置(企业信息、LOGO、SEO、个人站链接等设置)
export const _getCommonSetting = async (params) => {
  return await request({
    method: 'get',
    url: Api.common.getCommonSetting,
    params: params,
  })
}

export const _getWebsiteSetting = async (params) => {
  return await request({
    method: 'get',
    url: Api.website.getWebSetting,
    params: params,
  })
}
// 查询当前账号权限
export const _getEnterpriseLevel = async (params) => {
  return await request({
    method: 'get',
    url: Api.getEnterpriseLevel.getEnterpriseLevel,
    params: params,
  })
}