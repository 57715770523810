import React, { useState } from 'react'
import { Button, Modal, Form, Input } from 'antd'
import { TrimInput } from '../WithTrim/index'
import { useIntl } from 'react-intl'

import styles from './index.module.less'
const InfDownLoadModal = ({
  visible,
  onOk,
  onCancel,
  title,
  desc,
  label = 'PIN码',
  okText,
  modalProps,
}) => {
  const [form] = Form.useForm()
  // 登录状态
  const intl = useIntl();
  const [requesting, setRequesting] = useState(false)
  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setRequesting(true)
          await onOk(values, form)
        } catch (error) {
        } finally {
          setRequesting(false)
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }
  return (
    <Modal
      {...modalProps}
      wrapClassName={styles.InfDownLoadModal}
      width={600}
      transitionName={'none'}
      maskTransitionName={'none'}
      visible={visible}
      onCancel={onCancel}
      title={
        <div className="Inf_modal_title">
          <span className="Inf_modal_title_text">{title}</span>
          <span className="Inf_modal_subtitle_text">{desc}</span>
        </div>
      }
      footer={[
        <Button
          key="move"
          className="link-btn-default"
          onClick={() => onCancel()}
        >
          {intl.formatMessage({ id: 'cancel' })}
        </Button>,
        <Button
          loading={requesting}
          key="copy"
          className="Inf_black_btn"
          onClick={() => onSubmit()}
        >
          {okText && intl.formatMessage({ id: 'submit' })}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="inf_downLoad_modal">
        <Form.Item
          name="code"
          label={label}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: 'needInput' })}${label}`,
            },
          ]}
        >
          <TrimInput max={50} placeholder={intl.formatMessage({ id: 'needInput' })} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default InfDownLoadModal
