/*
 * @Author: melon
 * @Date: 2020-06-29 11:22:13
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-01 18:54:03
 */
import { Spin } from 'antd'
import classNames from 'classnames'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getAll as _getShowCaseSetting } from 'reduxModules/showcaseSetting'
import {
  getAllService as _getServiceList,
  addReservation as _add,
} from 'reduxModules/reservation'
import { useIntl } from 'react-intl'

import ContactForm from '../Components/ContactForm'
import styles from './index.module.less'

const WebContact = (props, ref) => {
  const formRef = useRef()

  const [serviceList, setServiceList] = useState([])
  const [loading, setLoading] = useState(true)
  const intl = useIntl();
  const dispatch = useDispatch()

  let history = useHistory()
  let location = useLocation()
  const commonSetting = useSelector((state) => state.commonSetting.data)
  console.log(commonSetting)
  const formData = { ...commonSetting }
  // 获取服务列表
  const getServiceList = async () => {
    try {
      const res = await _getServiceList()
      if ([0].includes(res.code)) {
        const {
          data: {
            formattedData: { list },
          },
        } = res

        setServiceList(list)
      }
      return res
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  // 提交预约信息
  const addReservation = async (data) => {
    return await _add({ ...data })
  }

  useEffect(() => {
    getServiceList()
  }, [])
  const ContactInfo = ({ data }) => (
    <div className={styles.web_contact}>
      <div className={styles.title}>{intl.formatMessage({ id: 'contactInfo' })}</div>
      {data.areaString && (
        <div className={styles.contact_text}>{data.areaString}</div>
      )}
      {data.email && <div className={styles.contact_text}>{data.email}</div>}
      {data.contactNumber && (
        <div className={styles.contact_text}>{data.contactNumber}</div>
      )}
      {data.website && (
        <div className={styles.contact_text}><a href={`http://${data.website}`} target="_blank" style={{color: '#4a4a4a'}}>{data.website}</a></div>
      )}
      {data.wechatImagePhotoInfo && data.wechatImagePhotoInfo.photoUrl ? (
        <div className={styles.contact_wx_code}>
          <img
            src={
              data.wechatImagePhotoInfo
                ? data.wechatImagePhotoInfo.photoUrl
                : ''
            }
            className={styles.image}
          />
          <div className="Inf_page_text">{intl.formatMessage({ id: 'wxAddFriend' })}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  )

  return (
    <Spin spinning={loading}>
      <div className={classNames(styles.contact_wrapper)}>
        {/* 联系信息 */}
        <ContactInfo data={formData} />
        {/* 联系表单 */}
        <ContactForm
            intl={intl}
            formRef={formRef}
            formData={formData}
            serviceList={serviceList}
            doSubmit={addReservation}
          />
      </div>
    </Spin>
  )
}

export default forwardRef(WebContact)
