// Servers

// Selectors

// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
