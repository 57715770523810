/*
 * @Author: melon
 * @Date: 2020-08-20 17:41:35
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-28 09:06:43
 */

import { isEmpty, map, isNil } from 'lodash/fp'
import moment from 'moment'

const mapWithIndex = map.convert({ cap: false })

// 查询服务信息列表
export const ServiceListSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    list: data,
    formattedData: {
      list: isEmpty(data)
        ? []
        : map((item) => ({ ...item, key: item.id + '' }))(data),
    },
  }

  return newData
}

export const ServiceAddSelector = (data) => {
  return {
    username: data.username, // 姓名/称呼
    phone: data.phone, // 电话
    enterpriseServiceId: data.enterpriseServiceId, // 意向服务id
    sureTime: data.sureTime
      ? moment(data.sureTime).format('YYYY-MM-DD')
      : data.sureTime, // 确认时间 yyyy-MM-dd
    howToFind: data.howToFind, // 如何找到我
    remark: data.remark, //备注
    accessToken: data.accessToken, // accessToken
  }
}
