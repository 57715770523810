import React, {useEffect, useState} from 'react'
import {Button, List, Checkbox, Layout} from 'antd'
import { reject } from 'lodash/fp'
import { useIntl } from 'react-intl'
import styles from './list.module.less'
import AboutModal from "../../CategoryPhotosPage/Components/AboutModal";
import commonComponents from 'components/index'
import {useSelector} from "react-redux";
import {
    getEnterpriseLevel as _getEnterpriseLevel,
} from 'reduxModules/commonSetting'
const {
    CommonFooter,
} = commonComponents


const InfDownloadList = ({ list, onDownload, renderItem }) => {
    const commonSettingData = useSelector((state) => state.commonSetting.data)
    const [EnterpriseLevelData, setEnterpriseLevelData] = useState({})
  const [checkedIds, setCheckedIds] = useState([])
  const intl = useIntl()
  const [downloadWay, setDownloadWay] = useState('local')
    useEffect(() => {
        EnterpriseLevel()
    }, [])
    const EnterpriseLevel = async () => {
        const res = await _getEnterpriseLevel({})
        console.log(res,'+++')
        setEnterpriseLevelData(res.data.data)
    }
  const doPackDownload = async () => {
    if (downloadWay === 'local') {
      await onDownload(checkedIds)
    }
  }

  const onChangeCheckBox = (e, item) => {
    const ids = [...checkedIds]
    if (e.target.checked) {
      // 如果选中 设置 目标分类和checkedIds

      setCheckedIds([...ids, item.id])
    } else {
      // 如果取消选中 清空目标分类和checkedIds
      const newIds = reject((i) => [item.id].includes(i))(ids)
      setCheckedIds(newIds)
    }
  }
  return (
    <div className={styles.Inf_packDownload_list}>
      <div className={styles.list_title}>{intl.formatMessage({ id: 'selectFile' })}</div>
      {list.length>0&&(
      <List
        itemLayout="vertical"
        dataSource={list}
        renderItem={(item) => (
          <List.Item
            extra={
              <Checkbox
                checked={checkedIds.includes(item.id)}
                onChange={(e) => onChangeCheckBox(e, item)}
              ></Checkbox>
            }
          >
            {renderItem(item)}
          </List.Item>
        )}
      />
      )}
      <div className={styles['download_text']}>{intl.formatMessage({ id: 'downloadWay' })}</div>
      <div className={styles['download_button_group']}>
        <Button
          key="copy"
          size="large"
          onClick={() => setDownloadWay('local')}
          style={downloadWay === 'local' && checkedIds.length ? { border: '1px solid #4A4A4A', color: '#4A4A4A' } : {}}
          disabled={!checkedIds.length}
        >
          <div className={styles.icon_text}>
            <i className="iconfont iconbtn_download1" style={{ marginRight: '12px'}}></i>
            {intl.formatMessage({ id: 'downloadToLocal' })}
          </div>
        </Button>
        {/* <Button
          size="large"
          style={downloadWay === 'baidu' && checkedIds.length ? { border: '1px solid #4A4A4A', color: '#4A4A4A' } : {}}
          onClick={() => setDownloadWay('baidu')}
          disabled={!checkedIds.length}
        >
          <div className={styles.icon_text}>
            <i className="iconfont iconbtn_baidupan" style={{ marginRight: '12px'}}></i>
            {intl.formatMessage({ id: 'exportToBaidu' })}
          </div>
        </Button> */}
      </div>
      <Button
        size="large"
        className="Inf_black_btn"
        disabled={!checkedIds.length}
        style={{ width: 100, height: 40, marginTop: 70, marginBottom: 50 }}
        onClick={doPackDownload}
      >
        {intl.formatMessage({ id: 'ok' })}
      </Button>
        <CommonFooter websiteFooter={commonSettingData.websiteFooter} />
    </div>
  )
}

export default InfDownloadList
