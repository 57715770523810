import axios from 'axios'

import { message } from 'antd'
//
import { getDomain } from 'utils/Tools'
import { isEmpty, flow, values, join, pick, omit } from 'lodash/fp'
// 5、C端的权限验证
//    1、所有接口都必传accessToken，accessToken组成为：accessToken = Base64(访问地址后缀|项目id|token)
//    2、除了客片外，accessToken内容都只包含“访问地址后缀”，多余的服务器会自动剔除忽略。
//    3、客片登录后，服务器会返回登录token，作为accessToken其中的一项。
//    4、accessToken每一项参数都以英文符"|"分割
//    5、accessToken需要参数组装后进行一次Base64加密，前端处理示例：
// 		base64编码：window.btoa('xxxx')
//         base64解码：window.atob("xxxxxxxx==")
// 		访问首页时，当前accessToken = Base64('3xxj7892')
// 		访问作品时，当前accessToken = Base64('3xxj7892')
// 		访问客片时:
// 			情况1(游客访问)：当前accessToken = Base64('3xxj7892|123124423874')
// 			情况2(登录访问)：当前accessToken = Base64('3xxj7892|123124423874|${登录接口返回的token}')

// servers 调用 request 方法  headers 传递 exHeader 严格按照 exHeader= { projectId, token }

export const setToken = (userToken) => {
  const tokenStorage = sessionStorage.getItem('token')
  if (isEmpty(tokenStorage)) {
    sessionStorage.setItem('token', userToken)
  } else {
    sessionStorage.removeItem('token')
    sessionStorage.setItem('token', userToken)
  }
}
export const getToken = () => {
  const tokenStorage = sessionStorage.getItem('token')
  return tokenStorage
}

export const removeToken = () => {
  sessionStorage.removeItem('token')
}
/**
 * 请求拦截器
 * 处理http请求request，添加token等
 */
axios.interceptors.request.use(
  (config) => {
    const domain = getDomain()
    // const domain = 'ucdworks.infotos.co';
    const exHeader = config.headers.exHeader
    const token = getToken()
    const accessTokenString = isEmpty(exHeader)
      ? domain
      : flow(
          values,
          join('|')
        )({ domain, ...pick(['projectId'])(exHeader), token })
    const accessToken = window.btoa(accessTokenString)

    // 自动设置 accessToken
    config.headers.accessToken = accessToken
    // 去掉exHeader
    config.headers = omit(['exHeader'])(config.headers)

    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'mock'
    ) {
      // console.log('---request--->', config)
    }
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)
/**
 * 响应拦截器
 * 处理http响应response，判断异常或错误等
 */
axios.interceptors.response.use(
  (response) => {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'mock'
    ) {
      // console.log('---response--->', response)
    }
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是2开头的的情况
  // 下面列举几个常见的操作，其他需求可自行扩展
  (error) => {
    // console.log('======error console=====>')
    // console.log(error)
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          message.error('身份验证失败，请关闭重新进入。')
          break

        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          message.error('登录过期，请关闭重新进入。')
          // 清除token
          break

        // 404请求不存在
        case 404:
          message.error('您访问的网页不存在。')
          break
        // 其他错误，直接抛出错误提示
        default:
          message.error(error.response.data.message)
      }
      return Promise.reject(error.response)
    }
  }
)

/**
 *
 * @param {*} options
 *
 * 用法
 *
  request({
      method: 'post',
      url: 'www.baidu.com',
      headers: { 'Content-Type': '' },
      data: {
        firstName: 'Fred',
        lastName: 'Flintstone',
      },
    })
 */

export default function request(options) {
  return axios(options)
}
