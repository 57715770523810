/*
 * @Author: melon
 * @Date: 2020-08-20 17:38:50
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-04 16:34:59
 */
import request from 'utils/Request'

import Api from 'apis/Api.js'

// 查询展示信息
/**
 * 
 * @param {*} param0 
 *  showcaseType *
string
(query)	
展示类型

Available values : INDEX, WORKS
 */
export const _getAllShowcaseCategory = async ({ showcaseType }) => {
  return await request({
    method: 'get',
    url: Api.showcasePhoto.getAllShowcaseCategory,
    params: {
      showcaseType,
    },
  })
}

// 分页查询展示图片
/**
 * categoryId
integer($int64)
(query)	
展示分类id



Available values : Y, N

pageNo
integer($int32)
(query)	
当前页面

pageSize
integer($int32)
(query)	
分页大小

showcaseType
string
(query)	
展示类型

Available values : INDEX, WORKS
 * @param {*} param0 
 */
export const _getShowcasePhotoForPage = async ({
  pageNo = 1,
  pageSize = 50,
  categoryId = undefined,
  showcaseType = undefined,
}) => {
  return await request({
    method: 'get',
    url: Api.showcasePhoto.getShowcasePhotoForPage,

    params: { pageNo, pageSize, categoryId, showcaseType },
  })
}
