import React from 'react'
import { Button } from 'antd'
import { FormattedMessage } from 'react-intl';
import styles from './bottomNotice.module.less'

export default class BottomNotice extends React.Component {
  state = {}

  componentDidMount() {}

  // hiddenTip = async () => {
  //   const { _paymentTip, bookMarkDetail, exHeader } = this.props
  //   await _paymentTip({
  //     data: {
  //       bookmarkId: bookMarkDetail.id,
  //     },
  //     exHeader: exHeader,
  //   })
  //   this.setState({
  //     visiable: false,
  //   })
  // }

  render() {
    // const { visiable } = this.state
    const { bookMarkDetail, isShow } = this.props
    const expendCount = isNaN(
      bookMarkDetail.selectedCount - bookMarkDetail.maxCount
    )
      ? 0
      : bookMarkDetail.selectedCount - bookMarkDetail.maxCount
    return (
      isShow && (
        <div
          className={styles.bottomNotice}
          // style={{
          //   display:
          //     bookMarkDetail.maxCount < bookMarkDetail.selectedCount && visiable
          //       ? ''
          //       : 'none',
          // }}
        >
          <div className={styles.bottomNoticeContent}>
            <div>
              <div>
              <span
                  className={`iconfont iconicon_remind ${styles.descIcon}`}
                ></span><FormattedMessage id="youCollection" />
                <span className={styles.fontBord}>
                {bookMarkDetail.name}
                </span>
                <FormattedMessage id="choose" />
                <span className={styles.fontBord}>
                  {bookMarkDetail.selectedCount}
                </span>
                <FormattedMessage id="beyond" />
                <span className={styles.fontBord}>{expendCount}</span>
                <FormattedMessage id="extract" />
                <span className={styles.fontBord}>
                  {(expendCount * bookMarkDetail.price).toString()}
                </span>
                <FormattedMessage id="fee" />
              </div>
              {/* <div className={styles.desc}>
                <span
                  className={`iconfont iconicon_remind ${styles.descIcon}`}
                ></span>
                确定完成收藏整理后请点击“完成”按钮以提交您的信息。
              </div> */}
            </div>
            {/* <div>
              <Button
                type="primary"
                className="Inf_black_btn"
                onClick={this.hiddenTip}
              >
                完成
              </Button>
            </div> */}
          </div>
        </div>
      )
    )
  }
}
