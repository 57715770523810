import request from 'utils/Request'

import Api from 'apis/Api.js'

//  查询服务信息列表
export const _getAllService = async (params = {}) => {
  return await request({
    method: 'get',
    url: Api.reservation.getAllService,
    headers: {
      accessToken: params.accessToken,
    },
    params: params,
  })
}
// 提交预约信息
export const _addReservation = async (data = {}) => {
  return await request({
    method: 'post',
    url: Api.reservation.add,
    headers: {
      accessToken: data.accessToken,
    },
    data: data,
  })
}
