/*
 * @Author: melon
 * @Date: 2020-08-20 17:41:32
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-02 23:16:01
 */
// 登录数据
export const LoginSelector = (data) => {
  return {
    ...data,
  }
}
