import Mock from 'mockjs'

import {
  AlignTypes,
  DefaultIndexTypes,
  WebLayout,
} from '../../Constants/Options'
import Imgs from 'imgs'
import { map } from 'lodash/fp'
const { LogoWT, Wxcode } = Imgs
const WebLayoutValues = map(({ value }) => value)(WebLayout)
const DefaultIndexTypesValues = map(({ value }) => value)(DefaultIndexTypes)
const AlignTypesValues = map(({ value }) => value)(AlignTypes)
const { Random } = Mock

// 拓展mockjs
Mock.Random.extend({
  phone: function () {
    var phonePrefixs = ['132', '135', '189']
    return this.pick(phonePrefixs) + Mock.mock(/\d{8}/) //Number()
  },
})

export const commonSetting = {
  name: Random.cword(4) + '企业名称', // 企业名称
  remark: Random.cword(100), // 企业简介
  contactNumber: Random.phone(), // 联系电话
  email: Random.email(), // 联系email
  provinceGeoId: '110000', // 省id
  cityGeoId: '110100', // 市id
  areaGeoId: '110105', // 区id
  address: Random.city(true), // 详细街道地址
  wechatImageKey: Random.name(), // 联系微信图片key
  wechatImagePhotoInfo: {
    photoKey: 'string', // 图片Key
    photoUrl: [
      // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // 'http://photo.infotos.cn/logo4.jfif1594463001',
      // 'http://photo.infotos.cn/logo3.jfif1594463406',
    ][Random.natural(0, 3)], // 图片完整Url
    photoSize: Random.natural(1, 500), // 图片大小(kb)
    photoFormat: ['png', 'jpg', 'jpeg'][Random.natural(0, 1)], // 图片格式
    photoWidth: Random.natural(1, 60), // 图片宽度(px)
    photoHeight: Random.natural(1, 60), // 图片高度(px)
  },
  weiboUrl: Random.url(), // 微博地址
  wechatNo: 'string', // 微信号/微信公众号
  instagram: Random.url(), // instagram
  brandLogo: 'string', // 品牌logo
  brandLogoPhotoInfo: {
    photoKey: 'string', // 图片Key
    photoUrl: [
      // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // 'http://photo.infotos.cn/logo4.jfif1594463001',
      // 'http://photo.infotos.cn/logo3.jfif1594463406',
    ][Random.natural(0, 3)], // 图片完整Url
    photoSize: Random.natural(1, 500), // 图片大小(kb)
    photoFormat: ['png', 'jpg', 'jpeg'][Random.natural(0, 1)], // 图片格式
    photoWidth: Random.natural(1, 60), // 图片宽度(px)
    photoHeight: Random.natural(1, 60), // 图片高度(px)
  },
  customerLogo: 'string', // 客片logo
  customerLogoPhotoInfo: {
    photoKey: 'string', // 图片Key
    photoUrl: Random.image('60x60', Random.color()), // 图片完整Url
    photoSize: Random.natural(1, 500), // 图片大小(kb)
    photoFormat: ['png', 'jpg', 'jpeg'][Random.natural(0, 1)], // 图片格式
    photoWidth: Random.natural(1, 60), // 图片宽度(px)
    photoHeight: Random.natural(1, 60), // 图片高度(px)
  },
  websiteIcon: 'string', // 网站图标
  websiteIconPhotoInfo: {
    photoKey: 'string', // 图片Key
    photoUrl: [
      // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // 'http://photo.infotos.cn/logo4.jfif1594463001',
      // 'http://photo.infotos.cn/logo3.jfif1594463406',
    ][Random.natural(0, 3)], // 图片完整Url
    photoSize: Random.natural(1, 500), // 图片大小(kb)
    photoFormat: ['png', 'jpg', 'jpeg'][Random.natural(0, 1)], // 图片格式
    photoWidth: Random.natural(1, 60), // 图片宽度(px)
    photoHeight: Random.natural(1, 60), // 图片高度(px)
  },
  websiteLayoutType: WebLayoutValues[1], //网站布局类型
  websiteTitle: Random.name() + '标题', // 网站标题
  websiteFooter: Random.name() + '尾部', // 页面底部文字
  websiteDesc: Random.name() + '描述', // 网站描述
  websiteKeywords: Random.name() + '关键词', // 关键词
}
