//我的订单列表

import React, {useEffect, useRef, useState } from "react";
import { Pagination,  } from 'antd';
import styles from './orderList.module.less'
import {FormattedMessage} from 'react-intl'
import { getSession } from 'utils/Tools'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  toStore as _toStore,
  toMasterCurrency as _toMasterCurrency,
} from 'reduxModules/customerPhoto'
import Api from 'apis/Api.js'
import myMessage from "../../../Components/Message";
import { getOrderType,QRCode } from 'utils/Tools'

const OrderList = (props) => {
  const loginUser = JSON.parse(getSession('loginUser')) || {}
  let { id } = useParams()
  const exHeader = {
    projectId: id,
  }
  const pagination = useRef({
    pageNo: 1,
    pageSize: 20,
    total: 0,
    current:1
  })
  const [order, setOrder] = useState([])
  useEffect(() => {
    pagination.current.pageNo=1
    getOrder()
  }, [])
  useEffect(() => {
    if(!props.codeVisible) {
      pagination.current.pageNo = 1
      getOrder()
    }
  }, [props.codeVisible])
  const getOrder= async ()=>{
    let json={
      customerId:loginUser.id,
      pageNum: pagination.current.pageNo,
      pageSize: pagination.current.pageSize,
    }
    let data = {
      "method": "POST",
      "url": Api.product.getAllorder,
      "parameterJson": JSON.stringify(json),
    }
    const res = await _toStore({data,exHeader})
    if (res.code == 0) {
      let num=JSON.parse(JSON.stringify(res.data.current))
      pagination.current.pageNo=num<res.data.pages?num++:num
      pagination.current.total=res.data.total
      pagination.current.current=res.data.current
      setOrder(res.data.records)
    }else{
      myMessage.warning(res.msg);
    }
  }
  const payOrder = async (v) => {
    let openid = {
    //   openid: wx.getStorageSync('openId')
    //   openid: loginUser.id
    }
    let json = {
      // appid:'wx0e2aefa2f0045f55',
      appid: 'wx0e8580e0e3dcd330',
      // attach: 'myorders',
      orderId: v.id,
      payer: openid,
      tradeType: 'NATIVE',
    }
    let data = {
      method: 'POST',
      parameterJson: JSON.stringify(json),
      url: Api.product.payorder
    }
    let res=await _toStore({data,exHeader})
    if ([0].includes(res.code)) {
      props.setPayCode(res.data.payInfo,v)
    }else{
      myMessage.warning(res.msg);
    }
  }
  return (
    <div className={styles.myOrders}>
      <div className={styles.titleBar}>{order.length!==0&&<FormattedMessage id="myOrders" />}</div>
      {order.map((v,i)=>{
        return(
          <div className={styles.orderList} key={i}>
            <div className={styles.orderInfo}>
              <div className={styles.id}>{v.orderDate.split(' ')[0]}<span><FormattedMessage id="orderNo" />{v.orderNum}</span></div>
              <div className={styles.status}>{getOrderType(v.status)}</div>
            </div>
            <div className={styles.OrderContent}>
              <div>
                {v.productVos.map(va=>{
                  return(
                    <div key={va.id} className={styles.pdList}>
                      <div className={styles.pdImg}>
                        <img src={va.imageUrl} alt=""/>
                      </div>
                      <div className={styles.pdInfo}>
                        <div className={styles.pdName}>{va.productName}</div>
                        <div className={styles.pdSubinfo}>
                          {va.optionName}
                        </div>
                        <div className={styles.pdSubinfo}>×{va.number}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className={styles.payinfo}><FormattedMessage id="orderTotalList" /> ¥{v.totalMoney}{v.status== "waitPay"&&(<span><FormattedMessage id="orderPendingPayment" /></span>)}</div>
              <div className={styles.actions}>
                {v.status== "waitPay"&&(<div className={styles.actionMain} onClick={()=>payOrder(v)}><FormattedMessage id="orderPay" /></div>)}
                <div className={styles.actionInfo} onClick={()=>props.toOrderDetails(v)}><FormattedMessage id="orderDetails" /></div>
              </div>
            </div>
          </div>
        )
      })}
      {order.length==0&&(<div className={`iconfont iconico_remind ${styles.noOrder}`}><span><FormattedMessage id="noOrders" /></span></div>)}
      {order.length>20&&(
        <Pagination defaultCurrent={pagination.current.current} total={pagination.current.total} pageSize={pagination.current.pageSize}/>
      )}
    </div>
  )
}
export default OrderList
