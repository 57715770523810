import commonComponents from 'components/index'
import { WebLayout } from 'constants/Options'
import React from 'react'
import { useSelector } from 'react-redux'
// servers
import { getShowcasePhotoForPage as _getPhotos } from 'reduxModules/showcasePhoto'

// 引入瀑布流
const { InfMasonryImgs, CommonFooter } = commonComponents
const Index = ({ selectedId: categoryId, scrollParentRef }) => {
  const commonSettingData = useSelector((state) => state.commonSetting.data)
  const getList = async (data) => {
    return await _getPhotos({
      ...data,
      showcaseType: 'WORKS',
      categoryId:
        !categoryId || categoryId === 'all' || categoryId === 'null'
          ? ''
          : categoryId,
    })
  }
  return (
    <>
      <InfMasonryImgs
        getList={getList}
        key={categoryId}
        categoryId={categoryId}
        scrollParentRef={scrollParentRef}
        isUseWindowScroll={
          WebLayout[0].value === commonSettingData.websiteLayoutType
        }
      />

      <CommonFooter websiteFooter={commonSettingData.websiteFooter} />
    </>
  )
}

export default Index
