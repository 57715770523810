import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { ConfigProvider } from 'antd'
import App from './App'

import zhCN from 'antd/es/locale/zh_CN'
ReactDOM.render(
  <ConfigProvider locale={zhCN} autoInsertSpaceInButton={false}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
)
