// Servers
import { _getAllService, _addReservation } from 'servers/reservation'
// Selectors
import {
  ServiceListSelector,
  ServiceAddSelector,
} from 'selectors/reservationSelectors'
// Actions

// Action Creators

// init data
const initPagination = {
  pageNo: 1,
  pageSize: 20,
}

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
export const getAllService = async (params) => {
  const res = await _getAllService(params)

  return new Promise(function (resolve, reject) {
    resolve(ServiceListSelector(res))
    reject({})
  })
}

export const addReservation = (data) =>
  _addReservation(ServiceAddSelector(data))
