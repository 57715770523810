//我的暂存

import React, {useState} from "react";
import styles from './projectList.module.less'
import {FormattedMessage} from 'react-intl'

const ProjectList = (props) => {
  return (
    <div className={styles.myProjects}>
      <div className={styles.titleBar}><FormattedMessage id="myProjects" /></div>
      <div className={styles.projectList}>
        <div className={styles.projectContent}>
          <div>
            <div className={styles.pdList}>
              <div className={styles.pdImg}>
                
              </div>
              <div className={styles.pdInfo}>
                <div className={styles.pdName}>PhotoAlbum</div>
                <div className={styles.pdSubinfo}>
                  产品选项，24页，21cm×21cm
                </div>
                <div className={styles.acDelete}>删除</div>
              </div>
            </div>
          </div>
          <div className={styles.payinfo}><FormattedMessage id="lastModified" />2023/09/23 12:09</div>
          <div className={styles.actions}>
            <div className={styles.actionMain}>加入购物车</div>
            <div className={styles.actionInfo}>编辑</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProjectList
