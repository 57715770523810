import { Layout, Menu } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import classNames from 'classnames'
import { first, flow, isEmpty, map, prop } from 'lodash/fp'
import React, { useRef } from 'react'
import { useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import ContentWrapper from './Content'
import styles from './leftRightLayout.module.less'
import Imgs from 'imgs'

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

const LeftRightLayout = ({
  commonSetting,
  showcaseSetting,
  photoCategories,
  customerProjects,
  selectedId,
  setSelectedId,
  isWorks,
  isCustomer,
}) => {
  const { weiboUrl, instagram, brandLogoUrl, websiteFooter } = commonSetting
  const { menus } = showcaseSetting
  let location = useLocation()
  let history = useHistory()

  const contentRef = useRef()
  const { pathname } = useLocation()
  const [defaultMenu, setDefaultMenu] = useState(
    isCustomer
      ? 'all'
      : isWorks
      ? photoCategories.length > 0 && photoCategories[0].key
      : 'default'
  )
  const changeMenu = async ({ key }) => {
    await setSelectedId(key)
    if (key.indexOf('/') < 0) {
      const chooseId = key
      await setSelectedId(chooseId)
    } else {
      await setSelectedId('')
      setDefaultMenu('default')
    }
  }
  const onClickSubMenu = async ({ key }) => {
    if ([PAGE_PATH.works].includes(key)) {
      const firstId = isEmpty(photoCategories)
        ? ''
        : flow(first, prop('id'))(photoCategories)
      await history.push(PAGE_PATH.works)
      await setSelectedId(firstId)
      setDefaultMenu(firstId)
    }
    if ([PAGE_PATH.photoProject].includes(key)) {
      const firstId = isEmpty(customerProjects)
        ? ''
        : flow(first, prop('id'))(customerProjects)
      await setSelectedId(firstId)
      await history.push(PAGE_PATH.photoProject)
      setDefaultMenu('all')
    }
  }

  const defaultImg = Imgs.LogoTemp
  const [brandLogoUrlTemp, setBrandLogoUrlTemp] = useState(brandLogoUrl)
  const imgerror = (event) => {
    if (isEmpty(brandLogoUrl)) {
      setBrandLogoUrlTemp(defaultImg)
    }
  }

  return ![PAGE_PATH.webIndex, PAGE_PATH.works].includes(pathname) ? (
    <div className={styles.Inf_layout_wrapper}>
      <Layout className={styles.Inf_layout}>
        <Sider className={styles.Inf_sider}>
          <div className={styles.Inf_header_logo}>
            <a href={PAGE_PATH.home}>
              <img src={brandLogoUrlTemp} alt=""  onError={imgerror} />
            </a>
          </div>
          <div className={styles.Inf_menus_div}>
            {menus.length > 1 && (
              <Menu
                key={location.pathname + defaultMenu}
                mode="inline"
                onClick={changeMenu}
                className={styles.Inf_menus}
                defaultSelectedKeys={[location.pathname, defaultMenu]}
                openKeys={[location.pathname]}
              >
                {map((i) => {
                  const isSelectedPath = [location.pathname].includes(i.path);
                  if (["WORKS"].includes(i.key)) {
                    return (
                      <SubMenu
                        key={i.path}
                        title={i.menuName}
                        onTitleClick={onClickSubMenu}
                        className={
                          isSelectedPath ? styles.Inf_selected_menu : ""
                        }
                      >
                        {map(
                          ({
                            name: childrenName,
                            id: childrenId,
                            key: childrenKey,
                          }) => (
                            <Menu.Item
                              className={styles.Inf_sub}
                              key={childrenId}
                              title={childrenName}
                            >
                              <Link to={i.path}>{childrenName}</Link>
                            </Menu.Item>
                          )
                        )(photoCategories)}
                      </SubMenu>
                    );
                  }
                  if (["CUSTOMER_PHOTO"].includes(i.key)) {
                    return (
                      <SubMenu
                        onTitleClick={onClickSubMenu}
                        key={i.path}
                        title={i.menuName}
                        className={
                          isSelectedPath ? styles.Inf_selected_menu : ""
                        }
                      >
                        {map(
                          ({
                            name: childrenName,
                            id: childrenId,
                            key: childrenKey,
                          }) => {
                            return (
                              <Menu.Item
                                className={styles.Inf_sub}
                                key={childrenKey}
                                title={childrenName}
                              >
                                <Link to={i.path}>{childrenName}</Link>
                              </Menu.Item>
                            );
                          }
                        )(customerProjects)}
                      </SubMenu>
                    );
                  }
                  return (
                    <Menu.Item key={i.path}>
                      <Link to={i.path}>{i.menuName}</Link>
                    </Menu.Item>
                  );
                })(menus)}
              </Menu>
            )}
            {menus.length === 1 && ["CUSTOMER_PHOTO"].includes(menus[0].key) && (
              <Menu
                mode="inline"
                onClick={changeMenu}
                className={styles.Inf_menus}
                defaultSelectedKeys={customerProjects[0] ? [customerProjects[0].id] : []}
                // triggerSubMenuAction="hover"
                triggerSubMenuAction="click"
              >
                {map(({ name, id }) => (
                  <Menu.Item key={id} className={styles.subMenuLi}>
                    <Link to={menus[0].path}>{name}</Link>
                  </Menu.Item>
                ))(customerProjects)}
              </Menu>
            )}
          </div>
          <div className={styles.Inf_menu_opt}>
            {/* 微博地址 */}
            {!isEmpty(weiboUrl) && (
              // <Tooltip placement="bottom" title={'微博地址'}>
              <a
                className={styles.Inf_menu_opt_item}
                target="_blank"
                href={`https://${weiboUrl}`}
              >
                <svg className="icon" aria-hidden="true">
                  <use xlinkHref="#iconicon_sc_weibo"></use>
                </svg>
              </a>
              // </Tooltip>
            )}
            {/* ins 地址 */}
            {!isEmpty(instagram) && (
              // <Tooltip placement="bottom" title={'instagram地址'}>
              <a
                className={styles.Inf_menu_opt_item}
                target="_blank"
                href={`https://${instagram}`}
              >
                <svg className="icon" aria-hidden="true">
                  <use xlinkHref="#iconicon_sc_instagram"></use>
                </svg>
              </a>
              // </Tooltip>
            )}
          </div>
        </Sider>

        <Layout ref={contentRef}>
          <ContentWrapper
            contentStyle={{
              // background: '#fff',
              // paddingRight: 70,
            }}
            selectedId={selectedId}
            websiteFooter={websiteFooter}
            scrollParentRef={contentRef}
            key={selectedId}
          />
        </Layout>
      </Layout>
    </div>
  ) : (
    <div
      className={classNames(styles.Inf_layout_wrapper, styles.scroller_layout)}
    >
      <Layout className={styles.Inf_layout}>
        <div className={styles.Inf_scroller_sider}>
          <Sider className={styles.Inf_sider}>
            <div className={styles.Inf_header_logo}>
              <a href={PAGE_PATH.home}>
                <img src={brandLogoUrl} alt="" />
              </a>
            </div>
            <div className={styles.Inf_menus_div}>
              {menus.length > 1 && (
                <Menu
                  mode="inline"
                  key={location.pathname + defaultMenu}
                  onClick={changeMenu}
                  className={styles.Inf_menus}
                  defaultSelectedKeys={[location.pathname, defaultMenu]}
                  openKeys={[location.pathname]}
                >
                  {map((i) => {
                    const isSelectedPath = [location.pathname].includes(i.path);

                    if (["WORKS"].includes(i.key)) {
                      return (
                        <SubMenu
                          key={i.path}
                          title={i.menuName}
                          onTitleClick={onClickSubMenu}
                          className={
                            isSelectedPath ? styles.Inf_selected_menu : ""
                          }
                        >
                          {map(
                            ({
                              name: childrenName,
                              id: childrenId,
                              key: childrenKey,
                            }) => (
                              <Menu.Item
                                className={styles.Inf_sub}
                                key={childrenId}
                                title={childrenName}
                              >
                                <Link to={i.path}>{childrenName}</Link>
                              </Menu.Item>
                            )
                          )(photoCategories)}
                        </SubMenu>
                      );
                    }
                    if (["CUSTOMER_PHOTO"].includes(i.key)) {
                      return (
                        <SubMenu
                          onTitleClick={onClickSubMenu}
                          key={i.path}
                          title={i.menuName}
                          className={
                            isSelectedPath ? styles.Inf_selected_menu : ""
                          }
                        >
                          {map(
                            ({
                              name: childrenName,
                              id: childrenId,
                              key: childrenKey,
                            }) => (
                              <Menu.Item key={childrenId} title={childrenName}>
                                <Link to={i.path}>{childrenName}</Link>
                              </Menu.Item>
                            )
                          )(customerProjects)}
                        </SubMenu>
                      );
                    }
                    return (
                      <Menu.Item key={i.path} title={i.menuName}>
                        <Link to={i.path}>{i.menuName}</Link>
                      </Menu.Item>
                    );
                  })(menus)}
                </Menu>
              )}
              {menus.length === 1 && ["WORKS"].includes(menus[0].key) && (
                <Menu
                  mode="inline"
                  onClick={changeMenu}
                  className={styles.Inf_menus}
                  defaultSelectedKeys={photoCategories[0] ? [photoCategories[0].id] : []}
                  // triggerSubMenuAction="hover"
                  triggerSubMenuAction="click"
                >
                  {map(({ name, id }) => (
                    <Menu.Item key={id} className={styles.subMenuLi}>
                      <Link to={menus[0].path}>{name}</Link>
                    </Menu.Item>
                  ))(photoCategories)}
                </Menu>
              )}
            </div>
            <div className={styles.Inf_menu_opt}>
              {/* 微博地址 */}
              {!isEmpty(weiboUrl) && (
                // <Tooltip placement="bottom" title={'微博地址'}>
                <a
                  className={styles.Inf_menu_opt_item}
                  target="_blank"
                  href={`https://${weiboUrl}`}
                >
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#iconicon_sc_weibo"></use>
                  </svg>
                </a>
                // </Tooltip>
              )}
              {/* ins 地址 */}
              {!isEmpty(instagram) && (
                // <Tooltip placement="bottom" title={'instagram地址'}>
                <a
                  className={styles.Inf_menu_opt_item}
                  target="_blank"
                  href={`https://${instagram}`}
                >
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#iconicon_sc_instagram"></use>
                  </svg>
                </a>
                // </Tooltip>
              )}
            </div>
          </Sider>
        </div>

        <div className={styles.Inf_scroller_content} ref={contentRef}>
          <ContentWrapper
            // contentStyle={{
            //   background: '#fff',
            //   paddingRight: 70,
            // }}
            selectedId={selectedId}
            websiteFooter={websiteFooter}
            minHeight={"calc(100% - 100px)"}
            scrollParentRef={contentRef}
            key={selectedId}
          />
        </div>
      </Layout>
    </div>
  );
};

export default LeftRightLayout;
