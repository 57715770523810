import { message } from 'antd'
import React from 'react'

const BottomMessage = {
  info: (content, imgUrl) => {
    message.config({
      // top: window.screen.height - 240,
      maxCount: 3,
    })
    message.info({
      content: content,
      style: {
        marginTop: '90vh',
      },
      className: 'bottom-message',
      icon: imgUrl && <img className="bottom-img" src={imgUrl}></img>,
    })
  },
}
export default BottomMessage
