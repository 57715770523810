import React from 'react'

import { Button } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import Router, { PAGE_PATH } from 'apis/Router.js'

import styles from './index.module.less'

const InfErrorPage = ({
  showBack = false,
  imgSrc = '',
  remindTitle = '无访问权限',
  remindText = '请联系您的服务商',
  cover = false,
}) => {
  let history = useHistory()
  const intl = useIntl()
  return (
    <div className={styles.InfErrorPage}>
      {/* logo */}
      <div className={styles.logo}>
        <img
          src={imgSrc}
          // style={
          //   cover ? { width: '100%', height: 150, objectFit: 'contain' } : {}
          // }
        />
      </div>
      {/* 提醒 */}
      <div className={styles.remind}>
        <div className={styles.remind_icon}>
          <svg className="icon" aria-hidden="true">
            <use
              style={{ transform: 'rotate(180deg, 180deg)' }}
              xlinkHref="#iconicon_remind"
            ></use>
          </svg>
        </div>
        <span className={styles.remind_text}>{remindTitle}</span>
        <span className="Inf_page_text">{remindText}</span>
      </div>

      {/* 返回按钮 */}
      {showBack && (
        <Button onClick={history.goBack}>
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#iconbtn_back"></use>
          </svg>
          {intl.formatMessage({ id: 'back' })}
        </Button>
      )}
    </div>
  )
}

export default InfErrorPage
