import React from 'react'

import { Layout, Card, Tabs, Avatar } from 'antd'

import { Route, Switch, useLocation } from 'react-router-dom'

import Router, { PAGE_PATH } from 'apis/Router'

import { reject } from 'lodash/fp'
import { useSelector } from 'react-redux'
import commonComponents from 'components/index'

import styles from './Content.module.less'

const { Content, Footer } = Layout
const { CommonFooter } = commonComponents
const ContentWrapper = (props) => {
  const {
    contentStyle,
    websiteFooter,
    minHeight,
    selectedId,
    scrollParentRef,
  } = props
  const { pathname } = useLocation()
  const commonSettingData = useSelector((state) => state.commonSetting.data)

  const ContentRouter = reject(({ path }) =>
    [PAGE_PATH.webIndex, PAGE_PATH.works].includes(path)
  )(Router)
  return ![PAGE_PATH.webIndex, PAGE_PATH.works].includes(pathname) ? (
    <Content
      style={{
        ...contentStyle,
        paddingTop: 50,
      }}
    >
      <div style={{ minHeight }}>
        <Switch>
          {ContentRouter.map((route, i) => (
            <Route
              key={i}
              path={route.path}
              render={() => (
                // pass the sub-routes down to keep nesting
                <route.component
                  {...route}
                  selectedId={selectedId}
                  scrollParentRef={scrollParentRef}
                />
              )}
            />
          ))}
        </Switch>
      </div>
      <CommonFooter websiteFooter={commonSettingData.websiteFooter} />
    </Content>
  ) : (
    <Content
      style={{
        ...contentStyle,
        paddingTop: 50,
      }}
    >
      <div style={{ minHeight }}>
        <Switch>
          {Router.map((route, i) => (
            <Route
              key={i}
              path={route.path}
              render={() => (
                // pass the sub-routes down to keep nesting
                <route.component
                  {...route}
                  selectedId={selectedId}
                  scrollParentRef={scrollParentRef}
                />
              )}
            />
          ))}
        </Switch>
      </div>
    </Content>
  )
}

export default ContentWrapper
