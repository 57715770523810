import {
  Carousel,
  Dropdown,
  Empty,
  Form,
  Input,
  Modal,
  Tooltip,
  Typography,
  Popover,
  Button,
} from 'antd'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { has } from 'lodash'
import styles from './index.module.less'

const { Paragraph } = Typography
const { TextArea } = Input

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style }} onClick={onClick} />
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style }} onClick={onClick} />
}


const GetContent = ({
  item,
  saveMessage,
  setVisible,
  deleteMessage,
  index,
}) => {
  const intl = useIntl()
  const [form] = Form.useForm();
  const submit = ({ message }) => {
    saveMessage({
      message,
      item,
      key: index,
    })
    setVisible(false)
  }
  const deleteMsg = () => {
    deleteMessage({
      item,
      key: index,
    })
    setVisible(false)
  }
  return (
    <Form
      onFinish={submit}
      form={form}
      initialValues={{ message: item.message }}
    >
      <Form.Item
        noStyle
        name="message"
        rules={[{ max: 100, message: intl.formatMessage({ id: 'max100'})}]}
      >
        <TextArea
          placeholder={intl.formatMessage({ id: 'remarkMax100'})}
          rows={3}
          bordered={false}
          style={{ resize: 'none', width: 260 }}
          onClick={(e) => {
            e.stopPropagation()
          }}
          maxLength={100}
        />
      </Form.Item>
      <div className={styles.messageBtn}>
        <a
          onClick={(e) => {
            e.stopPropagation()
            deleteMsg()
          }}
          type="link"
          className="dange-default-btn"
          style={{ visibility: item.message.length > 0 ? '' : 'hidden' }}
        >
          {intl.formatMessage({ id: 'delete' })}
        </a>

        <div>
          <Button
            type="link"
            onClick={(e) => {
              e.stopPropagation()
              form.resetFields();
              setVisible(false)
            }}
            className="link-btn-default"
          >
            {intl.formatMessage({ id: 'cancel' })}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="small"
            className="Inf_black_btn"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {intl.formatMessage({ id: 'save' })}
          </Button>
        </div>
      </div>
    </Form>
  )
}

const Opt = ({
  item = {},
  index,
  menuFeild,
  setPicHide,
  downloadImg,
  project,
  cancelBookmark = () => {},
  favoriteList,
  addToBookmark = () => {},
  saveMessage,
  deleteMessage,
  isFav,
  canDownload,
}) => {
  const intl = useIntl()
  const [visible, setVisible] = useState(false)
  console.log(favoriteList)
  const showFav = menuFeild ? (
    favoriteList.length <= 1 ? (
      <Tooltip
        placement="bottom"
        title={intl.formatMessage({ id: 'collectionOpt' })}
        overlayClassName="no-arrow"
      >
        <a
          onClick={() =>
            addToBookmark({
              img: item,
              index: index,
              key: favoriteList[0].id,
            })
          }
        >
          <svg className={`icon ${styles.imgIcon}`} aria-hidden="true">
            {item.isBookmarkShow ? (
              <use xlinkHref="#iconbtn_fav_on"></use>
            ) : (
              <use xlinkHref="#iconbtn_fav1"></use>
            )}
          </svg>
        </a>
      </Tooltip>
    ) : (
      <Dropdown
        overlay={menuFeild({ img: item, index: index })}
        trigger="click"
      >
        <Tooltip
          placement="bottom"
          title={intl.formatMessage({ id: 'collectionOpt' })}
          overlayClassName="no-arrow"
        >
          <a>
            <svg className={`icon ${styles.imgIcon}`} aria-hidden="true">
              {item.isBookmarkShow ? (
                <use xlinkHref="#iconbtn_fav_on"></use>
              ) : (
                <use xlinkHref="#iconbtn_fav1"></use>
              )}
            </svg>
          </a>
        </Tooltip>
      </Dropdown>
    )
  ) : (
    <Tooltip placement="bottom" title={intl.formatMessage({ id: 'unCollectionOpt' })} overlayClassName="no-arrow">
      <a
        onClick={() =>
          cancelBookmark({
            img: item,
            index: index,
          })
        }
      >
        <svg className={`icon ${styles.imgIcon}`} aria-hidden="true">
          {item.isBookmarkShow ? (
            <use xlinkHref="#iconbtn_fav1"></use>
          ) : (
            <use xlinkHref="#iconbtn_fav_on"></use>
          )}
        </svg>
      </a>
    </Tooltip>
  )


  const clickPopover = ({ visible, item, key }) => {
    setVisible(visible)
    // this.setState({
    //   visible: visible,
    //   selectedImg: { img: item, index: key },
    // })
  }
  console.log(item)
  console.log(project,'project',isFav,sessionStorage.getItem('accessType')=='visitorMode' && project.isVisitorDownload=='Y')
    return (
    <div className={styles.imgBtns}>
      {project && project.isShowBookmark && showFav}

      {project && project.isSetPhotoHidden && sessionStorage.getItem('accessType')=='clientMode' && (
        <Tooltip
          placement="bottom"
          title={item.isHideShow ? intl.formatMessage({ id: 'cancelHidden' }) : intl.formatMessage({ id: 'hidden' })}
          overlayClassName="no-arrow"
        >
          <a
            onClick={(e) => {
              e.stopPropagation()
              setPicHide({
                img: item,
                index: index,
              })
            }}
          >
            <svg className={`icon ${styles.imgIcon}`} aria-hidden="true">
              {item.isHideShow ? (
                <use xlinkHref="#iconbtn_hidden_on"></use>
              ) : (
                <use xlinkHref="#iconbtn_hidden1"></use>
              )}
            </svg>
          </a>
        </Tooltip>
      )}

      {/*{project && project.isMessage && isFav && sessionStorage.getItem('accessType')=='clientMode' && (<Popover*/}
      {project && project.isMessage  && sessionStorage.getItem('accessType')=='clientMode' && (<Popover
        placement="bottomLeft"
        content={<GetContent index={index} item={item} setVisible={setVisible} saveMessage={saveMessage} deleteMessage={deleteMessage} />}
        trigger="click"
        overlayClassName="messagePopover noArrow"
        visible={visible}
        onVisibleChange={(visible) => clickPopover({ visible, item })}
        destroyTooltipOnHide={true}
      >
        <Tooltip placement="bottom" title={intl.formatMessage({ id: 'remark' })} overlayClassName="no-arrow">
        <a
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {!item.hasMessage&&!item.message ? (<svg
            className={`icon ${styles.imgIcon}`}
            aria-hidden="true"
          >
            <use xlinkHref="#iconbtn_comment"></use>
          </svg>) : (
              <i className={`iconfont iconbtn_comment_on ${styles.imgIcon}`} style={{ fontSize: 18 }}></i>
          )}
        </a>
        </Tooltip>
      </Popover>)}
      {project && project.canDownload && isFav && item.canDownload === 'Y' &&(sessionStorage.getItem('accessType')=='clientMode'||(sessionStorage.getItem('accessType')=='visitorMode' && project.isVisitorDownload=='Y'))&& (
        <Tooltip placement="bottom" title={intl.formatMessage({ id: 'downLoad' })} overlayClassName="no-arrow">
          <a
            onClick={(e) => {
              e.stopPropagation()
              downloadImg(item)
            }}
          >
            <svg className={`icon ${styles.imgIcon}`} aria-hidden="true">
              <use xlinkHref="#iconbtn_download1"></use>
            </svg>
          </a>
        </Tooltip>
      )}
      {project && project.canDownload && canDownload && !isFav &&(sessionStorage.getItem('accessType')=='clientMode'||(sessionStorage.getItem('accessType')=='visitorMode' && project.isVisitorDownload=='Y'))&& (
        <Tooltip placement="bottom" title={intl.formatMessage({ id: 'downLoad' })} overlayClassName="no-arrow">
          <a
            onClick={(e) => {
              e.stopPropagation()
              downloadImg(item)
            }}
          >
            <svg className={`icon ${styles.imgIcon}`} aria-hidden="true">
              <use xlinkHref="#iconbtn_download1"></use>
            </svg>
          </a>
        </Tooltip>
      )}
    </div>
  )
}

const PreviewCarouselModal = ({
  visible,
  onCancel,
  list = [],
  previewCurrent,
  showKey = 'description',
  downloadImg,
  saveMessage,
  setPicHide,
  menuFeild,
  pagination,
  hasMore,
  showOpt = false,
  loadData,
  setPreviewCurrent,
  cancelBookmark,
  project,
  favoriteList = [],
  addToBookmark = () => {},
  deleteMessage,
  isFav,
  canDownload,
}) => {
  const ref = useRef()
  const [imgs, setImgs] = useState(list)
  const [messageValue, setMessageValue] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [currentImg, setCurrentImg] = useState({})
  useEffect(() => {
    setCurrentImg(imgs[previewCurrent])
    window.addEventListener('keydown', onKeyDown); // 添加全局事件
    return () => {
      window.removeEventListener('keydown', onKeyDown); // 销毁
    }
  }, [imgs, previewCurrent])

  if (ref && ref.current) {
    ref.current.goTo(previewCurrent)
  }
  const changeInput = ({ target: { value } }) => {
    setMessageValue(value)
  }

  const loadNextPage = async () => {
    if (
      [imgs.length - 2, imgs.length - 1, imgs.length].includes(
        previewCurrent
      ) &&
      hasMore
    ) {
      const res = await loadData()
      await ref.current.next()
      setPreviewCurrent(previewCurrent + 1)
      const oldList = [...imgs]
      const newList = oldList.concat(res)
      setImgs(newList)
    } else {
      setIsEditing(false)
      setPreviewCurrent(previewCurrent + 1)
      ref.current.next()
    }
  }
  const saveMsg = ({ item, key }) => {
    setIsEditing(false)
    imgs[key].message = messageValue
    saveMessage({ item, key, message: messageValue })
    setImgs(imgs)
  }

  const cancelFavorite = ({ img, index }) => {
    cancelBookmark({ img, index })
  }

  const setImgHiden = ({ img, index }) => {
    setCurrentImg(imgs[index])
    setPicHide({
      img: img,
      index: index,
    })
  }

  // 参考react-slick接口
  const SliderConfig = {
    lazyLoad: true,
    // afterChange: current => this.setState({ activeSlide2: current })
    beforeChange: (from, to) => {
      let clientW = document.documentElement.clientWidth
      if (clientW < 780) {
        if (from < to) {
          loadNextPage()
        } else {
          setIsEditing(false)
          // setPreviewCurrent(previewCurrent - 1)
          ref.current.prev()
          // setCurrentImg(imgs[previewCurrent])
        }
      }
    },
  }
  //键盘按键事件
  const onKeyDown = (e) => {
    if(!ref.current) {
      return
    }
    switch (e.keyCode) {
      case 37: // 向左事件 - 位置减减
        if(previewCurrent>0){
          ref.current.prev()
          setIsEditing(false)
          setPreviewCurrent(previewCurrent - 1)
        }
        break;
      case 39: // 向右事件 - 位置加加
        if(previewCurrent + 1 < pagination.total){
          loadNextPage()
        }
        break;
    }
  }
  return (
    <Modal
      visible={visible}
      title=""
      style={{ top: 0 }}
      onCancel={() => {
        onCancel(imgs)
        setIsEditing(false)
      }}
      centered
      maskClosable={false}
      footer={false}
      wrapClassName={styles.previewWrapper}
      width="100vw"
      height="100vh"
      cancelButtonProps={{
        className: 'Inf_page_text',
        style: { border: 'none', boxShadow: 'none' },
      }}
      // destroyOnClose={true}
      mask={false}
    >
      {imgs.length > 0 ? (
        <>
          <div
            className={styles.optWrapper}
            style={{ visibility: showOpt ? 'visible' : 'hidden' }}
          >
            <Opt
              item={currentImg}
              index={previewCurrent}
              downloadImg={downloadImg}
              setPicHide={() =>
                setImgHiden({ img: currentImg, index: previewCurrent })
              }
              menuFeild={menuFeild}
              cancelBookmark={cancelFavorite}
              project={project}
              favoriteList={favoriteList}
              addToBookmark={addToBookmark}
              deleteMessage={deleteMessage}
              saveMessage={saveMessage}
              isFav={isFav}
              canDownload={canDownload}
            />
          </div>
          <SamplePrevArrow
            onClick={() => {
              ref.current.prev()
              setIsEditing(false)
              setPreviewCurrent(previewCurrent - 1)
            }}
            className={styles.Inf_prev}
            style={{
              display: [0].includes(previewCurrent) ? 'none' : 'block',
            }}
          />
          <Carousel
            className={styles.Slider}
            dots={false}
            ref={ref}
            {...SliderConfig}
          >
            {imgs.map((img, index) => {
              return (
                <div className={styles.image_wrapper} key={img.id}>
                  <img src={img.thumbInfo['large'].photoUrl} className={styles.image} />
                </div>
              )
            })}
          </Carousel>
          <SampleNextArrow
            onClick={loadNextPage}
            className={styles.Inf_next}
            style={{
              display:
                previewCurrent + 1 == pagination.total ? 'none' : 'block',
            }}
          />
          {/* 全屏时底部页数显示 */}
          <div className={styles.slider_footer}>
            {/* <div className={classNames('Inf_page_text')}>
              <span className={styles.slider_footer_text}>
                {previewCurrent + 1}/{pagination.total}
              </span>
            </div> */}
            <div className={classNames('Inf_page_text')}>
              {currentImg ? currentImg[showKey] : ''}
            </div>
          </div>
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ paddingTop: 70 }}
        />
      )}
    </Modal>
  )
}

export default PreviewCarouselModal
