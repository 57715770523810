import Mock from 'mockjs'

import Api from 'apis/Api.js'

import {
  errorResponse,
  ListPaginationDataResponse,
  successResponse,
  qiniuTokenData,
} from './Data/common'

// 公共设置
import { commonSetting } from './Data/commonSetting'
// 展示设置
import { showcaseSettingData } from './Data/showcaseSetting'
// 展示图片
import {
  getShowcaseCategoryData,
  getShowcasePhotoList,
} from './Data/showcasePhoto'
// 预约
import { getEnterpriseServiceList } from './Data/reservation'
// 用户登录
import { LoginUserData } from './Data/member'

// 客片
import {
  getPhotosByCategory,
  getPhotoCategoryData,
  getAllCanDownloadPhotoCategoryData,
  getBookmarkDownloadPhotoCategoryData,
  PhotoCategoryDownloadUrlData,
} from './Data/customerPhoto'
import {
  getProjectCategorys,
  getProjectsByCategory,
  getProjectById,
  getProjectInfoForLogin,
} from './Data/project'

// 收藏夹

import {
  getFavoriteList,
  getPhotosByBookmark,
  BookmarkDownloadUrlData,
} from './Data/bookmark'
// 可以用querystring
const getQueryVariableObj = (url) => {
  const index = url.indexOf('?')
  const query = url.slice(index + 1)
  var vars = query.split('&')
  let VariableObj = {}
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    VariableObj[pair[0]] = pair[1]
  }
  return VariableObj
}
const getPagination = ({ pageNo = '1', pageSize = '10' }) => {
  return {
    pageNo: Number(pageNo),
    pageSize: Number(pageSize),
  }
}
export default {
  /**
   * mock start
   */
  start() {
    // 设置延迟
    Mock.setup({
      timeout: 500,
    })
    // 初始化函数
    // 登录
    Mock.mock(
      RegExp(Api.member.login + '(\\?.*)*$'),
      'post',
      successResponse(LoginUserData)
    )
    /* ---------------- 公共设置 start----------------*/
    // 获取公共设置
    Mock.mock(
      RegExp(Api.common.getCommonSetting + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(commonSetting)
      }
    )
    /* ---------------- 公共设置 end----------------*/

    /* ---------------- 展示设置 start----------------*/
    // 获取展示设置
    Mock.mock(
      RegExp(Api.showcaseSetting.getAll + '(\\?.*)*$'),
      'get',
      (config) => {
        return config.body === '{}'
          ? errorResponse()
          : successResponse(showcaseSettingData)
      }
    )
    /* ---------------- 展示设置 end----------------*/

    /* ---------------- 展示图片 start----------------*/
    // 获取展示分类
    Mock.mock(
      RegExp(Api.showcasePhoto.getAllShowcaseCategory + '(\\?.*)*$'),
      'get',
      (config) => {
        const query = getQueryVariableObj(config.url)
        return config.body === '{}'
          ? errorResponse()
          : successResponse(
              getShowcaseCategoryData({
                showcaseType: query.showcaseType,
              })
            )
      }
    )
    // 分页查询
    Mock.mock(
      RegExp(Api.showcasePhoto.getShowcasePhotoForPage + '(\\?.*)*$'),
      'get',
      (config) => {
        const query = getQueryVariableObj(config.url)
        const pagination = getPagination(query)
        return config.body === '{}'
          ? errorResponse()
          : ListPaginationDataResponse(
              {
                ...pagination,
                categoryId: Number(query.categoryId),
                showcaseType: query.showcaseType,
                isShow: query.isShow,
              },
              getShowcasePhotoList(query)
            )
      }
    )
    /* ---------------- 展示图片 end----------------*/

    /* ---------------- 预约 start----------------*/
    // 服务列表
    Mock.mock(
      RegExp(Api.reservation.getAllService + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getEnterpriseServiceList())
      }
    )
    // 添加预约
    Mock.mock(RegExp(Api.reservation.add + '(\\?.*)*$'), 'post', (options) => {
      return successResponse()
    })
    /* ---------------- 预约 end----------------*/

    /* ---------------- 客片 start----------------*/
    // 客片项目分类
    Mock.mock(
      RegExp(Api.project.getProjectCategoryList + '(\\?.*)*$'),
      'get',
      successResponse(getProjectCategorys())
    )

    // 客片项目
    Mock.mock(
      RegExp(Api.project.getProjectInfoList + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getProjectsByCategory(query))
      }
    )
    // 客片详情
    Mock.mock(RegExp(Api.project.getById + '(\\?.*)*$'), 'get', (options) => {
      const query = getQueryVariableObj(options.url)
      return successResponse(getProjectById(query))
    })

    // 客片项目登录详情
    Mock.mock(
      RegExp(Api.project.getProjectInfoForLogin + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getProjectInfoForLogin(query))
      }
    )

    // 客片分页
    Mock.mock(
      RegExp(Api.customerPhoto.pageList + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getPhotosByCategory(query))
      }
    )
    // 客片分类
    Mock.mock(
      RegExp(Api.customerPhoto.photoCategoryList + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getPhotoCategoryData())
      }
    )
    // 客片设置隐藏
    Mock.mock(
      RegExp(Api.customerPhoto.setHide + '(\\?.*)*$'),
      'post',
      successResponse({})
    )
    // 客片设置收藏
    Mock.mock(
      RegExp(Api.customerPhoto.addBookmarkPhoto + '(\\?.*)*$'),
      'post',
      successResponse({})
    )
    //  下载登录
    Mock.mock(
      RegExp(Api.customerPhoto.downLoadLogin + '(\\?.*)*$'),
      'post',
      successResponse({})
    )
    // 客片单个下载
    Mock.mock(
      RegExp(Api.customerPhoto.getDownloadUrl + '(\\?.*)*$'),
      'post',
      successResponse(PhotoCategoryDownloadUrlData)
    )

    // 客片可下载分类
    Mock.mock(
      RegExp(Api.customerPhotoCategory.getAllCanDownload + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getAllCanDownloadPhotoCategoryData())
      }
    )
    // 客片打包下载地址
    Mock.mock(
      RegExp(Api.customerPhotoCategory.getDownloadUrl + '(\\?.*)*$'),
      'post',
      successResponse(PhotoCategoryDownloadUrlData)
    )
    /* ---------------- 客片 end----------------*/

    /* ---------------- 收藏夹 start----------------*/
    // 获取收藏夹分类
    Mock.mock(RegExp(Api.bookmark.getAll + '(\\?.*)*$'), 'get', (options) => {
      const query = getQueryVariableObj(options.url)
      return successResponse(getFavoriteList(query))
    })
    // 收藏夹图片分页
    Mock.mock(
      RegExp(Api.bookmarkPhoto.getListForPage + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getPhotosByBookmark(query))
      }
    )
    // 修改收藏夹评论
    Mock.mock(
      RegExp(Api.bookmarkPhoto.addMessage + '(\\?.*)*$'),
      'post',
      successResponse({})
    )
    // 取消图片收藏
    Mock.mock(
      RegExp(Api.bookmarkPhoto.cancel + '(\\?.*)*$'),
      'post',
      successResponse({})
    )
    // 设置收藏夹图片显示/隐藏
    Mock.mock(
      RegExp(Api.bookmarkPhoto.setHide + '(\\?.*)*$'),
      'post',
      successResponse({})
    )
    // 收藏夹可下载分类
    Mock.mock(
      RegExp(Api.bookmark.getAllForDownload + '(\\?.*)*$'),
      'get',
      (options) => {
        const query = getQueryVariableObj(options.url)
        return successResponse(getBookmarkDownloadPhotoCategoryData())
      }
    )
    // 收藏夹打包下载地址
    Mock.mock(
      RegExp(Api.bookmark.getDownloadUrl + '(\\?.*)*$'),
      'post',
      successResponse(BookmarkDownloadUrlData)
    )
    // 收藏夹单个下载地址
    Mock.mock(
      RegExp(Api.bookmarkPhoto.getDownloadUrl + '(\\?.*)*$'),
      'post',
      successResponse(BookmarkDownloadUrlData)
    )
    /* ---------------- 收藏夹 end----------------*/
  },
}
