import AboutPage from 'pages/About/Containers/Index'
import ContactPage from 'pages/Contact/Containers/Index'
import WebIndexPage from 'pages/WebIndex/Containers/Index'
import WorksPage from 'pages/Works/Containers/Index'
import PhotoProjectsPage from 'pages/PhotoProjectsPage/Containers/Index'
// import { getAccessSuffix } from 'utils/Tools'

// const accessSuffix = '/' + getAccessSuffix()

export const PAGE_PATH = {
  // index: accessSuffix,
  home: '/',
  customerVisit: '/collection/:id',
  photoProject: '/collection',
  projectDetail: '/c/:id',
  favorite:  '/favorite/:id',
  sharePhotos:  '/share/:id',
  // shareFavorite:  '/shareFavorite/:id',
  about:  '/about',
  contact: '/contact',
  webIndex: '/webIndex',
  works: '/portflio',
  download: '/download/:id/:type',
  wxcode:'/wxcode',
}

const Router = [
  {
    path: PAGE_PATH.webIndex,
    component: WebIndexPage,
    name: '首页',
    key: 'INDEX',
  },
  {
    path: PAGE_PATH.works,
    component: WorksPage,
    name: '作品',
    key: 'WORKS',
  },
  {
    path: PAGE_PATH.photoProject,
    component: PhotoProjectsPage,
    name: '项目',
    key: 'CUSTOMER_PHOTO',
  },
  {
    path: PAGE_PATH.about,
    component: AboutPage,
    name: '关于',
    key: 'ABOUT',
  },
  {
    path: PAGE_PATH.contact,
    component: ContactPage,
    name: '联系',
    key: 'CONTACT',
  },
]

export default Router
