import Mock from 'mockjs'
import { ceil } from 'lodash/fp'

const { Random } = Mock

export const successResponse = (data = null) => ({
  code: 0,
  msg: '成功',
  ts: new Date().valueOf(),
  data: data,
})

export const errorResponse = (data = null) => ({
  code: 404,
  msg: '服务器异常',
  ts: new Date().valueOf(),
  data: data,
})

export const ListPaginationDataResponse = (pagination, data = null) => {
  const { pageNo = 1, pageSize = 10 } = pagination

  return {
    code: 0,
    msg: '成功',
    ts: new Date().valueOf(),
    data: {
      pageNo: pageNo + '', // 当前页码
      pageSize: pageSize + '', // 分页大小
      totalPage: ceil(100 / pageSize), // 总页数
      totalCount: 100 + '', // 总的记录数
      list: data, // 分页列表
      lastPage: 0 + '', // 最后页页码
      hasPreviousPage: 'Y', // 是否有上一页
      hasNextPage: 'Y', // 是否有下一页
      previousPage: 0 + '', // 上一页页码
      nextPage: 0 + '', // 下一页页码
    },
  }
}

export const qiniuTokenData = {
  code: 0,
  data: {
    token:
      '6B-M91_38jWV31g2SPyBeQMDadl7TlVgV8Xe0FPQ:_e-2qbrrq1g_O65_If7olK29tFo=:eyJzY29wZSI6InBob3RvLWRhaWx5IiwiZGVhZGxpbmUiOjE1OTUyMzU0MjF9',
    urlPrefix: 'http://photo.infotos.cn/',
  },
  msg: 'success',
  ts: '1595231821389',
}
