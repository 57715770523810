import { Col, Layout, Row, Spin } from 'antd'
import download from 'downloadjs'
import Masonry from 'masonry-layout'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import InfiniteScroll from 'react-infinite-scroller'
import InfListFooterText from '../InfListFooterText'
import InfoEmptyData from '../InfoEmptyData/index'
import PreviewCarouselModal from '../PreviewCarouselModal/index'

const { Content, Footer } = Layout

export default class MasonryImgs extends React.Component {
  state = {
    imgsData: [],
    hasMore: true,
    idOfShowOpts: '',
    previewCarouselModalVisible: false, // 查看图片弹窗
    previewList: [], // 查看弹窗图片list
    previewCurrent: 0, // 查看弹窗当前siderNumber
    pagination: {
      current: 1,
    }, // 分页数据
    loading: false,
  }

  componentDidMount() {
    this.getPhotos({})
  }

  getPhotos = async ({ pagination, ...rest }) => {
    const { getList, exHeader } = this.props
    const { imgsData } = this.state
    this.setState({ loading: true, hasMore: false })
    const res = await getList({
      ...pagination,
      ...rest,
    })
    this.setState({ loading: false })
    if ([0].includes(res.code)) {
      const { pagination, list } = res.data
      const hasMore =
        pagination.current * pagination.pageSize < pagination.total
      const newList = pagination.current === 1 ? list : imgsData.concat(list)

      this.setState({ imgsData: newList, hasMore, pagination })
    }
  }

  loadData = (page = 1) => {
    const { hasMore } = this.state
    if (hasMore) {
      this.getPhotos({
        pagination: { pageNo: page },
      })
    }
  }
  onLoadImg = () => {
    // nowNum++
    // if (nowNum === count) {
    //   // 如果所有图片加载完，调用实现瀑布流代码
    // }
    var elem = document.querySelector('.grid')
    var msnry = new Masonry(elem, {
      // options
      itemSelector: '.grid-item',
    })
    var msnry = new Masonry('.grid', {})
  }

  showOpt = (img) => {
    this.setState({ idOfShowOpts: img.id })
  }
  hideOpt = () => {
    this.setState({ idOfShowOpts: '' })
  }
  downloadImg = (img) => {
    download(img.thumbInfo['large'].photoUrl)
  }
  // 显示查看弹窗
  showPreviewCarouselModal = async (id) => {
    const { imgsData } = this.state
    await this.setState({
      previewCurrent: imgsData.findIndex((item) => [id].includes(item.id)),
    })
    await this.setState({
      previewCarouselModalVisible: true,
    })
  }
  // 隐藏查看弹窗
  hidePreviewCarouselModal = (newList) => {
    this.setState({
      previewCarouselModalVisible: false,
      previewCurrent: 0,
      imgsData: newList,
    })
  }
  setPreviewCurrent = async (index) => {
    await this.setState({
      previewCurrent: index,
    })
  }
  loadNext = async () => {
    const { categoryId, getList, exHeader } = this.props
    const { pagination, imgsData } = this.state
    this.setState({ loading: true })
    const res = await getList({
      ...pagination,
      pageNo: Number(pagination.current) + 1,
      categoryId: categoryId,
    })
    this.setState({ loading: false })
    let newList = []
    if ([0].includes(res.code)) {
      const { pagination, list } = res.data
      const hasMore =
        pagination.current * pagination.pageSize < pagination.total
      newList = list
      this.setState({ hasMore, pagination })
    }
    return newList
  }
  render() {
    const {
      imgsData,
      hasMore,
      previewCarouselModalVisible,
      previewCurrent,
      pagination,
      loading,
    } = this.state
    const { scrollParentRef, isUseWindowScroll = false } = this.props

    return !hasMore && !imgsData.length ? (
      <div style={{ marginTop: 120 }}>
        <Spin spinning={loading} tip={<FormattedMessage id="getData" />} style={{ width: "100%" }} />
        {!loading && <InfoEmptyData description={<FormattedMessage id="categoryNoData" />} />}
      </div>
    ) : (
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        hasMore={hasMore}
        loadMore={this.loadData}
        useWindow={!isUseWindowScroll}
        getScrollParent={() => scrollParentRef.current}
      >
        <Row className="grid fixAntdAndMosonryGrid" gutter={[10, 10]}>
          {imgsData.map((item, key) => {
            return (
              <Col
                className="grid-item"
                key={key}
                onMouseEnter={this.showOpt.bind(this, item)}
                onMouseLeave={this.hideOpt}
                onClick={() => this.showPreviewCarouselModal(item.id)}
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={6}
              >
                <img
                  className="masonry-img"
                  src={item.thumbInfo['general'].photoUrl}
                  key={item.thumbInfo['general'].photoKey}
                  onLoad={(e) => {
                    e.stopPropagation()
                    this.onLoadImg()
                  }}
                />
              </Col>
            )
          })}
          <Spin spinning={loading} tip={<FormattedMessage id="getData" />} style={{ width: "100%" }} />
          {/* 首页与作品页共用显示加载数量提醒 */}
          {/* <InfListFooterText hasMore={hasMore} pagination={pagination} /> */}
        </Row>
        <PreviewCarouselModal
          visible={previewCarouselModalVisible}
          onCancel={this.hidePreviewCarouselModal}
          list={imgsData}
          key={imgsData}
          previewCurrent={previewCurrent}
          downloadImg={this.downloadImg}
          setHide={this.setHide}
          menuFeild={this.menuFeild}
          pagination={pagination}
          hasMore={hasMore}
          setPreviewCurrent={this.setPreviewCurrent}
          loadData={this.loadNext}
        />
      </InfiniteScroll>
    )
  }
}
