import React, { useState, useEffect, useRef } from 'react'

import { useSelector } from 'react-redux'
import { isEmpty, flow, first, prop } from 'lodash/fp'
import { useLocation, useHistory } from 'react-router-dom'

import Router, { PAGE_PATH } from 'apis/Router.js'

// request
import { useIntl } from 'react-intl';

import { getAllShowcaseCategory as _getAllShowcaseCategory } from 'reduxModules/showcasePhoto'
import { getProjectCategorys as _getProjectCategorys } from 'reduxModules/customerPhoto'

// 上下排列
import TopDownLayout from '../Components/TopDownLayout'
// 左右排列
import LeftRightLayout from '../Components/LeftRightLayout'

import './index.less'
import commonComponents from 'components/index'
const { InfErrorPage } = commonComponents
const Dashboard = (props) => {
  let history = useHistory()
  let location = useLocation()

  // 作品分类
  const [showcaseCategories, setShowcaseCategories] = useState([])
  // 客片项目
  const [customerProjects, setCustomerProjects] = useState([])

  // 被选中的 作品 CategoryId 或者 客片项目Id
  const [selectedId, setSelectedId] = useState('')
  const divRef = useRef()
  const commonSetting = useSelector((state) => state.commonSetting.data)
  const showcaseSettingState = useSelector(
    (state) => state.showcaseSetting.data
  )
  const intl = useIntl();
  useEffect(() => {
    getWorkCategories() // 获取作品分类
    getCustomerProjects() // 获取客片分类
  }, [])
  useEffect(() => {
    const { defaultIndexType, menus } = showcaseSetting
    const defaultIndex = menus.find(({ key }) =>
      [defaultIndexType].includes(key)
    )
    // 当pathname是/的时候 或者是 为 端口加后缀 需要判断一下默认首页的路由，然后跳转去默认首页
    if (
      defaultIndex &&
      (/\/$/.test(location.pathname) ||
        location.pathname.split('/').length < 3) &&
      defaultIndex.path !== location.pathname
    ) {
      if (defaultIndexType === 'WORKS') {
        const firstId = isEmpty(showcaseCategories)
          ? ''
          : flow(first, prop('id'))(showcaseCategories)
        setSelectedId(firstId + '')
      }

      history.push(defaultIndex.path)
    }
  }, [showcaseSettingState])

  //  获取菜单项
  const getMenuItem = (menuName, key) => {
    const routerItem = Router.find((i) => [key].includes(i.key))
    const routerObj = isEmpty(routerItem) ? {} : routerItem
    return {
      ...routerObj,
      menuName,
    }
  }

  const getMenus = (menuSetting) => {
    if (isEmpty(menuSetting)) return []
    const {
      defaultIndexType,
      isShowIndex,
      indexName,
      isShowWorks,
      worksName,
      isShowCustomerPhoto,
      customerPhotoName,
      isShowAbout,
      aboutName,
      isShowContact,
      contactName,
    } = menuSetting
    let menus = []

    if (isEmpty(defaultIndexType)) {
      return []
    }
    // 如果展示作品的话 拉取分类数据
    if (isShowWorks) {
    }
    const navSettings = []
    navSettings[0] = {
      isShow: isShowIndex,
      name: indexName,
      key: 'INDEX',
    }
    navSettings[1] = {
      isShow: isShowWorks,
      name: worksName,
      key: 'WORKS',
    }
    navSettings[2] = {
      isShow: isShowCustomerPhoto,
      name: customerPhotoName,
      key: 'CUSTOMER_PHOTO',
    }
    navSettings[3] = {
      isShow: isShowAbout,
      name: aboutName,
      key: 'ABOUT',
    }
    navSettings[4] = {
      isShow: isShowContact,
      name: contactName,
      key: 'CONTACT',
    }

    const naves = navSettings.filter(({ isShow }) => isShow)
    naves.forEach((i) => {
      const menu = getMenuItem(i.name, i.key)
      menus.push({
        ...i,
        ...menu,
      })
    })

    return menus
  }

  // 获取作品分类
  const getWorkCategories = async () => {
    const res = await _getAllShowcaseCategory({
      showcaseType: 'WORKS',
    })
    if ([0].includes(res.code) && res.data) {
      const { formattedData = [] } = res.data
      const worksCategories = formattedData.list || []

      const firstId = isEmpty(worksCategories)
        ? ''
        : flow(first, prop('id'))(worksCategories)
      setShowcaseCategories(worksCategories)
      const isWorks = [PAGE_PATH.works].includes(location.pathname)
      if (isWorks) {
        setSelectedId(firstId + '')
      }
    }
  }
  // 获取客片项目
  const getCustomerProjects = async () => {
    const res = await _getProjectCategorys()
    if ([0].includes(res.code) && res.data) {
      const customerProjects = res.data.map(item => {
        if (item.name === '全部') {
          return {
            ...item,
            name: intl.formatMessage({ id: 'projectAll' })
          }
        }
        return item;
      });
      setCustomerProjects(customerProjects)
    }
  }

  const showcaseSetting = {
    ...showcaseSettingState,
    menus: getMenus(showcaseSettingState),
  }

  return (
    <>
      {/* 左右排列 */}
      {!isEmpty(commonSetting) &&
        !isEmpty(showcaseSetting.defaultIndexType) &&
        ['LEFT_RIGHT'].includes(commonSetting.websiteLayoutType) && (
          <LeftRightLayout
            commonSetting={commonSetting}
            showcaseSetting={showcaseSetting}
            photoCategories={showcaseCategories}
            customerProjects={customerProjects}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            divRef={divRef}
          />
        )}
      {/* 上下排列 */}
      {!isEmpty(commonSetting) &&
        !isEmpty(showcaseSetting.defaultIndexType) &&
        ['TOP_DOWN'].includes(commonSetting.websiteLayoutType) && (
          <TopDownLayout
            commonSetting={commonSetting}
            showcaseSetting={showcaseSetting}
            photoCategories={showcaseCategories}
            customerProjects={customerProjects}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
          />
        )}
      {!isEmpty(commonSetting) && isEmpty(showcaseSetting.defaultIndexType) && (
        <InfErrorPage
          imgSrc={commonSetting.brandLogoPhotoInfo.photoUrl}
          cover={true}
          remindTitle={intl.formatMessage({ id: 'maintenance' })}
          remindText={intl.formatMessage({ id: 'contactServe' }) + commonSetting.name}
        />
      )}
    </>
  )
}

export default Dashboard
