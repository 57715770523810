/**
 * tabs颜色
 *
 * #607AFB  - 紫蓝
 * #FFD001  - 蛋黄
 * #15C8AE  - 深绿
 * #F73B42  - 蜜桃红
 * #29A7F6  - 蓝色
 * #677CAB  - 墨灰
 * #FD8D02  - 橙黄
 *
 */

import { first, flow, last, map, split } from 'lodash/fp'

const mapWithIndex = map.convert({ cap: false })

//  流程状态
const FlowStatusOptions = [
  {
    key: 0,
    label: '未提交',
    color: '#677CAB',
  },
  {
    key: 1,
    label: '已提交',
    color: '#15C8AE',
  },
  {
    key: 2,
    label: '未通过',
    color: '#F73B42',
  },
]

/* --------------------------------*/

const WebLayout = [
  {
    key: 1,
    label: '左右排列形式',
    value: 'LEFT_RIGHT',
  },
  {
    key: 2,
    label: '上下排列形式',
    value: 'TOP_DOWN',
  },
]
const getOptionsByEnums = (Enums) =>
  mapWithIndex((i, index) => ({
    key: flow(split(':'), first)(i),
    value: flow(split(':'), first)(i),
    label: flow(split(':'), last)(i),
  }))(Enums)
/* ---------------- 侧边栏外观 start----------------*/
const SidebarAppearance = [
  {
    key: 1,
    label: '深色',
    value: 'DEEP_COLOUR',
  },
  {
    key: 2,
    label: '浅色',
    value: 'LIGHT_COLOUR',
  },
]
/* ---------------- 侧边栏外观 end----------------*/

/* ---------------- 服务分类 start----------------*/

export const CategoryCodeEnums = [
  'CHILDREN:儿童摄影',
  'WEDDING:婚礼摄影',
  'DRESS:婚纱摄影',
  'FAMILY:家庭摄影',
  'PORTRAIT:人像摄影',
  'FASHION:时尚摄影',
  'BUSINESS:商业摄影',
  'FOOD:美食摄影',
  'NEWS:新闻纪实摄影',
  'SCENERY:景观摄影',
  'SPORTS:体育活动摄影',
  'OTHER:其他',
]

const CategoryCodes = getOptionsByEnums(CategoryCodeEnums)

/* ---------------- 服务分类 end----------------*/

/* ---------------- 订阅版本 start----------------*/

export const EditionTypeEnums = [
  'FREE:免费版',
  'BASIC:基础版',
  'NORMAL:标准版',
  'PRO:专业版',
  'BUSINESS:商业版',
  'PREMIUM:高级版',
]

const EditionTypes = getOptionsByEnums(EditionTypeEnums)

/* ---------------- 订阅版本 end----------------*/

/* ---------------- 是否 start----------------*/
const YesOrNo = ['Y:是', 'N:否']
const YesOrNoOptions = getOptionsByEnums(YesOrNo)
const YesOrNoTypes = [
  {
    key: true,
    label: '是',
    value: 'Y',
  },
  {
    key: false,
    label: '否',
    value: 'N',
  },
]
/* ---------------- 是否 end----------------*/

/* ------- 消息类型 start------- */
const MessageTypeEnums = [
  'BOOKMARK:选片收藏',
  'DOWNLOAD:下载',
  'REGISTER:注册',
  'ORDER:订单',
  'OTHER:其它',
]
const MessageTypes = getOptionsByEnums(MessageTypeEnums)
/* ------- 消息类型 end------- */

// 项目状态
const ProjectStatus = [
  {
    key: 'HIDDEN',
    label: '隐藏',
  },
  {
    key: 'ONLINE',
    label: '上线',
  },
]
/* ------- 对齐方式 start------- */

const AlignTypeEnums = ['LEFT:居左', 'CENTER:居中', 'RIGHT:居右']
const AlignTypes = getOptionsByEnums(AlignTypeEnums)

/* ------- 对齐方式 end------- */
/* ------- 默认首页类型 start------- */
const DefaultIndexTypeEnums = [
  'INDEX:首页',
  'WORKS:作品',
  'CUSTOMER_PHOTO:客片',
  'ABOUT:关于',
  'CONTACT:联系',
]
const DefaultIndexTypes = getOptionsByEnums(DefaultIndexTypeEnums)
/* ------- 默认首页类型 end------- */

/* ------- 展示类型 start------- */
const ShowcaseTypeEnums = ['INDEX:首页', 'WORKS:作品']
const ShowcaseTypes = getOptionsByEnums(ShowcaseTypeEnums)
/* ------- 展示类型 end------- */
/* ------- 图片分类 start------- */
const ImgCategoryEnums = [
  'INDEX:首页',
  'SHOWCASE:展示',
  'CUSTOMER:客片',
  'BOOKMARK:收藏夹',
]
const ImgCategory = getOptionsByEnums(ImgCategoryEnums)
/* ------- 图片分类 end------- */
/* ------- 目标分类类型 start------- */
const categoryTypeEnums = [
  'INDEX:首页',
  'SHOWCASE:展示',
  'CUSTOMER:客片',
  'BOOKMARK:收藏夹',
]
const categoryTypes = getOptionsByEnums(categoryTypeEnums)
/* ------- 目标分类类型 end------- */
/* ---------------- 暂存类型 start----------------*/
const StorageTypeEnums = ['ALBUM:相册', 'OTHER:其他']
const StorageTypes = getOptionsByEnums(StorageTypeEnums)

/* ---------------- 暂存类型 end----------------*/
// 访问类型
const VisitTypes = [
  {
    key: 'OPEN',
    label: '公开访问',
  },
  {
    key: 'PASSWORD',
    label: '密码访问',
  },
]

// 下载图片类型
const DownloadImgSize = [
  {
    key: 'LOW',
    label: '小尺寸（最长边600）',
  },
  {
    key: 'MEDIUM',
    label: '社交尺寸（最长边1000）',
  },
  {
    key: 'HIGH',
    label: '大尺寸（最长边2000）',
  },
  {
    key: 'ORIGINAL',
    label: '源文件',
  },
]

// Dashboard类型
const DashboardDataType = [
  {
    key: 'PAST_YEAR',
    label: '过去一年',
  },
  {
    key: 'PAST_HALF_YEAR',
    label: '过去半年',
  },
  {
    key: 'PAST_MONTH',
    label: '30天',
  },
  {
    key: 'PAST_WEEK',
    label: '7天',
  },
]

/* ---------------- 预约知道方式 start----------------*/
const HowToFindEnums = [
  'FRIEND:朋友介绍',
  'WEBSITE:网站',
  'WECHAT:公众号',
  'MEDIA:媒体',
  'QUERY:查询',
  'OTHER:其他',
]

const HowToFindTypes = getOptionsByEnums(HowToFindEnums)

/* ---------------- 预约知道方式 end----------------*/

export {
  AlignTypes,
  CategoryCodes,
  categoryTypes,
  DefaultIndexTypes,
  DownloadImgSize,
  DashboardDataType,
  EditionTypes,
  HowToFindTypes,
  ImgCategory,
  MessageTypes,
  ProjectStatus,
  SidebarAppearance,
  ShowcaseTypes,
  StorageTypes,
  VisitTypes,
  WebLayout,
  YesOrNoOptions,
  YesOrNoTypes,
}
