import { ImgCategory, YesOrNoOptions } from '../../Constants/Options'
import { map } from 'lodash/fp'
import Mock from 'mockjs'
const ImgCategoryValues = map(({ value }) => value)(ImgCategory)
const YesOrNoOptionsValues = map(({ value }) => value)(YesOrNoOptions)
const { Random } = Mock

export const getShowcaseCategoryData = ({ showcaseType = undefined }) =>
  Array.from({ length: Random.natural(1, 10) }, (v, i) => ({
    id: i + 1, // id
    showcaseType,
    name: Random.cword(5) + '分类', // 图片分类名称
    sort: i + 1,
  }))

// 分页查询展示图片
export const getShowcasePhotoList = ({
  pageNo = 1,
  pageSize = 50,
  categoryId = undefined,
  showcaseType = undefined,
  isShow = undefined,
}) => {
  if (['WORKS'].includes(showcaseType) && !categoryId) {
    return []
  }
  return Array.from({ length: pageSize }, (v, i) => {
    const imgWidth = Random.natural(300, 720)
    const imgHeight = Random.natural(300, 720)
    return {
      id: Random.id(), // id
      showcaseCategoryId: categoryId, // 所属展示图片分类id
      photoName: Random.name(), // 图片名称
      photoInfo: {
        // 图片信息
        photoKey: 'string', // 图片Key
        photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()), // 图片完整Url
        // photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),

        photoSize: Random.natural(1, 500), // 图片大小(kb)
        photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)], // 图片格式
        photoWidth: imgWidth, // 图片宽度(px)
        photoHeight: imgHeight, // 图片高度(px)
      },
      showcaseType: showcaseType, // 展示类型 INDEX:首页, WORKS:作品
      isSales: YesOrNoOptionsValues[Random.natural(0, 1)], // 是否销售 Y:是, N:否
      isShow: YesOrNoOptionsValues[Random.natural(0, 1)], // 是否销售 Y:是, N:否, // 是否展示 Y:是, N:否
      description: Random.cword(20), // 描述
      sort: 10 * (Number(pageNo) - 1) + i + 1, // 排序
    }
  })
}
