// 手机号码正则
export const phonePattern = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/

// 密码正则 规则 6位以上的数字与字母组合

export const pswPattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/

// 邮箱验证
// export const emailPattern = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/
export const emailPattern = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/

// 网址验证
export const UrlPattern = /^(?=^.{3,255}$)(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*$/

// 微信/微信公众号验证
export const WxPattern = /^[a-zA-Z]{1}[-_a-zA-Z0-9]{5,19}$/

// 电话验证：中国手机、座机、香港、台湾、日本、韩国
export const phone = /(^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$)|(^(0\d{2,3})-?(\d{7,8}))$|(^(852|886|853)(-|\?)([6|9])\d{7}$|^[0][9]\d{8}$|^[6]([8|6])\d{5}$)|(^(81)?\-?0?[789](?:\d{8}|\d{9})$)|(^(00)?82\-?0[71](?:\d{8,9})$)/

// export const RequiredValidateRule = {
//   required: true,
//   message: '必填项',
// }
export const RequiredInputRule = (intl) => ({
  required: true,
  message: intl.formatMessage({ id: 'required' }),
})

// export const RequiredSelectRule = {
//   required: true,
//   message: '请选择',
// }

export const PhoneValidateRule = (intl) => ({
  pattern: phonePattern,
  message: intl.formatMessage({ id: 'inputPhone' }),
})

export const PhoneRule = {
  pattern: phone,
  message: '请输入正确的电话号码',
}
// export const PswValidateRule = {
//   pattern: pswPattern,
//   message: '6位以上的数字与字母组合',
// }

export const EmailValidateRule = {
  pattern: emailPattern,
  message: '请输入正确的邮箱！',
}

// export const UrlValidateRule = {
//   pattern: UrlPattern,
//   message: '请输入正确的网址！',
// }

// export const WxValidateRule = {
//   pattern: WxPattern,
//   message: '请输入正确的微信/微信公众号！',
// }

export const LoginAccountValidateRule = (intl) => ({
  // pattern: /(^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$)|(^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$)|(^[0-9]*[1-9][0-9]*$)/,
  pattern: /(^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$)|(^(0\d{2,3})-?(\d{7,8}))$|(^(852|886|853)(-|\?)([6|9])\d{7}$|^[0][9]\d{8}$|^[6]([8|6])\d{5}$)|(^(81)?\-?0?[789](?:\d{8}|\d{9})$)|(^(00)?82\-?0[71](?:\d{8,9})$)|(^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$)|(^[0-9]*[1-9][0-9]*$)/,
  message: intl.formatMessage({ id: 'validatePhoneOrEmail' }),
})
