// Servers
// Selectors
import { CommonSettingInfoSelector } from 'selectors/commonSettingSelectors'
import { _getCommonSetting, _getWebsiteSetting,_getEnterpriseLevel } from 'servers/commonSetting'

// Actions
const GET_COMMON_SETTING_INFO = 'GET_COMMON_SETTING_INFO'
const GET_COMMON_SETTING_INFO_SUCCESS = 'GET_COMMON_SETTING_INFO_SUCCESS'
const GET_COMMON_SETTING_INFO_FAIL = 'GET_COMMON_SETTING_INFO_FAIL'

// Action Creators

export const getCommonSettingInfoAction = () => ({
  type: GET_COMMON_SETTING_INFO,
})

export const getCommonSettingInfoSuccessAction = (data) => ({
  type: GET_COMMON_SETTING_INFO_SUCCESS,
  data: data,
})
export const getCommonSettingInfoFailAction = (data) => ({
  type: GET_COMMON_SETTING_INFO_FAIL,
  data,
})

// init data

const initialState = {
  data: {},
  errorMessage: null,
  fetching: false,
  code: 0,
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_COMMON_SETTING_INFO:
      return {
        ...state,
        fetching: true,
      }
    case GET_COMMON_SETTING_INFO_SUCCESS:
      return {
        ...state,
        data: action.data,
        fetching: false,
        code: action.data.code,
      }
    case GET_COMMON_SETTING_INFO_FAIL:
      return {
        ...state,
        fetching: false,
        errorMessage: action.data.msg,
        code: action.data.code,
      }
    default:
      return state
  }
}

//异步action
export const getWebsiteSetting = (params) => _getWebsiteSetting(params);

export const getCommonSetting = async (params) => {
  return async (dispatch) => {
    dispatch(getCommonSettingInfoAction())
    const res = await _getCommonSetting(params)
    if ([0].includes(res.data.code)) {
      dispatch(
        getCommonSettingInfoSuccessAction(
          CommonSettingInfoSelector(res.data.data)
        )
      )
      // 修改网站fav.icon
      const icon_url = res.data.data.websiteIconPhotoInfo.photoUrl
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = icon_url

      // 苹果touchIcon------
      var touchIcon57 = document.createElement('link')
      touchIcon57.rel = 'apple-touch-icon'
      touchIcon57.sizes = '57x57'
      touchIcon57.href = icon_url

      var touchIcon152 = document.createElement('link')
      touchIcon152.rel = 'apple-touch-icon'
      touchIcon152.sizes = '152x152'
      touchIcon152.href = icon_url
      // 苹果touchIcon-------end

      // 修改网站关键词和描述
      const websiteKeywords = res.data.data.websiteKeywords
      const description = res.data.data.websiteDesc
      const descriptionObj = document.getElementsByTagName('meta')[3]
      let wordMeta = document.createElement('meta')
      wordMeta.name = 'keywords'
      wordMeta.content = websiteKeywords
      document.getElementsByTagName('head')[0].appendChild(wordMeta)
      document.getElementsByTagName('head')[0].appendChild(link)
      document.getElementsByTagName('head')[0].appendChild(touchIcon57)
      document.getElementsByTagName('head')[0].appendChild(touchIcon152)

      descriptionObj.content = description
      document.title = res.data.data.websiteTitle
    } else {
      dispatch(getCommonSettingInfoFailAction(res.data))
    }
  }
}

export const getEnterpriseLevel = (params) => _getEnterpriseLevel(params);
