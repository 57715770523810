import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Layout, Modal, Input, Button, Form } from 'antd'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { isEmpty } from 'lodash/fp'
import {
  getAuthorization as _getAuthorization,
  getWechat as _getWechat
} from 'reduxModules/customerPhoto'

import styles from './index.module.less'
import { getToken } from 'utils/Request'
import { PAGE_PATH } from 'apis/Router'
import { getFullRoute } from 'utils/Tools'


const WxCodePage = (props) => {
  let history = useHistory()
  const [wxCode, setWxCode] = useState('')
  useEffect(() => {
    getProjectsCode()
  }, [])
  //访客模式获取二维码
  const getProjectsCode = async () => {
    let url = window.location.href.split('?')[1]; //获取url中"?"符后的字串
    let theRequest = new Object();
      let str = url.substr(1);
      let strs = str.split("&");
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
      }
    // console.log(window.location.href.split('?')[0])
    //非微信跳转获取二维码否则跳转项目
    if(!!theRequest.ollectionUrl) {
      let collectionUrl = window.location.href.split('=')[1]
      localStorage.setItem('collectionUrl', collectionUrl)
      // return
      // scope: 'snsapi_userinfo',
      const res = await _getAuthorization({
        params: {
          redirectUri: 'https://wxauth.infotos.co/wxcode',
          scope: 'snsapi_login',
          state: 'infotos'
        },
      })
      if (res.data.code == 0) {
        setWxCode(res.data.data)
      }
    }else{
      //微信扫码返回结果操作 redirect_uri?code=CODE&state=STATE
      // 用户允许授权后，将会重定向到redirect_uri的网址上，并且带上code和state参数
      if(theRequest.state=='infotos'){
        window.location.href=localStorage.getItem('collectionUrl')
        localStorage.setItem('code', theRequest.code)
      }
    }
  }

  return (
    <Layout
      className="Inf_page"
      style={{
        background: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:'center'
      }}
    >
      <div className={styles.packDownloadPage}>
        <p className={styles.down_title}>收藏</p>
        <p className={styles.down_desc}>小标题</p>
        <div className={styles.wxCode}>
          <img src={wxCode}/>
        </div>
        <p className={styles.wxDec}>使用微信扫码登录，或<a style={{color:'#066DD5'}}>通过Email/手机号登录</a></p>
      </div>
    </Layout>
  )
}

export default WxCodePage
