import Mock from 'mockjs'
const { Random } = Mock

export const getProjectCategorys = () => {
  return Array.from({ length: Random.natural(1, 10) }, (v, i) => {
    return {
      id: 'pro_' + Random.guid(),
      name: Random.cword(2) + '项目',
      isDefault: ['Y', 'N'][Random.natural(0, 1)],
      sort: i,
    }
  })
}

export const getProjectsByCategory = (query) => {
  const categoryId = query.categoryId
  return Array.from({ length: Random.natural(5, 20) }, (v, i) => {
    const imgWidth = Random.natural(50, 960)
    const imgHeight = Random.natural(50, 960)
    return {
      id: categoryId + '_' + i,
      name: Random.cword(2) + '项目',
      shootingDay: Random.date('2020-MM-dd'),
      status: ['HIDDEN', 'ONLINE'][Random.natural(0, 1)],
      customerPhotoCount: Random.natural(10, 50),
      visitType: ['OPEN', 'PASSWORD'][Random.natural(0, 1)],
      isAllowVisitor: ['Y', 'N'][Random.natural(0, 1)],
      allowVisitorCustomerPhotoCategoryId: Random.natural(1, 10),
      coverPhotoIngo: {
        photoName: Random.word(2),
        photoKey: Random.guid(),
        photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),
        photoSize: Random.natural(50, 150),
        photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)],
        photoWidth: imgWidth,
        photoHeight: imgHeight,
      },
    }
  })
}

export const getProjectById = (query) => {
  const imgWidth = Random.natural(300, 960)
  const imgHeight = Random.natural(300, 960)
  return {
    id: query.id,
    coverPhotoInfo: {
      photoKey: Random.guid(),
      photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),
      photoSize: Random.natural(50, 150),
      photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)],
      photoWidth: imgWidth,
      photoHeight: imgHeight,
    },
    projectCategoryId: Random.natural(1, 10),
    name: Random.cword(2, 5) + '项目',
    shootingDay: Random.date('2019-MM-dd'),
    loseDay: Random.date('2020-MM-dd'),
    projectUrlPrefix: Random.word(), // 项目地址前缀
    projectUri: Random.word(3, 8), // 项目地址uri
    projectFullUrl: Random.url('https', 'infotos.co'), //项目完整地址
    status: ['HIDDEN', 'ONLINE'][Random.natural(0, 1)], //项目状态 HIDDEN:隐藏, ONLINE:上线
    isGuestPieceShow: ['Y', 'N'][Random.natural(0, 1)], //客片栏目是否显示该影集 Y:是, N:否
    isSetPhotoHidden: ['Y', 'N'][Random.natural(0, 1)], //是否允许客户设置图片隐藏 Y:是, N:否
    visitType: ['OPEN', 'PASSWORD'][Random.natural(0, 1)], //访问类型 OPEN:公开访问, PASSWORD:密码访问
    visitPassword: Random.word(6, 8),
    isAllowVisitor: ['Y', 'N'][Random.natural(0, 1)], //是否允许访客访问
    allowVisitorPhotoCategoryId: Random.natural(1, 10), //允许访客访问的图片分类id， 为null则全部可以访问
    isAllowVisitorMeetingConditions: ['Y', 'N'][Random.natural(0, 1)], //访客访问需要通过关注微信访问（或通过输入手机/Email）Y:是, N:否
    canDownload: ['Y', 'N'][Random.natural(0, 1)], //是否允许下载图片 Y:是, N:否
    downloadSize: ['ORIGINAL', 'HIGH', 'MEDIUM', 'LOW'][Random.natural(0, 3)], //	下载尺寸类型 Y:是, N:否
    isDownloadCode: ['Y', 'N'][Random.natural(0, 1)], //下载需要验证码
    downloadCode: Random.string(6, 10), // 下载验证码
    isShowBookmark: ['Y', 'N'][Random.natural(0, 1)], //是否显示收藏夹及相关功能
    isMessage: ['Y', 'N'][Random.natural(0, 1)], //是否允许客户留言
    isAllowShare: ['Y', 'N'][Random.natural(0, 1)], //是否允许客户通过社交账号共享
    customerName: Random.cname(), //客户名称
    customerPhone: Random.phone(), //客户联系电话
    customerWechat: 'wx_' + Random.string(6, 10), //客户微信号
    customerEmail: Random.email(), //客户Email
    customerCountryGeoId: Random.natural(1, 20), // 客户收货地址 国家id
    customerProvinceGeoId: Random.natural(1, 20), // 客户收货地址 省id
    customerCityGeoId: Random.natural(1, 20), // 客户收货地址 市id
    customer_area_geo_id: Random.natural(1, 20), // 客户收货地址 区id
    customerAddress: Random.ctitle(), //客户收货地址 详细街道地址
    customerFullAddress: Random.ctitle(), //客户收货地址 全地址
    customerRemark: Random.ctitle(), //客户收货地址 客户备注
  }
}

export const getProjectInfoForLogin = (query) => {
  const imgWidth = Random.natural(300, 960)
  const imgHeight = Random.natural(300, 960)
  return {
    id: query.id,
    coverPhotoInfo: {
      photoKey: Random.guid(),
      photoUrl: [
        // 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // 'http://photo.infotos.cn/logo4.jfif1594463001',
        // 'http://photo.infotos.cn/logo3.jfif1594463406',
      ][Random.natural(0, 3)],
      photoSize: Random.natural(50, 150),
      photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)],
      photoWidth: imgWidth,
      photoHeight: imgHeight,
    },
    projectCategoryId: Random.natural(1, 10), // 所属图片项目分类id
    name: Random.cword(2, 5) + '项目', // 项目名称
    shootingDay: Random.date('2019-MM-dd'), // 拍摄日期时间
    loseDay: Random.date('2020-MM-dd'), // 失效日期
    uri: Random.word(3, 8), // 项目地址uri
    visitType: ['OPEN', 'PASSWORD'][Random.natural(0, 1)], //访问类型 OPEN:公开访问, PASSWORD:密码访问
    customerName: Random.cname(), //客户名称
    customerPhone: Random.phone(), //客户联系电话
    customerLogoPhotoInfo: {
      photoKey: Random.guid(),
      photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),
      photoSize: Random.natural(50, 150),
      photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)],
      photoWidth: imgWidth,
      photoHeight: imgHeight,
    },
  }
}
