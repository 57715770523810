import React from 'react'
import { Modal } from 'antd'
import { getSession,getLocal } from 'utils/Tools'
import { isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl';
import { PAGE_PATH } from 'apis/Router'
import styles from './aboutModal.module.less'
import {useSelector} from "react-redux";
import Imgs from 'imgs'

export default class AboutModal extends React.Component {
  state = {}
  componentDidMount() {}
  render() {
    const { visible, handleCancel, cameraman, commonSettingData } = this.props
    const loginUser = JSON.parse(getSession('loginUser')) || {}
    const wxInfo = JSON.parse(getSession('wxInfo')) || {}
    return (
      <Modal
        title={<FormattedMessage id="aboutPhotographer" />}
        visible={visible}
        onCancel={handleCancel}
        centered
        footer={<div></div>}
        width={600}
      >
        <div className={styles.aboutBody}>
          {commonSettingData.brandLogoPhotoInfo &&
            commonSettingData.brandLogoPhotoInfo.photoUrl && (
              <a href={PAGE_PATH.home} target='_blank'>
                <img
                  src={commonSettingData.brandLogoPhotoInfo.photoUrl}
                  className={styles.logo}
                />
              </a>
            )}

          <div className={styles.bandname}>
            {commonSettingData.name && <p>{commonSettingData.name}</p>}
          </div>
          {commonSettingData.areaString && (
            <p>{commonSettingData.areaString}</p>
          )}
          {commonSettingData.email && <p>{commonSettingData.email}</p>}
          {commonSettingData.contactNumber && (
            <p>{commonSettingData.contactNumber}</p>
          )}
          {commonSettingData.website && (
            <p><a href={`http://${commonSettingData.website}`} target="_blank" style={{color: '#4a4a4a'}}>{commonSettingData.website}</a></p>
          )}
          {cameraman.wx && <p>{cameraman.wx}</p>}
          {cameraman.contactWechatImagePhotoInfo &&
            cameraman.contactWechatImagePhotoInfo.photoUrl && (
              <>
                <p>
                  <img
                    src={cameraman.contactWechatImagePhotoInfo.photoUrl}
                    className={styles.wxCode}
                  />
                </p>
                <p>
                  <span className={styles.wxDec}><FormattedMessage id="wxAddFriend" /></span>
                </p>
              </>
            )}

          <div style={{justifyContent:'center', display:'flex'}}>
            <span className={styles.userInfo}
              // style={{ display: isEmpty(loginUser) ? 'none' : '' }}
            >
              <img className={styles.accProfoto} src={wxInfo.headImgUrl||Imgs.ProfotoDefault} ></img>
              <div>
                <div className={styles.accName}>{!!loginUser.customerName?loginUser.customerName:wxInfo.nickName}</div>
                <div className={styles.account}>{!!loginUser.customerPhone?loginUser.customerPhone:!!loginUser.customerEmail?loginUser.customerEmail:null}</div>
              </div>
              {/*{sessionStorage.getItem('accessType')!='clientMode'&&<div>sdhg</div>}*/}
            </span>
          </div>
        </div>
      </Modal>
    )
  }
}
