import moment from 'moment'

import { isEmpty, sortBy } from 'lodash'
import { getOptionBykeyName, getOption } from 'utils/Tools'
import { YesOrNoTypes } from 'constants/Options'
export const BookmarkPhotosSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  if (data) {
    newData.data = {
      pagination: {
        current: data.pageNo,
        pageSize: data.pageSize,
        total: data.totalCount,
      },
      list: data.list.map((img) => ({
        ...img,
        messageTime: isEmpty(img.messageTime)
          ? ''
          : moment(img.messageTime).format('YYYY/MM/DD hh:mm'),

        hasMessage: !isEmpty(img.message),
        isHideShow: getOptionBykeyName(
          'value',
          img.isHide,
          'key',
          YesOrNoTypes
        ), //是否允许客户设置图片隐藏,
      })),
    }
  }

  return newData
}

export const BookmarksSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  const list = data.map((item, index) => ({
    key: index,
    ...item,
  }))
  newData.data = sortBy(list, (item) => item.sort)
  return newData
}

//  收藏夹允许下载的分类
export const BookmarksDownloadSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  const list = data.map((item, index) => ({
    key: index,
    ...item,
  }))
  newData.data = sortBy(list, (item) => item.sort)
  return newData
}

// 设置收藏夹图片隐藏/显示
export const SetBookmarkPhotoHidenSelector = (data) => {
  let newData = {
    id: data.id,
    isHide: getOption(data.isHide, 'value', YesOrNoTypes), //访客访问需要通过关注微信访问
  }
  return newData
}
