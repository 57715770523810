/*
 * @Author: melon
 * @Date: 2020-08-20 17:38:46
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-02 23:14:11
 */
import request from 'utils/Request'

import Api from 'apis/Api.js'

// 登录
/**
 *
 * @param {account,password } param0
 *  account 账号
    password // 密码
 */
export const _login = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.member.login,
    headers: {
      exHeader,
    },
    data: {
      ...data,
    },
  })
}
export const _record = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.member.record,
    headers: {
      exHeader,
    },
    data: {
      ...data,
    },
  })
}
