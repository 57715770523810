import React, { useState, useRef } from 'react'
import { Layout, Menu, Tooltip } from 'antd'

import { Link, Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { PAGE_PATH } from 'apis/Router'
import { isEmpty, map, flow, first, prop, split, last } from 'lodash/fp'
import classNames from 'classnames'

import ContentWrapper from './Content'
import Imgs from 'imgs'

import styles from './topDownLayout.module.less'

const { Header, Content, Footer } = Layout
const { SubMenu } = Menu
const TopDownLayout = ({
  commonSetting,
  showcaseSetting,
  photoCategories,
  customerProjects,
  selectedId,
  setSelectedId,
}) => {
  const contentRef = useRef()
  const { weiboUrl, instagram, brandLogoUrl, websiteFooter } = commonSetting
  const { menus } = showcaseSetting
  const defaultImg = Imgs.LogoTemp
  const [brandLogoUrlTemp, setBrandLogoUrlTemp] = useState(brandLogoUrl)
  const imgerror = (event) => {
    if (isEmpty(brandLogoUrl)) {
      setBrandLogoUrlTemp(defaultImg)
    }
  }
  let location = useLocation()
  const changeMenu = async ({ key }) => {
    console.log('---->', key);
      await setSelectedId(key)
  }
  return (
    <div className={styles.Inf_layout_wrapper} ref={contentRef}>
      <Layout className={styles.Inf_layout}>
        <Header className={styles.Inf_header}>
          <div className={styles.Inf_header_logo}>
            <a href={PAGE_PATH.home}>
              <img src={brandLogoUrlTemp} alt="" onError={imgerror} />
            </a>
          </div>
          <div className={styles.Inf_menus_div}>
            {menus.length > 1 && (
              <Menu
                mode="horizontal"
                onClick={changeMenu}
                className={styles.Inf_menus}
                defaultSelectedKeys={[location.pathname, selectedId.toString()]}
                triggerSubMenuAction="hover"
                // triggerSubMenuAction="click"
              >
                {map((i) => {
                  const isSelectedPath = [location.pathname].includes(i.path);
                  if (["WORKS"].includes(i.key)) {
                    return (
                      <SubMenu
                        key={i.path}
                        title={i.menuName}
                        className={
                          isSelectedPath ? styles.Inf_selected_menu : ""
                        }
                      >
                        {map(
                          ({
                            name: childrenName,
                            id: childrenId,
                            key: childrenKey,
                          }) => (
                            <Menu.Item
                              key={childrenId}
                              className={styles.subMenuLi}
                            >
                              <Link to={i.path}>{childrenName}</Link>
                            </Menu.Item>
                          )
                        )(photoCategories)}
                      </SubMenu>
                    );
                  }
                  if (["CUSTOMER_PHOTO"].includes(i.key)) {
                    return (
                      <SubMenu
                        key={i.path}
                        title={i.menuName}
                        className={
                          isSelectedPath ? styles.Inf_selected_menu : ""
                        }
                      >
                        {map(
                          ({
                            name: childrenName,
                            id: childrenId,
                            key: childrenKey,
                          }) => (
                            <Menu.Item
                              key={childrenId}
                              className={styles.subMenuLi}
                            >
                              <Link to={i.path}>{childrenName}</Link>
                            </Menu.Item>
                          )
                        )(customerProjects)}
                      </SubMenu>
                    );
                  }
                  return (
                    <Menu.Item key={i.path}>
                      <Link to={i.path}>{i.menuName}</Link>
                    </Menu.Item>
                  );
                })(menus)}
              </Menu>
            )}
            {menus.length === 1 && ["WORKS"].includes(menus[0].key) && (
              <Menu
                mode="horizontal"
                onClick={changeMenu}
                className={styles.Inf_menus}
                defaultSelectedKeys={photoCategories[0] ? [photoCategories[0].id] : []}
                triggerSubMenuAction="click"
              >
                {map(({ name, id }) => (
                  <Menu.Item key={id} className={styles.subMenuLi}>
                    <Link to={menus[0].path}>{name}</Link>
                  </Menu.Item>
                ))(photoCategories)}
              </Menu>
            )}
            {menus.length === 1 && ["CUSTOMER_PHOTO"].includes(menus[0].key) && (
              <Menu
                mode="horizontal"
                onClick={changeMenu}
                className={styles.Inf_menus}
                defaultSelectedKeys={customerProjects[0] ? [customerProjects[0].id] : []}
                // triggerSubMenuAction="hover"
                triggerSubMenuAction="click"
              >
                {map(({ name, id }) => (
                  <Menu.Item key={id} className={styles.subMenuLi}>
                    <Link to={menus[0].path}>{name}</Link>
                  </Menu.Item>
                ))(customerProjects)}
              </Menu>
            )}
            <div className={styles.Inf_menu_opt}>
              {/* 微博地址 */}
              {!isEmpty(weiboUrl) && (
                // <Tooltip placement="bottom" title={'微博地址'}>
                <a
                  className={styles.Inf_menu_opt_item}
                  target="_blank"
                  href={`https://${weiboUrl}`}
                >
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#iconicon_sc_weibo"></use>
                  </svg>
                </a>
                // </Tooltip>
              )}
              {/* ins 地址 */}
              {!isEmpty(instagram) && (
                // <Tooltip placement="bottom" title={'instagram地址'}>
                <a
                  className={styles.Inf_menu_opt_item}
                  target="_blank"
                  href={`https://${instagram}`}
                >
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#iconicon_sc_instagram"></use>
                  </svg>
                </a>
                // </Tooltip>
              )}
            </div>
          </div>
        </Header>
        <ContentWrapper
          websiteFooter={websiteFooter}
          selectedId={selectedId}
          contentStyle={
            {
              //background: '#fff',
            }
          }
          // minHeight={650}
          scrollParentRef={contentRef}
          key={selectedId}
        />
      </Layout>
    </div>
  );
};

export default TopDownLayout;
