import React from 'react'
import { Modal } from 'antd'
import { getSession } from 'utils/Tools'
import { isEmpty } from 'lodash'
import { PAGE_PATH } from 'apis/Router'
import { FormattedMessage } from 'react-intl'
import styles from './aboutModal.module.less'

export default class AboutModal extends React.Component {
  state = {}

  componentDidMount() {}

  render() {
    const { visible, handleCancel, cameraman, commonSettingData } = this.props
    const loginUser = JSON.parse(getSession('loginUser')) || {}
    return (
      <Modal
        title={<FormattedMessage id="aboutPhotographer" />}
        visible={visible}
        onCancel={handleCancel}
        centered
        footer={<div></div>}
        width={600}
      >
        <div className={styles.aboutBody}>
          {commonSettingData.brandLogoPhotoInfo &&
            commonSettingData.brandLogoPhotoInfo.photoUrl && (
              <a href={PAGE_PATH.home} target='_blank'>
                <img
                  src={commonSettingData.brandLogoPhotoInfo.photoUrl}
                  className={styles.logo}
                />
              </a>
            )}

          <div className={styles.bandname}>{commonSettingData.name && <p>{commonSettingData.name}</p>}</div>
          {commonSettingData.areaString && (
            <p>{commonSettingData.areaString}</p>
          )}
          {commonSettingData.email && <p>{commonSettingData.email}</p>}
          {commonSettingData.contactNumber && <p>{commonSettingData.contactNumber}</p>}
          {commonSettingData.website && (
            <p><a href={`http://${commonSettingData.website}`} target="_blank" style={{color: '#4a4a4a'}}>{commonSettingData.website}</a></p>
          )}
          {cameraman.wx && <p>{cameraman.wx}</p>}

          {cameraman.contactWechatImagePhotoInfo &&
            cameraman.contactWechatImagePhotoInfo.photoUrl && (
              <>
                <p>
                  <img
                    src={cameraman.contactWechatImagePhotoInfo.photoUrl}
                    className={styles.wxCode}
                  />
                </p>
                <p><span className={styles.wxDec}><FormattedMessage id="wxAddFriend" /></span></p>
              </>
            )}

          <a
            className={styles.userInfo}
            style={{ display: isEmpty(loginUser) ? 'none' : '' }}
          >
            <div className={styles.accName}>{loginUser.customerName}</div>
            <div className={styles.account}>{loginUser.customerPhone}</div>
          </a>
        </div>
      </Modal>
    )
  }
}
