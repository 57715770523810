// Servers
import {
  _getAllShowcaseCategory,
  _getShowcasePhotoForPage,
} from 'servers/showcasePhoto'
// Selectors
import {
  ShowcaseCategoryListSelector,
  ShowcasePhotoListSelector,
} from 'selectors/showcasePhotoSelectors'
// Actions

// Action Creators

// init data
const initPagination = {
  pageNo: 1,
  pageSize: 50,
}

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action

// 获取分类
export const getAllShowcaseCategory = async (params) => {
  const res = await _getAllShowcaseCategory(params)

  return new Promise(function (resolve, reject) {
    resolve(ShowcaseCategoryListSelector(res))
    reject({})
  })
}

// 获取列表
export const getShowcasePhotoForPage = async (params) => {
  const res = await _getShowcasePhotoForPage(params)

  return new Promise(function (resolve, reject) {
    resolve(ShowcasePhotoListSelector(res))
    reject({})
  })
}
