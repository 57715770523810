import {
  Button,
  Checkbox,
  Divider,
  Input,
  Popover,
  Switch,
  Tooltip,
  Typography,
} from 'antd'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styles from './index.module.less'

const { Text } = Typography
const SharePopover = ({
  props,
  children,
  shareDetail = {},
  _setOpenShare,
  _setOpenSharePassword,
  exHeader,
  visitTypeShow,
  _addShare,
}) => {
  const [canShare, setCanShare] = useState(
    visitTypeShow || shareDetail.isOpenShare || false
  )
  const [checked, setChecked] = useState(
    shareDetail.isShareNeedPinCode || false
  )

  const intl = useIntl()
  const onChangeShare = async (checked) => {
    if (!visitTypeShow) {
      const res = await _setOpenShare({
        data: {
          onOff: checked,
        },
        exHeader: exHeader,
      })
      if ([0].includes(res.data.code)) {
        setCanShare(checked)
      }
    }
  }
  const onChangePW = async (e) => {
    const res = await _setOpenSharePassword({
      data: {
        onOff: e.target.checked,
      },
      exHeader: exHeader,
    })
    if ([0].includes(res.data.code)) {
      setChecked(e.target.checked)
    }
  }

  const copyContent = `${intl.formatMessage({ id: 'inviteLookPic' })}${shareDetail.projectName}${intl.formatMessage({ id: 'byAuthor' })} ${
    shareDetail.companyName
  }, ${intl.formatMessage({ id: 'link' })}：${shareDetail.shareUrl}${
    checked ? `，${intl.formatMessage({ id: 'password' })}: ` + shareDetail.sharePinCode : ''
  }`
  //添加分享记录
  const addShare=async ()=>{
    const res = await _addShare({
      data: {
        content:'影集',
        source:'WEB',
        accessType:sessionStorage.getItem('accessType')
      },
      exHeader: exHeader,
    })
    if ([0].includes(res.data.code)) {

    }
  }
  const content = (
    <div>
      <div className={styles.shareSwitch}>
        <div>
          <div className={styles.shareLabel}>
          {intl.formatMessage({ id: 'publicShare' })}
          </div>
          {sessionStorage.getItem('accessType')=='clientMode' && (
          <div className={styles.shareDesc}>
            {intl.formatMessage({ id: 'shareTip' })}
          </div>
          )}
        </div>
        {/* <div>
          <Switch onChange={onChangeShare} checked={canShare} />
        </div> */}
      </div>
      {/* <Divider /> */}
      <div className={styles.shareUrl}>
        <Input
          defaultValue={shareDetail.shareUrl}
          readOnly
          style={{ padding: '4px 11px' }}
        />
        {canShare ? (
          <CopyToClipboard text={copyContent}>
            <Tooltip title={intl.formatMessage({ id: 'copied' })} trigger={['click']}>
              <Button type="primary" onClick={addShare} className="Inf_black_btn">
                {intl.formatMessage({ id: 'copy' })}
              </Button>
            </Tooltip>
          </CopyToClipboard>
        ) : (
          <Button onClick={addShare} type="primary" className="Inf_black_btn">
            {intl.formatMessage({ id: 'copy' })}
          </Button>
        )}
      </div>
      <div style={{ display: !visitTypeShow && canShare ? '' : 'none' }}>
        {/* <div className={styles.sharePW}>
          <div className={styles.needPW}>
            <Checkbox checked={checked} onChange={onChangePW}>
              {intl.formatMessage({ id: 'needCode' })}
            </Checkbox>
          </div>
          <div style={{ display: checked ? '' : 'none' }}>
            {intl.formatMessage({ id: 'password' })}
            <Input
              style={{ padding: '4px 11px', width: 70, marginLeft: '0.75rem' }}
              defaultValue={shareDetail.sharePinCode}
              readOnly
            />
          </div>
        </div> */}
        <div className={styles.shareDesc}>
          {intl.formatMessage({ id: 'copyToShare' })}
        </div>
      </div>
    </div>
  )
  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      content={content}
      {...props}
      overlayClassName={`${styles.sharePopover} noArrow`}
    >
      {children}
    </Popover>
  )
}
export default SharePopover
