import moment from 'moment'

import { isEmpty, sortBy } from 'lodash'
import {
  getOptionBykeyName,
  getOption,
  initFormFeildDateValue,
} from 'utils/Tools'
import Imgs from 'imgs'
import { YesOrNoTypes, VisitTypes } from 'constants/Options'

export const ProjectCategorysSelector = (res) => {
  const defaultItem = {
    id: '',
    key: 'all',
    name: '全部',
    isDefault: 'Y',
    sort: 0,
  }
  let newData = {
    ...res.data,
    // code: 9500,
  }
  const { data } = res.data
  if ([0].includes(res.data.code)) {
    newData.data = [
      defaultItem,
      ...data.map((item) => ({
        key: item.id,
        ...item,
        //是否允许客户设置图片隐藏,
      })),
    ]
  }
  return newData
}

export const ProjectListSelector = (res) => {
  let newData = {
    ...res.data,
  }

  const { data, code } = res.data
  if ([0].includes(code)) {
    newData.data = data.list.map((item) => ({
      key: item.id,
      ...item,
      img: item.coverPhotoIngo.photoUrl,
      shootingDay: moment(item.shootingDay).format('YYYY/MM/DD'),
      visitTypeOpen: item.visitTypeShow === VisitTypes[0].key,
      isAllowVisitorShow: getOptionBykeyName(
        'value',
        item.isAllowVisitor,
        'key',
        YesOrNoTypes
      ), //是否允许客户设置图片隐藏,
      isLock: ['PASSWORD'].includes(item.visitType), // 是否上锁
    }))
    newData.pagination={
      pageNo:Number(data.pageNo),
      pageSize:Number(data.pageSize),
      total:data.totalCount
    }
  }
  return newData
}

// 项目详情
export const ProjectSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    ...data,
    visitTypeShow: data.visitType === VisitTypes[0].key, // open为true，password为false
    coverPhotoInfoUrl: !isEmpty(data.coverPhotoInfo)
      ? data.coverPhotoInfo.photoUrl
      : '', // 封面 url
    customerLogoPhotoInfoUrl: !isEmpty(data.customerLogoPhotoInfo)
      ? data.customerLogoPhotoInfo.photoUrl
      : '', // 客片Logo url
    shootingDay: moment(data.shootingDay).format('YYYY/MM/DD'),
    isAllowShare: getOptionBykeyName(
      'value',
      data.isAllowShare,
      'key',
      YesOrNoTypes
    ), // 分享的照片 Y:是, N:否
    isGuestPieceShow: getOptionBykeyName(
      'value',
      data.isGuestPieceShow,
      'key',
      YesOrNoTypes
    ), //客片栏目是否显示该影集 Y:是, N:否

    isAllowVisitorMeetingConditions: getOptionBykeyName(
      'value',
      data.isAllowVisitorMeetingConditions,
      'key',
      YesOrNoTypes
    ), //访客访问需要通过关注微信访问
    isSetPhotoHidden: getOptionBykeyName(
      'value',
      data.isSetPhotoHidden,
      'key',
      YesOrNoTypes
    ), //是否允许客户设置图片隐藏
    areaArray: !isEmpty(data.customerProvinceGeoId)
      ? [
          data.customerProvinceGeoId,
          data.customerCityGeoId,
          data.customerAreaGeoId,
        ]
      : [], // 省市区
    canDownload: getOptionBykeyName(
      'value',
      data.canDownload,
      'key',
      YesOrNoTypes
    ), //是否显示收藏夹及相关功能
    isDownloadCode: getOptionBykeyName(
      'value',
      data.isDownloadCode,
      'key',
      YesOrNoTypes
    ), //是否显示收藏夹及相关功能
    isShowBookmark: getOptionBykeyName(
      'value',
      data.isShowBookmark,
      'key',
      YesOrNoTypes
    ), //是否显示收藏夹及相关功能
    isMessage: getOptionBykeyName('value', data.isMessage, 'key', YesOrNoTypes), //是否显示收藏夹及相关功能
    isLock: ['PASSWORD'].includes(data.visitType), // 是否上锁
    isOpenShare: getOptionBykeyName(
      'value',
      data.isOpenShare,
      'key',
      YesOrNoTypes
    ), //是否公开分享
    isShareNeedPinCode: getOptionBykeyName(
      'value',
      data.isShareNeedPinCode,
      'key',
      YesOrNoTypes
    ), //分享是否需要密码访问
    bookmarkIsOpenShare: getOptionBykeyName(
      'value',
      data.bookmarkIsOpenShare,
      'key',
      YesOrNoTypes
    ), //收藏夹是否公开分享
    bookmarkIsShareNeedPinCode: getOptionBykeyName(
      'value',
      data.bookmarkIsShareNeedPinCode,
      'key',
      YesOrNoTypes
    ), //收藏夹分享是否需要密码访问
  }
  return newData
}

// 项目详情
export const ProjectInfoForLoginSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  newData.data = {
    ...data,
    customerLogoPhotoInfoUrl: !isEmpty(data)&&!isEmpty(data.customerLogoPhotoInfo)&&!isEmpty(data.customerLogoPhotoInfo.photoUrl)
      ? data.customerLogoPhotoInfo.photoUrl
      : Imgs.LogoTemp, // 客片Logo url
    coverPhotoInfoUrl: !isEmpty(data)&&!isEmpty(data.coverPhotoInfo)&& !isEmpty(data.coverPhotoInfo.photoUrl)
      ? data.coverPhotoInfo.photoUrl
      : Imgs.DefaultCoverPNG, // 封面 url
    largeCoverPhotoInfoUrl: !isEmpty(data)&&!isEmpty(data.largeCoverPhotoIngo)
    ? data.largeCoverPhotoIngo.photoUrl
    : Imgs.DefaultCoverPNG, // 封面大图 url
    loseDayText: !isEmpty(data)&&!!data.loseDay
    ?moment(data.loseDay).format('YYYY/MM/DD'):'', // 失效日期
    shootingDayText: !isEmpty(data)&&!!data.shootingDay
    ?moment(data.shootingDay).format('YYYY/MM/DD'):'', // 拍摄时间
    isLock: !isEmpty(data)&&!isEmpty(data.visitType)
        ?['PASSWORD'].includes(data.visitType):'', // 是否上锁
  }
  return newData
}

export const SetOpenShareSelector = (data) => {
  let newData = {
    onOff: getOption(data.onOff, 'value', YesOrNoTypes), //设置客片分享是否打开
  }
  return newData
}

export const SetOpenSharePasswordSelector = (data) => {
  let newData = {
    onOff: getOption(data.onOff, 'value', YesOrNoTypes), //设置客片分享密码是否打开
  }
  return newData
}

export const SetBookmarkOpenShareSelector = (data) => {
  let newData = {
    onOff: getOption(data.onOff, 'value', YesOrNoTypes), //设置收藏夹分享是否打开
  }
  return newData
}

export const SetBookmarkOpenSharePasswordSelector = (data) => {
  let newData = {
    onOff: getOption(data.onOff, 'value', YesOrNoTypes), //设置收藏夹分享密码是否打开
  }
  return newData
}
export const DownloadCodeSelector = (res) => {
  let newData = {
    ...res.data,
  }
  return newData
}
