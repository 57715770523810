// Servers
import { _getAll } from 'servers/showcaseSetting'
// Selectors
import { ShowcaseSettingInfoSelector } from 'selectors/showcaseSettingSelectors'
// Actions
const GET_SHOWCASE_SETTING_INFO = 'GET_SHOWCASE_SETTING_INFO'
const GET_SHOWCASE_SETTING_INFO_SUCCESS = 'GET_SHOWCASE_SETTING_INFO_SUCCESS'
const GET_SHOWCASE_SETTING_INFO_FAIL = 'GET_SHOWCASE_SETTING_INFO_FAIL'

// Action Creators

export const getShowcaseSettingInfoAction = () => ({
  type: GET_SHOWCASE_SETTING_INFO,
})

export const getShowcaseSettingInfoSuccessAction = (data) => ({
  type: GET_SHOWCASE_SETTING_INFO_SUCCESS,
  data: data,
})
export const getShowcaseSettingInfoFailAction = (data) => ({
  type: GET_SHOWCASE_SETTING_INFO_FAIL,
  data,
})

// init data

const initialState = {
  data: {},
  errorMessage: null,
  fetching: false,
  code: 0,
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SHOWCASE_SETTING_INFO:
      return {
        ...state,
        fetching: true,
      }
    case GET_SHOWCASE_SETTING_INFO_SUCCESS:
      return {
        ...state,
        data: action.data,
        fetching: false,
      }
    case GET_SHOWCASE_SETTING_INFO_FAIL:
      return {
        ...state,
        fetching: false,
        errorMessage: action.data.msg,
        code: action.data.code,
      }
    default:
      return state
  }
}

//异步action

export const getAll = async (params) => {
  return async (dispatch) => {
    dispatch(getShowcaseSettingInfoAction())
    const res = await _getAll(params)
    if ([0].includes(res.data.code)) {
      dispatch(
        getShowcaseSettingInfoSuccessAction(
          ShowcaseSettingInfoSelector(res.data.data)
        )
      )
    } else {
      dispatch(getShowcaseSettingInfoFailAction(res.data))
    }
  }
}
