/**
 * Help是提供特殊模块公用的方法的类
 */

import { isEmpty } from 'lodash/fp'
import addressData from 'assets/data.json'
/**
 * 根据 key 和 value 设置  sessionStorage
 * @param {*} sessionStorageKey key
 * @param {*} sessionStorageValue value
 */
export const setSessionStorageByKey = (
  sessionStorageKey = '',
  sessionStorageValue = ''
) => {
  const oldValue = sessionStorage.getItem(sessionStorageKey)
  if (isEmpty(oldValue)) {
    sessionStorage.setItem(sessionStorageKey, sessionStorageValue)
  } else {
    sessionStorage.removeItem(sessionStorageKey)
    sessionStorage.setItem(sessionStorageKey, sessionStorageValue)
  }
}
/**
 * 根据 key 获取对应的 sessionStorage 值
 * @param {*} sessionStorageKey key
 */
export const getSessionStorageValue = (sessionStorageKey = '') => {
  const sessionStorageValue = sessionStorage.getItem(sessionStorageKey)
  return sessionStorageValue
}
/**
 * 根据 key 删除对应的 sessionStorage
 * @param {*} sessionStorageKey
 */
export const removeSessionStorageByKey = (sessionStorageKey = '') => {
  sessionStorage.removeItem(sessionStorageKey)
}

export const getAddressString = (arrayAddr = []) => {
  let string = ''
  const matchP = addressData.filter(
    (province) => province.value === arrayAddr[0]
  )[0]
  if (matchP) {
    string = string + matchP.label
  }
  const matchC = matchP.children.filter(
    (city) => city.value === arrayAddr[1]
  )[0]
  if (matchC) {
    string = string + matchC.label
  }
  const matchZ = matchC.children.filter(
    (zone) => zone.value === arrayAddr[2]
  )[0]
  if (matchZ) {
    string = string + matchZ.label
  }
  return string
}
