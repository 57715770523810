import Mock from 'mockjs'

const { Random } = Mock

// 文件夹分类
export const getFavoriteList = () =>
  Array.from({ length: Random.natural(2, 5) }, (v, i) => ({
    id: i + 1,
    name: Random.cword(2) + '收藏夹',
    maxCount: Random.natural(50, 100),
    plusFee: Random.natural(10, 100),
    remark: Random.capitalize(),
    sort: i,
  }))

// 分页文件夹图片

export const getPhotosByBookmark = (query) => {
  const pageNo = Number(query.pageNo)
  const pageSize = Number(query.pageSize)
  const totalCount = [53, 36, 18, 2, 40, 12, 16, 4][query.bookmarkId]
  const residue = totalCount - (pageNo - 1) * pageSize
  const pagePhotoCount = residue / pageSize > 1 ? pageSize : residue
  return {
    pageNo: pageNo,
    pageSize: pageSize,
    totalCount: totalCount,
    totalPage: Math.ceil(totalCount / pageSize),
    list: Array.from({ length: pagePhotoCount }, (v, i) => {
      const imgWidth = Random.natural(300, 960)
      const imgHeight = Random.natural(300, 960)
      return {
        id: query.bookmarkId + '_' + Random.guid(),
        bookmarkId: query.bookmarkId, //所属文件夹id
        sort: i,
        message: [true, false][Random.natural(0, 1)] ? Random.ctitle() : '', //  留言
        messageTime: Random.datetime(), //  留言时间
        isHide: ['Y', 'N'][Random.natural(0, 1)], //  是否隐藏
        photoInfo: {
          photoKey: Random.guid(),
          photoUrl: Random.image(`${imgWidth}x${imgHeight}`, Random.color()),
          photoSize: Random.natural(50, 150),
          photoFormat: ['png', 'jpeg', 'svg', 'jpg'][Random.natural(0, 3)],
          photoWidth: imgWidth,
          photoHeight: imgHeight,
        },
      }
    }),
  }
}

//  收藏夹打包下载地址

export const BookmarkDownloadUrlData = {
  packageDownloadName: Random.word(12, 30) + '.zip',
  packageDownloadUrl: Random.url(),
  packageSize: Random.natural(1, 2048), // 文件大小(kb)
}
