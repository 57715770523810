import React, { useState } from 'react'
import { Button, Modal, Form, Input } from 'antd'
import { TrimInput } from '../WithTrim/index'
import { LoginAccountValidateRule } from 'utils/ValidateRulesAndMsg'
import { useIntl } from 'react-intl'
import styles from './index.module.less'
const InfPhoneCheckModal = ({ visible, onOk, onCancel }) => {
  const [form] = Form.useForm()
  // 登录状态
  const intl = useIntl()
  const [requesting, setRequesting] = useState(false)
  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setRequesting(true)
          await onOk(values, form)
        } catch (error) {
        } finally {
          setRequesting(false)
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }
  return (
    <Modal
      wrapClassName={styles.InfPhoneCheckModal}
      width={600}
      transitionName={'none'}
      maskTransitionName={'none'}
      visible={visible}
      onCancel={onCancel}
      title={
        <div className="Inf_modal_title">
          <span className="Inf_modal_title_text">{intl.formatMessage({ id: 'visitorValidate' })}</span>
          <span className="Inf_modal_subtitle_text">
            {intl.formatMessage({ id: 'needUserInfo' })}
          </span>
        </div>
      }
      footer={[
        <Button
          key="move"
          className="link-btn-default"
          onClick={() => onCancel()}
        >
          {intl.formatMessage({ id: 'cancel' })}
        </Button>,
        <Button
          loading={requesting}
          key="copy"
          className="Inf_black_btn"
          onClick={() => onSubmit()}
        >
          {intl.formatMessage({ id: 'submit' })}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="inf_downLoad_modal">
        <Form.Item
          name="code"
          label={intl.formatMessage({ id: 'phoneEmail' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'validatePhoneOrEmail' }),
            },
            LoginAccountValidateRule(intl),
          ]}
        >
          <TrimInput max={50} placeholder={intl.formatMessage({ id: 'validatePhoneOrEmail' })} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default InfPhoneCheckModal
