import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Switch, Divider, Dropdown, Menu} from 'antd'
import React, {useRef, useState,useEffect} from 'react'
import { useIntl } from 'react-intl'
import styles from './wxEmailModal.module.less'
import { EmailValidateRule } from 'utils/ValidateRulesAndMsg'
import {FormattedMessage} from "react-intl";


const WxEmailModal  = ({
                       visible,
                         emailLogin,
                         projectInfo,
                           formRef,
                           form
                     }) => {
  const intl = useIntl()
  const [isWx, setIsWx] = useState(true)


  useEffect(() => {

  }, [])

  const handleModal = (value) => {
    setIsWx(value)
  }
  const toH5=()=>{
    window.location.href='https://static.infotos.co/wxInfo/index.html?collectionUrl='+window.btoa(window.location.href+'?token='+sessionStorage.getItem('token')+'&accessType='+sessionStorage.getItem('accessType'))
  }
  const doCheck=()=>{
      emailLogin()
    /*formRef.current
        .validateFields()
        .then(async (values) => {
       console.log(values)
          // 派发修改数据请求
          try {
            // const resData = await doSubmit(body)

          } catch (error) {
          } finally {

          }
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })*/
  }
  return (
      <Modal
          visible={visible}
          closable={false}
          width={600}
          footer={null}
          centered
      >
        <Form
            ref={formRef}
            form={form}
            layout="vertical"
            name="add_project_form"
            requiredMark={false}
        >
          <div className={styles.form}>
            <div className={styles.name}>{projectInfo.name}</div>
            <div className={styles.remarkText}>{intl.formatMessage({ id: 'needUserInfo' })}</div>
            {isWx?(
                <div className={styles.cont}>
                  <div onClick={toH5} className={`iconfont iconico_wechatmark ${styles.wx}`}></div>
                  <span>{intl.formatMessage({ id: 'wxAuth' })}</span>
                </div>
            ):(
                <div className={styles.email}>
                    <div className={`iconfont iconico_emailmark ${styles.emailMark}`}></div>
                  <Form.Item
                      label=""
                      name="customerEmail"
                      rules={[
                        {
                          type: 'email',
                          message: intl.formatMessage({ id: 'inputEmailTip' }),
                        },
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'inputEmail' }),
                        },
                      ]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'inputEmail' })} />
                  </Form.Item>
                  <Button onClick={doCheck}>{intl.formatMessage({ id: 'submit' })}</Button>
                </div>
            )}
            <div style={{margin:'50px 0 10px'}}>{intl.formatMessage({ id: 'or' })}</div>
            <div onClick={()=>{setIsWx(!isWx)}} className={styles.btn}>{isWx?`${intl.formatMessage({ id: 'email4login' })}`:`${intl.formatMessage({ id: 'wechat4login' })}`}</div>
          </div>
        </Form>
      </Modal>
  )
}

export default WxEmailModal
