import React, { useState, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { Layout, Spin, Button, Menu, Tooltip, Affix, Dropdown } from 'antd'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Masonry from 'masonry-layout'
import AboutModal from 'pages/CategoryPhotosPage/Components/AboutModal'
import InfiniteScroll from 'react-infinite-scroller'

import {
  getPhotosByBookmark as _getPhotosByBookmark,
  getFavoriteList as _getFavoriteList,
  saveMessage as _saveMessage,
  setBookmarkPhotoHide as _setBookmarkPhotoHide,
  cancelBookmark as _cancelBookmark,
  getProjectById as _getProjectById,
  setBookmarkOpenShare as _setBookmarkOpenShare,
  setBookmarkOpenSharePassword as _setBookmarkOpenSharePassword,
  getBookmarkSingleDownloadUrl as _getBookmarkSingleDownloadUrl,
  paymentTip as _paymentTip,
  verifyDownloadCode as _verifyDownloadCode,
} from 'reduxModules/customerPhoto'
import { isEmpty } from 'lodash/fp'
import MasonryImgs from '../Components/MasonryImgs'
import BottomNotice from '../Components/BottomNotice'
import ShareProjectForm from 'pages/CategoryPhotosPage/Components/ShareProjectForm'
import { PAGE_PATH } from 'apis/Router'
import myMessage from 'components/Message/index'
import styles from './index.module.less'
import { getToken } from 'utils/Request'
import { getFullRoute } from 'utils/Tools'
import { login as _login } from 'reduxModules/member'

import { setToken, removeToken } from 'utils/Request'
import {
  setSessionStorageByKey,
  getSessionStorageValue,
  removeSessionStorageByKey,
} from 'utils/Help'
import commonComponents from 'components/index'
const {
  InfDownLoadModal,
  InfPhoneCheckModal,
  SharePopover,
  CommonFooter,
} = commonComponents

const { SubMenu } = Menu
const FavoritePage = (props) => {
  // const [imgsData, setImgsData] = useState([])
  let history = useHistory()
  let { id } = useParams()
  const showcase = useSelector((state) => state.showcaseSetting.data)
  const commonSettingData = useSelector((state) => state.commonSetting.data)
  const token = getToken()
  const exHeader = isEmpty(token)
    ? {
        projectId: id,
      }
    : {
        projectId: id,
      }
  const intl = useIntl();
  const [favoriteList, setFavoriteList] = useState([])
  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedFavDetail, setSelectedFavDetail] = useState({})
  const [shareFormVisible, setShareFormVisible] = useState(false)

  const [aboutVisible, setAboutVisible] = useState(false)

  const [showBottom, setShowBottom] = useState(true)
  // 下载登录
  const [downloadModalVisible, setDownloadModalVisible] = useState(false)

  // 客户验证
  const [customerModalVisible, setCustomerModalVisible] = useState(false)

  // 项目详情
  const [project, setProject] = useState({})

  // 验证的方法对象
  const [callbackObj, setCallbackObj] = useState({
    callbackFuncs: {},
    callbackName: '',
    callbackOptions: {},
  })
  //isDownLodaCode 验证码是否正确
  const [isDownLodaCode, setIsDownLodaCode] = useState(false)

  useEffect(() => {
    getFavoriteList()
    getProjectData()
  }, [])
  const getFavoriteList = async () => {
    const res = await _getFavoriteList({
      exHeader,
    })
    if (res.code == 0) {
      setFavoriteList(res.data)
      if (isEmpty(selectedCategory)) {
        const bookmark = res.data[0]
        setSelectedFavDetail({
          id: bookmark.id,
          name: bookmark.name,
          maxCount: bookmark.maxCount,
          price: bookmark.plusFee,
        })
        setSelectedCategory(bookmark.id.toString())
        getShowBottom(bookmark.id)
      }
    } else if ([9200].includes(res.code)) {
      backToLogin()
    }
  }
  const backToLogin = () => {
    history.push(
      getFullRoute(PAGE_PATH.customerVisit, {
        id: id,
      })
    )
  }
  // 子组件瀑布流获取最大数量
  const setFavDetailSelected = (selectedCount) => {
    setSelectedFavDetail({
      ...selectedFavDetail,
      selectedCount,
    })
  }
  // 获取项目信息
  const getProjectData = async () => {
    const res = await _getProjectById({
      params: {
        id: id,
      },
      exHeader,
    })
    if (res.code == 0) {
      setProject(res.data)
      getVerifyCode(res.data.id)
    }
  }
  //若已有验证码则判定当前验证码
  const getVerifyCode=async(id)=>{
    if(!!getSessionStorageValue('pin')){
      const res = await _verifyDownloadCode({
        data: {
          code:getSessionStorageValue('pin'),
          projectId:id
        },
        exHeader,
      })
      if (res.code == 0&&res.data) {
        setIsDownLodaCode(true)
      }
    }
  }
  const getShowBottom = async (id) => {
    const res = await _paymentTip({
      data: {
        id: id,
      },
      exHeader: exHeader,
    })
    if (res.data.code == 0) {
      setShowBottom(res.data.data)
    }
  }

  // 切换按钮
  const changeMenu = ({ key }) => {
    const bookmark = favoriteList.filter((item) => item.id === key)[0]
    getShowBottom(bookmark.id)
    setSelectedFavDetail({
      id: bookmark.id,
      name: bookmark.name,
      maxCount: bookmark.maxCount,
      price: bookmark.plusFee,
    })
    setSelectedCategory(key)
  }

  const showShareVisible = () => {
    setShareFormVisible(true)
  }
  // 跳转到打包下载页面
  const gotoPackDownload = () => {
    history.push(
      getFullRoute(PAGE_PATH.download, {
        id: id,
        type: window.btoa('favorite'),
      })
    )
  }

  // 下载登录
  const downloadLogin = async (value, form) => {
    //判定验证码是否正确
    const res = await _verifyDownloadCode({
      data: {
        code:value.code,
        projectId:project.id
      },
      exHeader,
    })
    const { callbackFuncs, callbackName, callbackOptions } = callbackObj
    if (res.code == 0&&res.data) {
      setDownloadModalVisible(false)
      setSessionStorageByKey('pin', value.code)
      form.resetFields()
      return setTimeout(() => {
        callbackFuncs[callbackName](callbackOptions)
      }, 1000)
    }else{
      form.setFields([
        {
          name: 'code',
          value: value.code,
          errors: [intl.formatMessage({ id: 'pinCodeNoRight' })],
        },
      ])
    }
    return
    const downloadCode = project.downloadCode
    const canView = downloadCode === value.code
    // const { callbackFuncs, callbackName, callbackOptions } = callbackObj
    if (canView) {
      setDownloadModalVisible(false)
      setSessionStorageByKey('pin', value.code)
      form.resetFields()
      return setTimeout(() => {
        callbackFuncs[callbackName](callbackOptions)
      }, 1000)
    } else {
      form.setFields([
        {
          name: 'code',
          value: value.code,
          errors: [intl.formatMessage({ id: 'pinCodeNoRight' })],
        },
      ])
    }
  }

  // 验证手机号或email
  const checkPhone = async (value, form) => {
    const { callbackFuncs, callbackName, callbackOptions } = callbackObj
    const res = await _login({
      data: {
        account: value.code,
      },
      exHeader,
    })

    if ([0].includes(res.data.code)) {
      const { token } = res.data.data
      await setToken(token)
      setCustomerModalVisible(false)
      form.resetFields()
      callbackFuncs[callbackName](callbackOptions)
    } else {
      form.setFields([
        {
          name: 'code',
          value: value.code,
          errors: [intl.formatMessage({ id: 'pinCodeNoRight' })],
        },
      ])
    }
  }

  // 验证登陆状态
  const checkIdentity = async ({ type = '', funcName = '', options = {} }) => {
    const isDownloadType = ['packDownload', 'download'].includes(type)
    // 1. 获取当前的验证信息
    const pin = getSessionStorageValue('pin')
    //  2. 获取当前项目是否公开 是否下载需要验证码
    const { isLock, isDownloadCode, downloadCode } = project
    const { callbackFuncs } = callbackObj
    setCallbackObj({
      ...callbackObj,
      callbackName: funcName,
      callbackOptions: options,
    })
    // 是否验证了 pin 码 （用于需要 pin 码验证的情况）
    const isCheckPin = [downloadCode].includes(pin)

    if (isDownloadType && isDownloadCode && !isCheckPin) {
      // 是下载操作 如果需要下载验证 且客户未验证过 显示下载登录弹窗
      //若验证码已验证且正确则直接下载否则显示弹出框
      if(isDownLodaCode){
        // callbackName.current=funcName
        // callbackOptions.current=options
        return setTimeout(() => {
          callbackFuncs[funcName](options)
        }, 1000)
      }else {
        setDownloadModalVisible(true)
      }
    } else {
      // 派发请求 看登录是否过期
      const res = await callbackFuncs[funcName](options)
      if (!isLock && res && [9403, 9200].includes(res.data.code)) {
        // 如果处于公开 且客户未验证过 显示客户验证弹窗
        setCustomerModalVisible(true)
      }
    }
  }
  // 退出
  const loginOut = () => {
    // 删除用户登录的token
    removeToken()
    removeSessionStorageByKey('pin')
    // 跳转回客片登录页
    history.push(
      getFullRoute(PAGE_PATH.customerVisit, {
        id: id,
      })
    )
  }

  const doGoback = () => {
    history.push(
      getFullRoute(PAGE_PATH.projectDetail, {
        id: id,
      })
    )
    history.go()
  }

  // 验证后可调用的回调集
  const funcs = {
    gotoPackDownload: gotoPackDownload,
    showShareVisible: showShareVisible,
  }
  useEffect(() => {
    setCallbackObj({
      ...callbackObj,
      callbackFuncs: {
        ...callbackObj.callbackFuncs,
        ...funcs,
      },
    })
  }, [])

  const showAbout = () => {
    setAboutVisible(true)
  }

   // 跳转到我的订单页面
   const showOrder =() => {
    
   }

  // 跳转到我的订单页面
  const showProject =() => {
  
  }

  // 关于用户
  const userMenu = (
    <div className={styles.aboutMenu}>
      <Menu>
        <Menu.Item>
          <a onClick={showAbout}>{intl.formatMessage({ id: 'about' })}</a>
        </Menu.Item>
        <Menu.Divider />
        {/* <Menu.Item>
          <a onClick={showProject}>{intl.formatMessage({ id: "myProjects"})}</a>
        </Menu.Item> */}
        <Menu.Item>
          <a onClick={showOrder}>{intl.formatMessage({ id: "myOrders"})}</a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a onClick={loginOut}>{intl.formatMessage({ id: 'quit' })}</a>
        </Menu.Item>
      </Menu>
    </div>
  )
  return (
    <div className={styles.favphotoLayout}>
    <Layout>
      <div className={styles.header}>
        <Affix>
          <div className={styles.topArea}>
            <div className={styles.bkButton}>
              <Button onClick={doGoback}>
                <svg className="icon" aria-hidden="true">
                  <use xlinkHref="#iconbtn_back"></use>
                </svg>
                {intl.formatMessage({ id: 'back' })}
              </Button>
              {/* <span className={styles.title}>收藏夹</span> */}
            </div>
            <div className={styles.category}>
              <Menu
                mode="horizontal"
                className={styles.Inf_menus}
                onClick={changeMenu}
                selectedKeys={[selectedCategory]}
              >
                {favoriteList.map((category) => (
                  <Menu.Item key={category.id}>{category.name}</Menu.Item>
                ))}
              </Menu>
            </div>
            <div className={styles.iconBtns}>
              {project.canDownload && (sessionStorage.getItem('accessType')=='clientMode'||(sessionStorage.getItem('accessType')=='visitorMode' && project.isVisitorDownload=='Y')) && (
                <Tooltip
                  placement="bottom"
                  title={intl.formatMessage({ id: 'downLoadAllPic' })}
                  overlayClassName="no-arrow"
                >
                  <a>
                    <span
                      onClick={() =>
                        checkIdentity({
                          type: 'packDownload',
                          funcName: 'gotoPackDownload',
                        })
                      }
                      className="iconfont iconbtn_downloadall"
                    ></span>
                  </a>
                </Tooltip>
              )}

              {/* 收藏夹分享功能
              {project.isAllowShare && (
                <SharePopover
                  shareDetail={{
                    shareUrl: project.bookmarkShareUrl,
                    sharePinCode: project.bookmarkSharePinCode,
                    isOpenShare: project.bookmarkIsOpenShare,
                    isShareNeedPinCode: project.bookmarkIsShareNeedPinCode,
                    projectName: project.name,
                    companyName: commonSettingData.name,
                  }}
                  exHeader={exHeader}
                  visitTypeShow={project.visitTypeShow}
                  _setOpenShare={_setBookmarkOpenShare}
                  _setOpenSharePassword={_setBookmarkOpenSharePassword}
                >
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: 'shareToFriend' })}
                    overlayClassName="no-arrow"
                  >
                    <a>
                      <span className="iconfont iconbtn_share"></span>
                    </a>
                  </Tooltip>
                </SharePopover>
              )} */}

              <Dropdown overlay={userMenu} trigger="click">
                <a>
                  <svg className={`icon ${styles.user}`} aria-hidden="true">
                    <use xlinkHref="#iconbtn_info"></use>
                  </svg>
                </a>
              </Dropdown>
            </div>
          </div>
        </Affix>
      </div>
      <div className={styles.waterfullBody}>
        {favoriteList.length > 0 && (
          <MasonryImgs
            _pagePhotos={_getPhotosByBookmark}
            _saveMessage={_saveMessage}
            _cancelBookmark={_cancelBookmark}
            _setBookmarkPhotoHide={_setBookmarkPhotoHide}
            _getBookmarkSingleDownloadUrl={_getBookmarkSingleDownloadUrl}
            categoryId={selectedCategory}
            key={selectedCategory}
            exHeader={exHeader}
            checkIdentity={checkIdentity}
            setCallbackObj={setCallbackObj}
            callbackObj={callbackObj}
            backToLogin={backToLogin}
            project={project}
            setFavDetailSelected={setFavDetailSelected}
            intl={intl}
          />
        )}
      </div>
      <ShareProjectForm
        visible={shareFormVisible}
        onCancel={() => setShareFormVisible(false)}
        shareUrl={'aaa'}
        key={1}
      />
      {/* 下载弹窗 */}
      <InfDownLoadModal
        visible={downloadModalVisible}
        onCancel={() => setDownloadModalVisible(false)}
        onOk={downloadLogin}
      />
      {/* 用户验证弹窗 */}
      <InfPhoneCheckModal
        visible={customerModalVisible}
        onCancel={() => setCustomerModalVisible(false)}
        onOk={checkPhone}
      />
      <AboutModal
        visible={aboutVisible}
        handleCancel={() => setAboutVisible(false)}
        cameraman={showcase}
        commonSettingData={commonSettingData}
      />
      <BottomNotice
        bookMarkDetail={selectedFavDetail}
        key={selectedFavDetail.id}
        isShow={showBottom}
        // _paymentTip={_paymentTip}
        // exHeader={exHeader}
      />
      <CommonFooter websiteFooter={commonSettingData.websiteFooter} />
    </Layout>
    </div>
  )
}

export default FavoritePage
