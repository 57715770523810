/*
 * @Author: melon
 * @Date: 2020-07-02 16:10:25
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-25 01:29:37
 */

import { Button, DatePicker, Form, Select } from 'antd'
import classNames from 'classnames'
import commonComponent from 'components/index'
import React from 'react'
import {
  PhoneValidateRule,
  RequiredInputRule,
} from 'utils/ValidateRulesAndMsg'
import { FormattedMessage } from 'react-intl';
import { HowToFindTypes } from 'constants/Options'

import styles from './Contact.module.less'
const { TrimInput, TrimTextArea, MyMessage } = commonComponent
const { Option } = Select
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

const tailLayout = {
  wrapperCol: { span: 24 },
}
class WebContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      btnLoginLoading: false,
    }
  }
  formRef = React.createRef()
  onFinish = async (values) => {
    const { doSubmit } = this.props

    this.formRef.current
      .validateFields()
      .then(async (values) => {
        const body = {
          ...values,
        }
        await this.setState({
          btnLoginLoading: true,
        })
        // 派发修改数据请求
        try {
          const resData = await doSubmit(body)
          if (resData.data && [0].includes(resData.data.code)) {
            this.formRef.current.resetFields()
            MyMessage.success(<FormattedMessage id="submitSuccess" />)
          } else {
            MyMessage.error(<FormattedMessage id="submitFail" />)
          }
        } catch (error) {
        } finally {
          await this.setState({
            btnLoginLoading: false,
          })
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  onFinishFailed = (errorInfo) => {}

  render() {
    const { btnLoading } = this.state
    const { formData, serviceList, intl } = this.props

    return (
      <div className={classNames(styles.form_wrapper)}>
        <Form
          className={classNames(styles.form)}
          {...layout}
          ref={this.formRef}
          name="login"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          validateTrigger="onSubmit"
        >
          <div className={styles.title}><FormattedMessage id="submitRequirements" /></div>
          <Form.Item
            name="username"
            rules={[RequiredInputRule(intl)]}
            label={<FormattedMessage id="username" />}
          >
            <TrimInput placeholder="" />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[RequiredInputRule(intl), PhoneValidateRule(intl)]}
            label={<FormattedMessage id="phone" />}
          >
            <TrimInput placeholder="" />
          </Form.Item>
          {serviceList.length > 0 && (
            <Form.Item
              name="enterpriseServiceId"
              //rules={[RequiredSelectRule]}
              label={<FormattedMessage id="intentServe" />}
            >
              <Select
                placeholder=""
                suffixIcon={
                  <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                }
              >
                {serviceList.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item name="sureTime" label={<FormattedMessage id="sureTime" />}>
            <DatePicker placeholder="" style={{width: '100%',}} />
          </Form.Item>
          <Form.Item
            name="howToFind"
            // rules={[RequiredSelectRule]}
            label={<FormattedMessage id="howToFind" />}
          >
            <Select
              placeholder=""
              suffixIcon={
                <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
              }
            >
              {HowToFindTypes.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="remark" label={<FormattedMessage id="remarkContact" />}>
            <TrimTextArea
              style={{ height: '170px' }}
              placeholder={intl.formatMessage({ id: 'shootingNeeds'})}
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              className={styles.submit_btn}
              loading={btnLoading}
            >
              <FormattedMessage id="submit" />
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default WebContactForm
