import { Button, DatePicker, Form, Typography, Divider } from 'antd'
import React, { useState, useEffect } from 'react'
import { AlignTypes } from 'constants/Options'
import { map } from 'lodash/fp'
import { FormattedMessage, useIntl } from 'react-intl'
import { RequiredInputRule } from 'utils/ValidateRulesAndMsg'
import styles from './AboutInfo.module.less'

import commonComponents from 'components/index'
const { InfoEmptyData } = commonComponents
const { div } = Typography
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

const AboutInfoForm = (props) => {
  const { formData, align } = props
  const intl = useIntl()
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({
      ...formData,
    })
  }, [form, formData])
  return (
    <div className={styles.web_about}>
      <Form
        form={form}
        ref={props.formRef}
        layout="vertical"
        name="About_form"
        className={styles.About_form}
        scrollToFirstError={true}
      >
        <Form.Item shouldUpdate>
          {() => {
            return (
              <Form.Item rules={[RequiredInputRule(intl)]} noStyle name="aboutTitle">
                <div className={styles.title} style={{ textAlign: align }}>
                  {form.getFieldValue('aboutTitle')}
                </div>
              </Form.Item>
            )
          }}
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => {
            return (
              <Form.Item rules={[RequiredInputRule(intl)]} noStyle name="aboutDesc">
                <div className={styles.about_text} style={{ textAlign: align }}>
                  {form.getFieldValue('aboutDesc')}
                </div>
              </Form.Item>
            )
          }}
        </Form.Item>
      </Form>
    </div>
  )
}

const AboutInfo = ({ formData, align, formRef }) => {
  return (
    <div>
      {formData.aboutTitle || formData.aboutDesc ? (
        <AboutInfoForm formRef={formRef} formData={formData} align={align} />
      ) : (
        <div style={{ margin: '20px 0' }}>
          <InfoEmptyData description={<FormattedMessage id="intrNoData" />} />
        </div>
      )}
    </div>
  )
}

export default AboutInfo
