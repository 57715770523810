import {Modal} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import styles from './index.module.less'
import QRCode from 'qrcode.react';
import Imgs from 'imgs'
import myMessage from "../Message";
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useHistory, useParams } from 'react-router-dom'
import Api from 'apis/Api.js'
import {
  toMasterCurrency as _toMasterCurrency,
} from 'reduxModules/customerPhoto'
const {payWechat} = Imgs
const PaymentQRcodeModal = ({
                           visible,
                           onCancel,
                           wxCode,
                           order,
                           toOrdes,
                         }) => {
  let { id } = useParams()
  const exHeader = {
    projectId: id,
  }
  const [codeVisible, setCodeVisible] = useState(false)
  // 定时器查看支付情况
  const [inters, setInters] = useState(null)
  useEffect(() => {
    setCodeVisible(visible)
    if(visible){
      let inter=setInterval(() => {
        getPayStatus()
      }, 1000)
      setInters(inter)
    }else{
      clearInterval(inters)
      setInters(null)
    }
  }, [visible])
  const onCancels=()=>{
    if(inters){
      clearInterval(inters)
      setInters(null)
    }
    toOrdes()
    onCancel()
    setCodeVisible(false)
  }
  const getPayStatus=async ()=>{
    let json={
      orderNum:order?.orderNum,
    }
    let data = {
      method: 'POST',
      parameterJson: JSON.stringify(json),
      url: Api.product.getPayStatus+'/'+order?.orderNum
    }
    const res = await _toMasterCurrency({data,exHeader})
    // console.log(res,inters.current)
    if (res.code == 0) {
      if(res.data[0]?.status== "paid") {
        toOrdes()
        onCancels()
        clearInterval(inters)
        setInters(null)
      }
    }else{
      toOrdes()
      clearInterval(inters)
      setInters(null)
      myMessage.warning(res.msg);
    }
  }
  return (
    <Modal
      className={styles.paymentDialog}
      visible={codeVisible}
      centered
      maskClosable={false}
      onCancel={onCancels}
      footer={null}
      width={600}
      forceRender={true}
    >
      <div className={styles.codes}>
        <div className={styles.payway}><img src={payWechat}/><FormattedMessage id="orderWechatPay" /></div>
        <QRCode
          id="qrCode"
          value={wxCode}
          size={300} // 二维码的大小
          fgColor="#000000" // 二维码的颜色
          /*imageSettings={{ // 二维码中间的logo图片
            src: 'logoUrl',
            height: 100,
            width: 100,
            excavate: true, // 中间图片所在的位置是否镂空
          }}*/
        />
        <div><FormattedMessage id="payWechatScan" /><br/><FormattedMessage id="payWechatRemind" /></div>
      </div>
    </Modal>
  )
}

export default PaymentQRcodeModal
