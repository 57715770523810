import React, { useState, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { Layout, Menu, Dropdown, Affix } from 'antd'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import {
  pageSharePhoto as _pageSharePhoto,
  getSharePhotoCategory as _getSharePhotoCategory,
  getProjectById as _getProjectById,
} from 'reduxModules/customerPhoto'
import {
  record as _record,
} from 'reduxModules/member'
import { isEmpty } from 'lodash/fp'
import MasonryImgs from '../Components/MasonryImgs'
import AboutModal from '../Components/AboutModal'
import styles from './index.module.less'
import { login as _login } from 'reduxModules/member'

import { setSessionStorageByKey, getSessionStorageValue } from 'utils/Help'

import commonComponents from 'components/index'
const {
  InfCheckPINModal,
  InfoEmptyData,
  CommonFooter,
  InfErrorPage,
} = commonComponents
const CategoryPhotosPage = (props) => {
  let { id } = useParams()
  const exHeader = {
    projectId: id,
  }
  const intl = useIntl()
  const commonSettingData = useSelector((state) => state.commonSetting.data)

  const showcase = useSelector((state) => state.showcaseSetting.data)

  const [aboutVisible, setAboutVisible] = useState(false)

  const [photoCategorys, setPhotoCategorys] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')
  // 下载登录
  const [pinModalVisible, setPinModalVisible] = useState(false)

  // 项目详情
  const [project, setProject] = useState({})

  // 是否公开分享
  const [isOpenShare, setIsOpenShare] = useState(false)
  // 有无权限验证
  const [isOpen, setIsOpen] = useState(false)
  // 是否已验证
  const [canView, setCanView] = useState(
    !isEmpty(getSessionStorageValue('sharePin'))
  )
  const [longLat, setLongLat] = useState('')
  const [address, setAddress] = useState({
    country:'',
    province:'',
    city:'',
    district:'',
    township:'',
  })
  const [browsers, setBrowser] = useState('')
  const [clientTypeSimple, setClientTypeSimple] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [recordData, setRecordData] = useState({})

  useEffect(() => {
    getPhotoCategory()
    getProjectData()
    // login()
    getAddress()
  }, [])
  const login= async ()=>{
    const res = await _login({
      data: {
        account	:'',
        password:'',
        accessType:'normalMode'
      },
      exHeader,
    })
  }
  const getProjectData = async () => {
    const res = await _getProjectById({
      params: {
        id: id,
      },
      exHeader,
    })
    if (res.code == 0) {
      setProject(res.data)
      setIsOpenShare(res.data.isOpenShare)
      setCanView(canView || res.data.bookmarkIsOpenShare)
    }
  }

  const getPhotoCategory = async () => {
    const pin = getSessionStorageValue('sharePin')
    const res = await _getSharePhotoCategory({
      exHeader,
      params: {
        projectId: id,
        pinCode: pin,
      },
    })
    //debugger
    if (res.code == 0) {
      setIsOpen(true)
      setPhotoCategorys(res.data)

      if (isEmpty(selectedCategory) && res.data[0]) {
        setSelectedCategory(res.data[0].id.toString())
      }
      setCanView(true)
    }

    if (res.code == 9403) {
      setIsOpen(false)
    }
    if (res.code == 9500) {
      setPinModalVisible(true)
    }
  }

  // 切换按钮
  const changeMenu = ({ key }) => {
    setSelectedCategory(key)
  }

  // 查看登录
  const pinLogin = async (value, form) => {
    const sharePinCode = project.sharePinCode
    const checkCanView = sharePinCode === value.code
    if (checkCanView) {
      setCanView(checkCanView)
      setPinModalVisible(false)
      setSessionStorageByKey('sharePin', value.code)
      form.resetFields()
      return setTimeout(() => {
        window.location.reload()
      }, 1000)
    } else {
      form.setFields([
        {
          name: 'code',
          value: value.code,
          errors: [intl.formatMessage({ id: 'passwordNoRight' })],
        },
      ])
    }
  }

  const showAbout = () => {
    setAboutVisible(true)
  }

  // 关于用户
  const userMenu = () => {
    const token = getSessionStorageValue('token')
    return (
      <div className={styles.aboutMenu}>
        <Menu>
          <Menu.Item>
            <a onClick={showAbout}>
              {intl.formatMessage({ id: 'about' })}
            </a>
          </Menu.Item>
        </Menu>
      </div>
    )
  }

// 获取地址信息
const getAddress = async() => {
  let browser=getBrowserInfo()
  setBrowser(browser)
  let clientTypeSimple=''
  if(browser.split('-')[1].indexOf('Windows')<0){
    clientTypeSimple=getExploreName()+', '+browser.split('-')[1].split(';')[0]
  }else{
    clientTypeSimple=getExploreName()+', '+browser.split('-')[1].split(' ')[0]
  }
  setClientTypeSimple(clientTypeSimple)
  //获取地理位置与经纬度
  try {
    fetch("https://restapi.amap.com/v3/ip?key=28d085faf15e8975ef1338d723e18a9b").then((res) => {
      if (res.ok) {
        res.text().then(async(data) => {
          const detail = JSON.parse(data)
          if (detail.rectangle.length == 0) {
            let datas={
              browsers:getBrowserInfo(),
              clientTypeSimple:clientTypeSimple,
              ...address
            }
            gotoView('',datas)
            return
          }
          let rectangle = ((parseFloat(detail.rectangle.split(';')[0].split(',')[0]) +
              parseFloat(detail.rectangle.split(';')[1].split(',')[0])) / 2).toFixed(8) + ',' + ((
              parseFloat(detail.rectangle.split(';')[0].split(',')[1]) +
              parseFloat(detail.rectangle.split(';')[1].split(',')[1])) / 2).toFixed(8)
          await setLongLat(rectangle)
          fetch("https://restapi.amap.com/v3/geocode/regeo?output=json&location=" + rectangle + "&key=28d085faf15e8975ef1338d723e18a9b&radius=1000&extensions=all").then((re) => {
            if (re.ok) {
              re.text().then(async(data) => {
                await setAddress(JSON.parse(data).regeocode.addressComponent)
                let datas={
                  browsers:getBrowserInfo(),
                  clientTypeSimple:clientTypeSimple,
                  location:JSON.parse(data).regeocode.addressComponent.streetNumber.location,
                  ...JSON.parse(data).regeocode.addressComponent
                }
                gotoView('',datas)
              })
            }
          })
        })
      }
    })
  } catch (error) {
      gotoView('','')
  } finally
  {
  }
}
//获取浏览器及版本号
const getBrowserInfo=()=>{
  var agent = navigator.userAgent.toLowerCase();
  var regStr_ie = /msie [\d.]+;/gi;
  var regStr_ff = /firefox\/[\d.]+/gi
  var regStr_chrome = /chrome\/[\d.]+/gi;
  var regStr_saf = /safari\/[\d.]+/gi;

  let str = navigator.userAgent;
  let rsg = /\((.*?)\)/;
  let arr = rsg.exec(str);
  let result = "";
  if(arr.length >= 2){
    result = arr[1];
  }else{
    result = str.toLowerCase().indexOf("mac os x") > 0 ? "mac os":"windows"
  }
  //IE
  if (agent.indexOf("msie") > 0) {
    return agent.match(regStr_ie)+'-'+result;
  }
  //firefox
  if (agent.indexOf("firefox") > 0) {
    return agent.match(regStr_ff)+'-'+result;
  }
  //Safari
  if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
    return agent.match(regStr_saf)+'-'+result;
  }
  //Chrome
  if (agent.indexOf("chrome") > 0) {
    return agent.match(regStr_chrome)+'-'+result;
  }
  //（2）然后获取版本号
  var browser = getBrowserInfo();//返回的是个长度为1的数组，根据自己的需要进行转换
  var verinfo = (browser + "").replace(/[^0-9.]/ig, "");

}
  const getExploreName=()=>{
    var userAgent = navigator.userAgent;
    if(userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1){
      return 'Opera';
    }
    else if(userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1){
      return 'IE';
    }
    else if(userAgent.indexOf("Edge") > -1){
      return 'Edge';
    }
    else if(userAgent.indexOf("Firefox") > -1){
      return 'Firefox';
    }
    else if(userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1){
      return 'Safari';
    }
    else if(userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1){
      return 'Chrome';
    }
    else if(!!window.ActiveXObject || "ActiveXObject" in window){
      return 'IE>=11';
    }
    else{
      return 'Unkonwn';
    }
  }
  const gotoView = async (customerId,adr) => {
    let recordBrowseDTO={
      customerId:customerId,
      clientType:browsers?browsers:adr.browsers,
      clientTypeSimple:clientTypeSimple?clientTypeSimple:adr.clientTypeSimple,
      country:address.country?address.country:adr.country,
      province:address.province?address.province:adr.province,
      city:address.city?address.city:adr.city,
      position:address.country?address.country+address.province+address.city+address.district+address.township:adr.country+adr.province+adr.city+adr.district+adr.township,
      latitude:longLat.split(',')[1]?longLat.split(',')[1]:adr.location?adr.location.split(',')[1]:'',
      longitude:longLat.split(',')[0]?longLat.split(',')[0]:adr.location?adr.location.split(',')[0]:'',
      accessType:'normalMode'
    }
    console.log(recordBrowseDTO)
    setRecordData(recordBrowseDTO)
    return
    const res = await _record({
      data: recordBrowseDTO,
      exHeader,
    })
  }
  return (
    <div className={styles.sharephotoLayout}>
    <Layout>
      {isOpenShare || canView ? (
        isOpenShare || isOpen ? (
          <>
            <div className={styles.header}>
              <div className={styles.topInfo}>
                <div>
                  <div className={styles.projectName}>{project.name}</div>
                  <div className={styles.projectDate}>
                    {project.shootingDay=='Invalid date'?'':project.shootingDay}
                  </div>
                  <div className={styles.logo}>
                    <img
                      src={
                        isEmpty(commonSettingData)
                          ? ''
                          : commonSettingData.customerLogoPhotoInfo.photoUrl
                      }
                    />
                  </div>
                </div>
              </div>
              {photoCategorys.length > 0 ? (
                <Affix>
                  <div className={styles.topArea}>
                    <div className={styles.category}>
                      <Menu
                        mode="horizontal"
                        className={styles.Inf_menus}
                        onClick={changeMenu}
                        selectedKeys={[selectedCategory]}
                      >
                        {photoCategorys.map((category) => (
                          <Menu.Item key={category.id}>
                            {category.name}
                          </Menu.Item>
                        ))}
                      </Menu>
                    </div>
                    <div className={styles.iconBtns}>
                      <Dropdown overlay={userMenu} trigger="click">
                        <a>
                          <svg
                            className={`icon ${styles.user}`}
                            aria-hidden="true"
                          >
                            <use xlinkHref="#iconbtn_info"></use>
                          </svg>
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                </Affix>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '10%',
                  }}
                >
                  <InfoEmptyData description={intl.formatMessage({ id: 'noVisitorPic' })} />
                </div>
              )}
            </div>
            <div className={styles.waterfullBody}>
              {recordData.accessType&&(
                  <MasonryImgs
                      _pagePhotos={_pageSharePhoto}
                      recordData={recordData}
                      categoryId={selectedCategory}
                      key={selectedCategory + project.id}
                      exHeader={exHeader}
                      project={project}
                  />
              )}
            </div>
            <CommonFooter websiteFooter={commonSettingData.websiteFooter} />
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              height: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div>
              <InfErrorPage
                imgSrc={
                  commonSettingData.customerLogoPhotoInfo &&
                  commonSettingData.customerLogoPhotoInfo.photoUrl
                }
                remindTitle={intl.formatMessage({ id: 'noAccess' })}
                remindText={`${intl.formatMessage({ id: 'contactServe' })}${commonSettingData.name}`}
              />
              <CommonFooter websiteFooter={commonSettingData.websiteFooter} />
            </div>
          </div>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <InfErrorPage
              imgSrc={
                commonSettingData.customerLogoPhotoInfo &&
                commonSettingData.customerLogoPhotoInfo.photoUrl
              }
              remindTitle={intl.formatMessage({ id: 'noAccess' })}
              remindText={`${intl.formatMessage({ id: 'contactServe' })}${commonSettingData.name}`}
            />
            <CommonFooter websiteFooter={commonSettingData.websiteFooter} />
          </div>
        </div>
      )}
      {/* 下载弹窗 */}
      <InfCheckPINModal
        visible={pinModalVisible}
        onCancel={() => setPinModalVisible(false)}
        onOk={pinLogin}
        title={intl.formatMessage({ id: 'accessCheck' })}
        desc=""
        label={intl.formatMessage({ id: 'password' })}
        okText={intl.formatMessage({ id: 'ok' })}
        modalProps={{
          closable: false,
          maskClosable: false,
        }}
      />
      <AboutModal
        visible={aboutVisible}
        handleCancel={() => {
          setAboutVisible(false)
        }}
        cameraman={showcase}
        commonSettingData={commonSettingData}
      />
    </Layout>
    </div>
  )
}

export default CategoryPhotosPage
