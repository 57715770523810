import moment from 'moment'

import { isEmpty, sortBy } from 'lodash'
import { getOptionBykeyName, getOption } from 'utils/Tools'
import { YesOrNoTypes } from 'constants/Options'
export const PhotosListSelector = (res) => {
  let newData = {
    ...res.data,
    // code: 9500,
  }
  const { data } = res.data
  newData.data = {
    pagination: isEmpty(data)
      ? {
          pageNo: 1,
          current: 1,
          pageSize: 50,
          total: 0,
        }
      : {
          current: data.pageNo,
          pageSize: data.pageSize,
          total: data.totalCount,
        },
    list:
      !isEmpty(data) && !isEmpty(data.list)
        ? data.list.map((img) => ({
            ...img,
            messageTime: isEmpty(img.messageTime)
              ? ''
              : moment(img.messageTime).format('YYYY/MM/DD hh:mm'),
            isBookmarkShow: getOptionBykeyName(
              'value',
              img.isBookmark,
              'key',
              YesOrNoTypes
            ), //是否允许客户设置图片隐藏,
            isHideShow: getOptionBykeyName(
              'value',
              img.isHide,
              'key',
              YesOrNoTypes
            ), //是否允许客户设置图片隐藏,
          }))
        : [],
  }

  return newData
}

export const PhotoCategorySelector = (res) => {
  let newData = {
    ...res.data,
  }

  const { data } = res.data
  const list = !isEmpty(data)
    ? data.map((item, index) => ({
        key: index,
        ...item,
      }))
    : []
  newData.data = sortBy(list, (item) => item.sort)
  return newData
}

// 设置项目隐藏/显示
export const SetPhotoHidenSelector = (data) => {
  let newData = {
    id: data.id,
    isHide: getOption(data.isHide, 'value', YesOrNoTypes), //访客访问需要通过关注微信访问
  }
  return newData
}

//  客片允许下载的分类
export const CustomerCategoriesCanDownloadSelector = (res) => {
  let newData = {
    ...res.data,
  }
  const { data } = res.data
  const list = !isEmpty(data)
    ? data.map((item, index) => ({
        key: index,
        ...item,
      }))
    : []
  newData.data = sortBy(list, (item) => item.sort)
  return newData
}
export const ProjectsListSelector = (res) => {
  const { data } = res
  return data
}
