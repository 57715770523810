import React from 'react'
import { Row, Col, Spin } from 'antd'
import Masonry from 'masonry-layout'
import { FormattedMessage } from 'react-intl'
import InfiniteScroll from 'react-infinite-scroller'

import { getSessionStorageValue } from 'utils/Help'
import styles from './masonryImgs.module.less'

import commonComponents from 'components/index'
const { PreviewCarouselModal, InfoEmptyData, InfListFooterText } =
  commonComponents
export default class MasonryImgs extends React.Component {
  state = {
    imgsData: [],
    hasMore: true,
    idOfShowOpts: '',
    previewCarouselModalVisible: false, // 查看图片弹窗
    previewList: [], // 查看弹窗图片list
    previewCurrent: 0, // 查看弹窗当前siderNumber
    pagination: {
      current: 1,
    }, // 分页数据
    loading: false,
  }

  componentDidMount() {
    const { categoryId, project } = this.props
    if (categoryId.length && project.id) {
      this.getCustomorPhotos({ categoryId: categoryId, projectId: project.id })
    }
  }

  getCustomorPhotos = async ({ pageInfo, ...rest }) => {
    const { _pagePhotos, exHeader,recordData } = this.props
    const { imgsData } = this.state
    const pin = getSessionStorageValue('sharePin')
    this.setState({ loading: true, hasMore: false })
    console.log(recordData)
    const res = await _pagePhotos({
      params: {
        ...recordData,
        ...pageInfo,
        ...rest,
        pinCode: pin,
      },
      exHeader,
    })
    this.setState({ loading: false })
    if ([0].includes(res.code)) {
      const { pagination, list } = res.data
      const hasMore =
        pagination.current * pagination.pageSize < pagination.total

      const newList = pagination.current === '1' ? list : imgsData.concat(list)
      this.setState({ imgsData: newList, hasMore, pagination })
    }
  }

  loadData = (page = 1) => {
    const { hasMore } = this.state

    const { categoryId, project } = this.props
    // _pageCustomerPhoto()
    if (hasMore) {
      this.getCustomorPhotos({
        categoryId: categoryId,
        projectId: project.id,
        pageInfo: { pageNo: page },
      })
    }
  }
  onLoadImg = () => {
    // nowNum++
    // if (nowNum === count) {
    //   // 如果所有图片加载完，调用实现瀑布流代码
    // }

    var elem = document.querySelector('.grid')
    var msnry = new Masonry(elem, {
      // options
      itemSelector: '.grid-item',
    })
  }

  showOpt = (img) => {
    const { imgsData } = this.state
    this.setState({
      idOfShowOpts: img.id,
      previewCurrent: imgsData.findIndex((item) => [img.id].includes(item.id)),
    })
  }
  hideOpt = () => {
    this.setState({ idOfShowOpts: '' })
  }

  // 显示查看弹窗
  showPreviewCarouselModal = async (id) => {
    const { imgsData } = this.state
    await this.setState({
      previewCurrent: imgsData.findIndex((item) => [id].includes(item.id)),
    })
    await this.setState({
      previewCarouselModalVisible: true,
    })
  }
  // 隐藏查看弹窗
  hidePreviewCarouselModal = (newList) => {
    this.setState({
      previewCarouselModalVisible: false,
      previewCurrent: 0,
      imgsData: newList,
    })
  }
  setPreviewCurrent = async (index) => {
    await this.setState({
      previewCurrent: index,
    })
  }
  loadNext = async () => {
    const { categoryId, _pagePhotos, exHeader,recordData } = this.props
    const { pagination } = this.state
    this.setState({ loading: true })
    console.log(recordData)
    const res = await _pagePhotos({
      params: {
        ...recordData,
        ...pagination,
        pageNo: Number(pagination.current) + 1,
        categoryId: categoryId,
      },
      exHeader,
    })
    this.setState({ loading: false })
    let newList = []
    if ([0].includes(res.code)) {
      const { pagination, list } = res.data
      const hasMore =
        pagination.current * pagination.pageSize < pagination.total
      newList = list
      this.setState({ hasMore, pagination })
    }
    return newList
  }

  render() {
    const {
      imgsData,
      hasMore,
      previewCarouselModalVisible,
      previewCurrent,
      pagination,
      loading,
    } = this.state
    const { project } = this.props

    return !hasMore && !imgsData.length ? (
      <div style={{ marginTop: 120 }}>
        <Spin spinning={loading} tips={<FormattedMessage id="getData" />} style={{width: "100%" }} />
        {!loading && <InfoEmptyData description={<FormattedMessage id="categoryNoData" />} />}
      </div>
    ) : (
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        hasMore={hasMore}
        loadMore={this.loadData}
      >
        <Spin spinning={loading}>
          <Row className="grid fixAntdAndMosonryGrid" gutter={[10, 10]}>
            {imgsData.map((item, key) => {
              return (
                <Col
                  className="grid-item"
                  key={key}
                  onMouseEnter={this.showOpt.bind(this, item)}
                  onMouseLeave={this.hideOpt}
                  onClick={() => this.showPreviewCarouselModal(item.id)}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={6}
                  // xxl={4}
                >
                  <img
                    className="masonry-img"
                    src={item.thumbInfo['general'].photoUrl}
                    key={item.thumbInfo['general'].photoKey}
                    onLoad={(e) => {
                      e.stopPropagation()
                      this.onLoadImg()
                    }}
                  />
                  <div className={styles.imgTop}></div>
                </Col>
              )
            })}
            {/* <Spin spinning={loading} style={{ width: "100%" }} /> */}
          </Row>
        </Spin>
        {/* 查看弹窗 */}
        <PreviewCarouselModal
          visible={previewCarouselModalVisible}
          onCancel={this.hidePreviewCarouselModal}
          list={imgsData}
          key={imgsData}
          previewCurrent={previewCurrent}
          pagination={pagination}
          hasMore={hasMore}
          setPreviewCurrent={this.setPreviewCurrent}
          loadData={this.loadNext}
          showKey="null"
          project={project}
        />
      </InfiniteScroll>
    )
  }
}
