import { Empty } from 'antd'
import React from 'react'
import styles from './index.module.less'
import { useIntl } from 'react-intl'
import Imgs from 'imgs'

const InfListFooterText = ({ pagination = {}, hasMore = false }) => {
  const intl = useIntl()
  const number = hasMore
    ? pagination.total - pagination.current * pagination.pageSize
    : pagination.total
  return pagination.total > 0 ? (
    <div className={styles.InfListFooterText}>
      {hasMore ? `${intl.formatMessage({ id: 'remaining' })}${number}${intl.formatMessage({ id: 'picLoading' })}` : `${number}${intl.formatMessage({ id: 'picLoadingComplete' })}`}
    </div>
  ) : (
    ''
  )
}
export default InfListFooterText
