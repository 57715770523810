import React, { useState } from 'react'
import { Button, Modal, Form, Input } from 'antd'
import { useIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl';
import { TrimInput } from '../WithTrim/index'

import styles from './index.module.less'
const InfCheckPINModal = ({ visible, onOk, onCancel }) => {
  const [form] = Form.useForm()
  // 登录状态
  const intl = useIntl()
  const [requesting, setRequesting] = useState(false)
  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setRequesting(true)
          await onOk(values, form)
        } catch (error) {
        } finally {
          setRequesting(false)
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }
  return (
    <Modal
      key={visible}
      wrapClassName={styles.InfDownLoadModal}
      width={600}
      transitionName={'none'}
      maskTransitionName={'none'}
      visible={visible}
      centered
      maskClosable={false}
      onCancel={onCancel}
      title={
        <div className="Inf_modal_title">
          <span className="Inf_modal_title_text"><FormattedMessage id="dwpinTitle" /></span>
          <span className="Inf_modal_subtitle_text">
            <FormattedMessage id="dwpinTip" />
          </span>
        </div>
      }
      footer={[
        <Button
          key="move"
          className="link-btn-default"
          onClick={() => onCancel()}
        >
          {intl.formatMessage({ id: 'cancel' })}  
        </Button>,
        <Button
          loading={requesting}
          key="copy"
          className="Inf_black_btn"
          onClick={() => onSubmit()}
        >
          {intl.formatMessage({ id: 'submit' })}  
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="inf_downLoad_modal">
        <Form.Item
          name="code"
          label={intl.formatMessage({ id: 'pinCode' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'pleaseInputPin' }),
            },
          ]}
        >
          <TrimInput max={50} placeholder={intl.formatMessage({ id: 'needInput' })} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default InfCheckPINModal
