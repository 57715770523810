import request from 'utils/Request'

import Api from 'apis/Api.js'

import { omit } from 'lodash/fp'

// 查询项目类别
export const _getProjectCategorys = async () => {
  return await request({
    method: 'get',
    url: Api.project.getProjectCategoryList,
  })
}

// 查询客片项目
export const _getProjectsByCategory = async (data) => {
  return await request({
    method: 'post',
    url: Api.project.getProjectInfoList+'?categoryId='+data.categoryId+'&pageNo='+data.pageNo+'&pageSize='+data.pageSize,
    data: data,
  })
}

// 查询客片项目详情
export const _getProjectById = async ({ params = {}, exHeader = {} }) => {
  return await request({
    method: 'get',
    url: Api.project.getById,
    headers: {
      exHeader,
    },
    params: params,
  })
}

// 查询客片项目登录情况
export const _getProjectInfoForLogin = async ({
  params = {},
  exHeader = {},
}) => {
  return await request({
    method: 'get',
    url: Api.project.getProjectInfoForLogin,
    headers: {
      exHeader,
    },
    params: params,
  })
}

// 开关是否公开分享
export const _setOpenShare = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.project.onOffIsOpenShare,
    headers: {
      exHeader,
    },
    data: data,
  })
}

// 开关是否公开分享设置密码
export const _setOpenSharePassword = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.project.onOffIsShareNeedPinCode,
    headers: {
      exHeader,
    },
    data: data,
  })
}

// 开关是否公开分享
export const _setBookmarkOpenShare = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.project.onOffBookmarkIsOpenShare,
    headers: {
      exHeader,
    },
    data: data,
  })
}

// 开关是否公开分享设置密码
export const _setBookmarkOpenSharePassword = async ({
  data = {},
  exHeader = {},
}) => {
  return await request({
    method: 'post',
    url: Api.project.onOffBookmarkIsShareNeedPinCode,
    headers: {
      exHeader,
    },
    data: data,
  })
}
// 获取微信授权地址
export const _getAuthorization = async ({
                                                params = {},
                                                exHeader = {},
                                              }) => {
  return await request({
    method: 'get',
    url: Api.project.authorization,
    headers: {
      exHeader,
    },
    params: params,
  })
}
// 微信公众号通过授权code 获取微信用户信息
export const _getWechat = async ({
                                                      data = {},
                                                      exHeader = {},
                                                    }) => {
  return await request({
    method: 'post',
    url: Api.project.wechat,
    headers: {
      exHeader,
    },
    data: data,
  })
}
// 验证项目下载码
export const _verifyDownloadCode = async ({ data = {}, exHeader = {} }) => {
  return await request({
    method: 'post',
    url: Api.project.downloadCode+'?code='+data.code+'&projectId='+data.projectId,
    headers: {
      exHeader,
    },
    data: data,
  })
}
